/*
bootstrap custom
*/

.btn, .btn:hover, .btn:focus-visible, .btn:active {
	/* background: none; */
	border: none;
	box-shadow: none;
}

input:disabled {
	opacity: 1;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
	color: #fff;
	background: none;
	border: none
}

a, a:hover {
	color: var(--user-color);
	outline: none;
	text-decoration: none;
}

.btn-close:focus {
	box-shadow: none;
	border: none
}

.modal-content {
	border-radius: var(--br-xxl);
}

.modal-header .modal-title {
	font-size: 24px;
	font-weight: 500;
}

.modal-header {
	background: var(--white);
	border-top-left-radius: var(--br-xxl);
	border-top-right-radius: var(--br-xxl);
	padding: 1.2rem 1.5rem;
	align-items: flex-start;
}

.modal-header .modal-subtitle {
	font-size: 18px;
	font-weight: 500;
	padding: 0;
	margin: 0;
}

.modal-body {
	padding: 1.2rem 1.5rem;
	padding-bottom: 2rem;
}

@media (max-width: 768px) {
	.modal-content {
		border-radius: var(--br-xl);
	}
	.modal-header {
    border-top-left-radius: var(--br-xl);
    border-top-right-radius: var(--br-xl);
}
}

@media (max-width: 576px) {
	.modal-body, .modal-header, .modal-footer {
		padding: 1rem
	}
}

@media (min-width: 1500px) {
	.col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
	}
	.col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
	}
	.col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
	}
	.col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
	}
}

@media (min-width: 1580px) {
	.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl, .container-xxxl {
		max-width: 1500px;
	}

}



/*

Main styles

*/

body {
	font-family: 'roboto', sans-serif;
	font-weight: 400;
	color: var(--user-color);
	font-size: 16px;
	line-height: 24px;
	margin: 0;
	position: relative;
	overflow-x: hidden;
}

@media (max-width: 992px) {
	body {
		padding-bottom: 45px;
	}
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}

/* .custom-scrollbar {
  scrollbar-width: var(--scrollbarWidth);
  scrollbar-color: var(--scrollbarThumb) var(--scrollbarBg);
}
.custom-scrollbar::-webkit-scrollbar {
  width: var(--scrollbarWidth);
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbarBg);
  border-radius: var(--scrollbarBorderRadius);
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumb);
  border-radius: var(--scrollbarBorderRadius);
} */

@-moz-document url-prefix() {
	* {
			scrollbar-width: thin;
			scrollbar-color: var(--scrollbarThumb) var(--scrollbarBg);
		}
	}
	
	/* CHROME */
	::-webkit-scrollbar {
		width: var(--scrollbarWidth);
	}
	
	::-webkit-scrollbar-track {
		background: transparent;
	}
	
	::-webkit-scrollbar-thumb {
		background: var(--scrollbarThumb);
		border-radius: var(--scrollbarBorderRadius);
	}

.btn-close {
	transition: var(--transition-btns);
}

.btn-close:hover {
	transform: rotate(90deg);
}

.btn-default {
	width: 100%;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--br-lg);
	transition: var(--transition-btns);
	color: #fff
}

.btn-pink {
	background: var(--pink);
}

.btn-pink:hover, .btn-pink:focus-visible, .btn-pink:active {
	background: var(--pink-hover)!important;
	color: #fff
}

.btn-brown {
	background: var(--pink);
}

.btn-brown:hover, .btn-brown:focus-visible, .btn-brown:active {
	background: var(--pink-hover) !important;
  color: var(--bs-white) !important;
}

.btn-white {
	background: var(--white);
}

.btn-white:hover, .btn-white:focus-visible, .btn-white:active {
	background: #e7e4df !important;
}

.btn-green {
	background: var(--green);
}

.btn-green:hover, .btn-green:focus-visible, .btn-green:active {
	background: var(--green-hover)!important;
	color: #fff
}

.btn-stroke {
	background: #fff0;
	border: 1px solid var(--user-color);
	border-radius: var(--br-lg);
	color: var(--user-color);
}

.btn-stroke:hover, .btn-stroke:focus-visible, .btn-stroke:active {
	background: var(--user-color)!important;
	border: 1px solid var(--user-color)!important;
	color: #fff
}

.mt-sc {
	margin-top: 90px;
}

.mt-sc-lg {
	margin-top: 120px;
}

h3 {
	font-size: 24px;
	font-weight: 600;
}


.swiper-button-prev.swiper-custom-arrow, .swiper-button-next.swiper-custom-arrow {
	background: rgba(231, 228, 223, 0.7);
	width: 42px;
	height: 42px;
	border-radius: 100%;
	transition: var(--transition-btns);
}

.swiper-button-prev.swiper-custom-arrow::after, .swiper-button-next.swiper-custom-arrow::after {
	color: rgba(44, 54, 56, 0.7);
	font-size: 20px;
	font-weight: 500;
}

.swiper-button-prev.swiper-custom-arrow:hover, .swiper-button-next.swiper-custom-arrow:hover {
	transform: scale(1.15);
	background: rgba(231, 228, 223, 1);
}



.icons-arrow-line-wr {
	display: flex;
	position: relative;
	margin-left: auto;
	gap: 50px;
}

.icon-arrow-line::after {
	font-size: 22px;
	color: var(--user-color);
	font-weight: 600;
}

.icon-arrow-line {
	position: static;
	margin: 0;
	padding: 0;
	transition: var(--transition-btns);
}

.swiper-button-prev.icon-arrow-line:hover {
	transform: translateX(-5px);
}

.swiper-button-next.icon-arrow-line:hover {
	transform: translateX(5px);
}

.icon-arrow-line::before {
	font-size: 35px;
	font-weight: 400;
	color: var(--user-color);
}

.swiper-button-next.icon-arrow-line::before {
	transform: rotate(180deg);
}

.swiper-button-prev.icon-arrow-line::after, .swiper-button-next.icon-arrow-line::after {
	display: none
}



 /* checkbox */

.checkbox {
  --border-default: #bbbbc1;
  --border-hover: #9898a3;
  --active: var(--pink);
  /* --active-tick: #ffffff; */
  --active-tick: #000000;
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
.checkbox svg {
  display: block;
  position: absolute;
}
.checkbox input {
	display: block;
	outline: none;
	border: none;
	padding: 0;
	margin: 0;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	border-radius: 36%/36%;
	box-shadow: inset 0 0 0 1.5px var(--border, var(--border-default));
	background: var(--background, transparent);
	transition: background 0.25s linear, box-shadow 0.25s linear;
}
.checkbox input + svg {
  width: 21px;
  height: 18px;
  left: 0;
  top: 0;
  /* color: var(--active); */
  color: #000000;
}
.checkbox input + svg .tick {
  stroke-dasharray: 20;
  stroke-dashoffset: var(--stroke-dashoffset, 20);
  transition: stroke-dashoffset 0.2s;
}
.checkbox input + svg .tick.mask {
  stroke: var(--active-tick)!important;
}
.checkbox input + svg + svg {
  width: 11px;
  height: 11px;
  fill: none;
  stroke: var(--active);
  stroke-width: 1.25;
  stroke-linecap: round;
  top: -6px;
  right: -10px;
  stroke-dasharray: 4.5px;
  stroke-dashoffset: 13.5px;
  pointer-events: none;
  -webkit-animation: var(--animation, none) 0.2s ease 0.175s;
          animation: var(--animation, none) 0.2s ease 0.175s;
}
.checkbox input:checked {
  --background: var(--active);
  --border: var(--active);
}
.checkbox input:checked + svg {
  --stroke-dashoffset: 0;
}
.checkbox input:checked + svg + svg {
  --animation: check;
}

.checkbox:hover input:not(:checked) {
  --border: var(--border-hover);
}

@-webkit-keyframes check {
  100% {
    stroke-dashoffset: 4.5px;
  }
}

@keyframes check {
  100% {
    stroke-dashoffset: 4.5px;
  }
}

.checkbox-wrapper span {
	font-size: 14px;
	padding-top: 1px;
}

.checkbox-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	padding-left: 5px;
	margin-top: 10px;
}

 /* success mail */
.email-notification {
  --border: #2B3044;
  --bell-default: var(--pink);
  --bell-active: var(--pink);
  box-shadow: inset 4px -4px 0 0 var(--border);
  position: relative;
  display: table;
  cursor: pointer;
  transition: transform 0.15s;
  transform: scale(var(--scale, 1)) translateZ(0);
	margin: 0 auto
}
.email-notification:active {
  --scale: .96;
}
.email-notification:before, .email-notification:after {
  content: "";
  position: absolute;
  background: var(--border);
  display: block;
}
.email-notification:before {
  border-radius: 0 0 3px 0;
  left: 0;
  top: 0;
  height: 4px;
  width: 38px;
}
.email-notification:after {
  border-radius: 3px 0 0 0;
  right: 0;
  bottom: 0;
  width: 4px;
  height: 24px;
}
.email-notification input {
  display: none;
}
.email-notification input:checked + svg {
  --svg-animation: tick;
}
.email-notification input:checked + svg + .bell {
  --bell-animation: bell;
  --bell-bottom-animation: bell-bottom;
  --bell-bottom-duration: 0s;
  --bell-bottom-delay: 1.3s;
  --bell-bottom-s: .9;
  --bell-top-y: 0px;
  --bell-top-delay: .1s;
  --bell-color: var(--bell-active);
}
.email-notification svg {
  display: block;
  width: 64px;
  height: 41px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 4px;
  stroke-dasharray: 60px;
  stroke-dashoffset: 136px;
  stroke: var(--border);
  -webkit-animation: var(--svg-animation, none) 0.3s linear;
          animation: var(--svg-animation, none) 0.3s linear;
}
.email-notification .bell {
  position: absolute;
  right: 2px;
  top: 6px;
  height: 4px;
  width: 20px;
  border-radius: 2px;
  background: var(--bell-color, var(--bell-default));
  -webkit-animation: var(--bell-animation, none) 1.2s linear 0.1s;
          animation: var(--bell-animation, none) 1.2s linear 0.1s;
  transition: background 0.25s;
}
.email-notification .bell:before, .email-notification .bell:after {
  content: "";
  display: block;
  position: absolute;
}
.email-notification .bell:before {
  left: 2px;
  bottom: 0;
  width: 16px;
  height: 19px;
  border-radius: 8px 8px 0 0;
  box-shadow: inset 0 0 0 4px var(--bell-color, var(--bell-default));
  -webkit-clip-path: polygon(0 0, 16px 0, 16px calc(19px - var(--bell-top-y, 19px)), 0 calc(19px - var(--bell-top-y, 19px)));
          clip-path: polygon(0 0, 16px 0, 16px calc(19px - var(--bell-top-y, 19px)), 0 calc(19px - var(--bell-top-y, 19px)));
  transform: translateY(var(--bell-top-y, 19px));
  transition: transform 0.1s linear var(--bell-top-delay, 0s), box-shadow 0.25s, -webkit-clip-path 0.1s linear var(--bell-top-delay, 0s);
  transition: transform 0.1s linear var(--bell-top-delay, 0s), clip-path 0.1s linear var(--bell-top-delay, 0s), box-shadow 0.25s;
  transition: transform 0.1s linear var(--bell-top-delay, 0s), clip-path 0.1s linear var(--bell-top-delay, 0s), box-shadow 0.25s, -webkit-clip-path 0.1s linear var(--bell-top-delay, 0s);
}
.email-notification .bell:after {
  bottom: -7px;
  left: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--bell-color, var(--bell-default));
  transform: scale(var(--bell-bottom-s, 0));
  transition: transform var(--bell-bottom-duration, 0.2s) ease var(--bell-bottom-delay, 0s), background 0.25s;
  -webkit-animation: var(--bell-bottom-animation, none) 1.2s linear 0.2s;
          animation: var(--bell-bottom-animation, none) 1.2s linear 0.2s;
}

@-webkit-keyframes tick {
  25% {
    stroke-dashoffset: 144px;
  }
  50% {
    stroke-dashoffset: 120px;
  }
  100% {
    stroke-dashoffset: 136px;
  }
}

@keyframes tick {
  25% {
    stroke-dashoffset: 144px;
  }
  50% {
    stroke-dashoffset: 120px;
  }
  100% {
    stroke-dashoffset: 136px;
  }
}
@-webkit-keyframes bell {
  8%, 92% {
    transform: translate(6px, -8px) rotate(45deg);
  }
}
@keyframes bell {
  8%, 92% {
    transform: translate(6px, -8px) rotate(45deg);
  }
}
@-webkit-keyframes bell-bottom {
  8%, 100% {
    transform: scale(0.9);
  }
  14%, 26%, 38%, 50%, 62%, 74%, 86% {
    transform: translateX(2px) scale(0.9);
  }
  20%, 32%, 44%, 56%, 68%, 80% {
    transform: translateX(-2px) scale(0.9);
  }
}
@keyframes bell-bottom {
  8%, 100% {
    transform: scale(0.9);
  }
  14%, 26%, 38%, 50%, 62%, 74%, 86% {
    transform: translateX(2px) scale(0.9);
  }
  20%, 32%, 44%, 56%, 68%, 80% {
    transform: translateX(-2px) scale(0.9);
  }
}

/*  modal success */

.success-modal .modal-content {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 250px;
}

.success-modal .success-title {
	font-size: 18px;
	font-weight: 500;
	margin-top: 30px;
	text-align: center;
}


/*
toasts
*/

.toast.custom-add-toast {
	border: none;
	border-radius: var(--br-xl);
	box-shadow: var(--shadow-block);
	text-align: center
}

.toast.custom-add-toast .toast-header {
	border-top-left-radius: var(--br-xl);
	border-top-right-radius: var(--br-xl);
	justify-content: space-between;
	border-bottom: 1px solid var(--light-grey);
}

.toast.custom-add-toast .toast-body {
	background: #fff;
	border-bottom-left-radius: var(--br-xl);
	border-bottom-right-radius: var(--br-xl);
}

.toast.custom-add-toast .toast-body a{
	font-weight: 500
}

.toast {
	--bs-toast-max-width: 360px;
}

.toast-body .icon-check {
	font-size: 18px;
	font-weight: 600;
	display: inline-block;
	margin: 7px 0;
	color: #fff;
	background: var(--green);
	border-radius: 100%;
	padding: 6px;
}

.toast.custom-add-toast .toast-body .icon-close {
	font-size: 18px;
	font-weight: 600;
	display: inline-block;
	margin: 7px 0;
	color: #fff;
	background: var(--red);
	border-radius: 100%;
	padding: 6px;
}


/* breadcrumbs */
ul.breadcrumb li:not(:last-child) {
	display: flex;
	align-items: center;
}

ul.breadcrumb {
	align-items: center;
}

ul.breadcrumb li:not(:last-child)::after {
	content: "";
	display: block;
	margin: auto;
	background: var(--dark-grey);
	width: 20px;
	height: 1px;
	margin: 0 8px;
}

ul.breadcrumb svg {
	width: 25px;
	height: 25px;
}

ul.breadcrumb li, ul.breadcrumb svg path, ul.breadcrumb li a {
	transition: var(--transition-btns)
}

ul.breadcrumb li:hover > a {
	color: var(--pink)
}

ul.breadcrumb li:hover svg path:first-child {
	stroke: var(--pink)
}

ul.breadcrumb li:hover svg path:last-child {
	fill: var(--pink)
}

ul.breadcrumb li {
	font-size: 14px;
}


.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
	color: var(--user-color);
}

.page-container {
	margin-top: 30px;
}

.form-check-input:checked {
	background-color: var(--pink);
	border-color: var(--pink)!important;
}

.form-check-input {
	cursor: pointer;
	border-color: var(--light-grey);
}

.form-check-input:focus {
	border-color: var(--light-grey);
	outline: 0;
	box-shadow: 0px 0px 0px 0.2rem #b1839c4a;
}


@media (max-width : 768px) {
	ul.breadcrumb li {
		font-size: 12px;
	}
	ul.breadcrumb li:not(:last-child)::after {
		width: 12px;
		margin: 0 6px;
	}
	ul.breadcrumb svg {
		width: 20px;
		height: 20px;
	}

	.page-container {
		margin-top: 15px;
	}
}

@media (max-width : 576px) {
	.mt-sc, .mt-sc-lg {
    margin-top: 50px!important
}

.icon-arrow-line::before {
	font-size: 26px;
}
.icons-arrow-line-wr {
	gap: 25px;
}

.checkbox input + svg {
	width: 17px;
	height: 17px;
}
.checkbox {
    width: 18px;
    height: 18px;
}
.checkbox-wrapper span {
	font-size: 11px;
}
.checkbox-wrapper {
	gap: 5px;

}

ul.breadcrumb li:not(:last-child)::after {
	width: 8px;
	height: 1px;
	margin: 0 4px;
	margin-top: -1px;
}
ul.breadcrumb svg {
	width: 16px;
	height: 16px;
}
ul.breadcrumb li {
	font-size: 11px;
	line-height: 20px
}


.offcanvas-title {
	font-size: 18px;
}

.btn-close {
    width: 0.7em;
    height: 0.7em;
}

}



.fancybox__backdrop {
	background: #fff;
}

.fancybox__infobar {
	display: none
}

.fancybox__toolbar__column.is-right > button {
	background: #fff;
	color: var(--user-color);
	box-shadow: none
}

.f-thumbs__slide__img {
	border-radius: var(--br-xl);
}

.fancybox__thumbs {
	--f-thumb-width: 90px;
	--f-thumb-height: 60px;
	--f-thumb-border-radius: 7px;
}

.fancybox__footer {
	margin-top: 10px;
	background: rgba(203, 203, 203, 0.2784313725);
	z-index: 25;
}

.fancybox__thumbs.is-modern {
	--f-thumb-gap: 10px;
}



@media (max-width : 480px) {
	div#subscribeSuccess .modal-content {
    padding: 10px;
		height: 200px;
}
.success-title {
	font-size: 16px;
	margin-top: 25px;
}
}

div#subscribeFailed input {
	display: none;
}

div#subscribeFailed .modal-content {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 200px;
}

div#subscribeFailed .success-title {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 2rem;
	text-align: center;
}

.exclamation-mark-body {
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	overflow: hidden;
}

.exclamation-point {
	height: 9rem;
    line-height: 10rem;
    text-align: center;
    font-size: 7rem;
    font-family: monospace;
    color: transparent;
    border-radius: 20px;
    animation: point-anim 0.7s linear forwards;
    animation-delay: 0.5s;
  }
  
  @keyframes point-anim {
	30%, 50%, 70%, 90% {
	  transform: rotateZ(10deg);
	  /* color: var(--red); */
	  color: var(--pink);
	}
	20%, 40%, 60%, 80%, 100% {
	  transform: rotateZ(-10deg);
	  /* color: var(--red); */
	  color: var(--pink);
	}
	100% {
	  transform: rotateZ(0deg);
	}
  }


/* Canvas Accordion custom */


.accordion.canvas-custom-accordion-parent {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.accordion-item.canvas-custom-accordion-item {
	border: none;
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
	position: relative;
	padding-bottom: 40px;
}

.accordion-bottom-close {
	content: "";
	position: absolute;
	bottom: 10px;
	right: 0;
	left: 0;
	margin: 0 auto;
	width: 50%;
	background: var(--light-grey);
	height: 7px;
	border-radius: 30px;
	transition: none;
	cursor: pointer;
}

.accordion-item.canvas-custom-accordion-item .accordion-header button {
	background: #fff;
	color: var(--user-color);
	font-weight: 500;
	border: none;
	box-shadow: none;
	border-radius: var(--br-xl);
}

.accordion-item.canvas-custom-accordion-item.catalog-filter-price .accordion-header button {
	cursor: default
}

.accordion-item.canvas-custom-accordion-item .accordion-body {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.accordion-footer {
	height: 30px;
	position: absolute;
	bottom: 0;
	width: 100%;
	cursor: pointer;
}


@media (max-width: 576px) {
	.accordion-item.canvas-custom-accordion-item .accordion-header button {
    border-radius: var(--br-lg);
    font-size: 14px;
		padding: 10px 15px;
	}
	.accordion.canvas-custom-accordion-parent {
			gap: 25px;
			padding: 0.5rem;
	}
	.accordion-bottom-close {
			bottom: 6px;
			height: 4px;
	}
	.accordion-item.canvas-custom-accordion-item .accordion-body {
		gap: 10px;
		padding: 12px;
		font-size: 12px;
	}
}


/* regular toast */
.custom-regular-toast {
	--bs-toast-padding-x: 0.9rem;
	--bs-toast-padding-y: 0.8rem;
	position: fixed;
	bottom: 2%;
	left: 1%;
	border-radius: var(--br-xl);
	background: #fff;
	z-index: 1055;
	border: none;
	box-shadow: var(--shadow-block-hover);
}

.custom-regular-toast .toast-header {
	justify-content: space-between;
	font-weight: 500;
	color: var(--user-color);
	font-size: 16px;
	border-radius: var(--br-xl) var(--br-xl) 0 0;
}

.form-check-input:focus {
	outline: 0;
	box-shadow: none;
}


.product-block-title {
	font-size: 28px;
	font-weight: 500;
	line-height: normal;
}

@media (max-width: 1200px) {
	.product-block-title {
    font-size: 22px;
}
}

@media (max-width: 576px) {
	.product-block-title {
    font-size: 20px;
}
}




/* dropzone custom styles */

.dropzone {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	border: 1px solid rgba(0, 0, 0, 0.0901960784);
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	gap: 30px;
}


.dropzone .icon-add-img {
	width: 50px;
	height: 50px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 5px;
}

.dropzone .dz-default.dz-message {
	margin: auto;
}

.dropzone .dz-preview.dz-image-preview {
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
	margin: 0 auto;
}

.dz-remove-file span {
	display: inherit;
	cursor: pointer!important;
}

.dz-remove-file {
	position: absolute;
	top: 6px;
	right: 6px;
	z-index: 30;
	background: var(--red);
	color: #fff;
	line-height: 1;
	border-radius: 100%;
	padding: 5px;
	font-size: 13px;
	cursor: pointer!important;
	display: block;
}

.dropzone .dz-preview .dz-details .dz-filename:hover {
	overflow: hidden
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
	border: none;
	background-color: rgba(255, 255, 255, 0.4);
}

.dropzone .dz-error-mark svg {
	fill: var(--red)!important;
}

.dropzone .dz-preview .dz-error-message {
	font-size: 12px;
	line-height: 18px;
	text-align: center;
}

.dropzone .dz-preview .dz-success-mark svg {
	fill: var(--brigth-green)!important;
}


.loading-wrapper .spinner-border {
	position: absolute;
	top: -25px;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	display: block;
	--bs-spinner-width: 3rem;
	--bs-spinner-height: 3rem;
}

.loading-wrapper .loading-text {
	position: absolute;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	font-size: 18px;
	font-weight: 500;
}