
@font-face {
	font-family: 'Roboto';
	src:url('../fonts/Roboto-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:url('../fonts/Roboto-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:url('../fonts/Roboto-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src:url('../fonts/Roboto-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto+Condensed';
	src:url('../fonts/RobotoCondensed-Medium.woff2') format('woff2');
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto+Condensed';
	src:url('../fonts/RobotoCondensed-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto+Condensed';
	src:url('../fonts/RobotoCondensed-Bold.woff2') format('woff2');
	font-weight: 700;
	font-display: swap;
}
