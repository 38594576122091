:root {

	--pink: #B1839C;
	--green: #81ACB7;
	--brown: #D1B19D;
	--white: #E7E4DF;
	--user-color: #2C3638;
	--dark-grey: #666666;
	--light-grey: #CBCBCB;
	--grey-ss: #E9EEF5;
	--light-wihte: #00000012;
	--orange: #FFB802;
	--red: #FC3F1D;
	--brigth-green: #00C243;
	--brigth-red: #FC3F1D;


	--pink-hover: #C2A2B4;
	--green-hover: #A1BFC7;
	--brown-hover: #E0C7B7;
	--white-hover: #F2EBE0;



	--transition-btns: all .6s ease;
	--transition-images: all 1.5s ease-in-out;


	--br-sm: 7px;
	--br-lg: 14px;
	--br-xl: 17px;
	--br-xxl: 30px;


	--shadow-block: 0px 5px 40px 0px #0000001F;
	--shadow-block-filter: drop-shadow(0px 5px 40px rgba(0, 0, 0, 0.12));
	--shadow-mini-block: 0px 5px 22px rgba(0, 0, 0, 0.12);
	--shadow-minimal: 0px 5px 22px rgba(0, 0, 0, 0.1);

	--shadow-block-hover: 0px 7px 39px rgba(0, 0, 0, 0.3);

  --scrollbarBg: rgba(206, 206, 206, 0.308);
  --scrollbarThumb: rgba(0, 0, 0, 0.151);
  --scrollbarWidth: 10px;
  --scrollbarBorderRadius: calc(var(--scrollbarWidth) / 2);


	--custom-gap-blocks: 3rem;
}