/* 

-----------
HEADER
----------

*/



header {
	background: #FFF;
	box-shadow: 0px 5px 56px 0px rgba(0, 0, 0, 0.08);
}

.header-top-wrapper {
	background: var(--white)
}


/* header top */
.header-top {
	--header-top-height: 38px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: var(--header-top-height);
}

.header-geo-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 25px;
}

.header-city-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 10px;
	gap: 5px;
	cursor: pointer;
	transition: var(--transition-btns);
	height: var(--header-top-height);
}

.header-city-btn:hover {
	background: var(--pink);
	color: #fff;
}

.header-city-btn .icon-geo {
	font-size: 28px;
}

.prmn-cmngr__city-name {
	font-size: 14px;
	line-height: initial;
}

.header-salons-btn .icon-salons {
	font-size: 28px;
}

.header-geo-wrapper .btn.dropdown-toggle {
	align-items: center;
	border-radius: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 5px;
	height: var(--header-top-height);
	justify-content: center;
	padding: 8px 10px;
	transition: var(--transition-btns);
	background: var(--pink);
	color: #fff;
}

.header-geo-wrapper .btn.dropdown-toggle:hover,
.header-geo-wrapper .btn.dropdown-toggle.show {
	background: var(--pink);
	color: #fff;
}

.header-geo-wrapper .btn.dropdown-toggle>span:last-child {
	font-size: 14px;
}

.header-nav-wrapper nav {
	display: flex;
	align-items: center;
	font-size: 14px;
}

.header-nav-wrapper nav a {
	display: flex;
	align-items: center;
	transition: var(--transition-btns);
	padding: 10px 20px;
	height: var(--header-top-height);
	text-align: center;
}

.header-nav-wrapper nav a:hover {
	background: var(--pink);
	color: #fff;
}


.header-nav-socials-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 12px;
}

.header-nav-socials-wrapper a {
	transition: var(--transition-btns);
	border: 1px solid rgba(255, 255, 255, 0);
	border-radius: var(--br-sm);
	height: calc(var(--header-top-height) - 5px);
	margin-top: -1px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-nav-socials-wrapper a:hover {
	border-color: none;
	color: #b1839c;
}

.custom-bg {
	background-color: #e5e2dd;
	border-radius: 30px;
	padding: 20px;
}

a.list-group-item.list-group-item-action.ss {
	padding: 15px;
}

b.b-dp {
	color: #b1839c;
}

.title-dp {
	text-align: center;
	font-size: 38px;
	font-weight: 600;
}

.header-nav-socials-wrapper a span {
	display: block;
	font-size: 25px;
	padding: 4px;
}

div#iframeContainer {
	display: flex;
	position: relative;
	justify-content: center;
	background-color: #e5e2dd;
	padding: 3rem;
	border-radius: 30px;
}

.header-nav-socials-wrapper .btn.btn-stroke {
	font-size: 14px;
	height: calc(var(--header-top-height) - 6px);
	margin-top: -2px;
	display: flex;
	align-items: center;
}

.footer-socials-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
}

.footer-socials-wrapper a {
	transition: var(--transition-btns);
	border: 1px solid rgba(255, 255, 255, 0);
	border-radius: var(--br-sm);
	height: calc(var(--header-top-height) - 5px);
	margin-top: -1px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer-socials-wrapper a:hover {
	border-color: none;
}

.footer-socials-wrapper a span {
	display: block;
	font-size: 40px;
	padding: 4px;
}

/* Salons - dropdown menu */
.header-geo-wrapper .dropdown-menu {
	padding: 20px 22px;
	min-width: 20rem;
	border-radius: var(--br-xl);
	border: none;
	box-shadow: var(--shadow-mini-block);
}

.header-geo-wrapper .dropdown-menu .custom-scrollbar {
	--scrollbarWidth: 7px;
	overflow-y: auto;
	height: 100%;
	min-height: max-content;
	max-height: 300px;
	padding-right: 5px;
}

li.head-salons-item h5 {
	font-size: 17px;
	font-weight: 500;
}

li.head-salons-item.footer h5 {
	font-size: 16px;
	font-weight: 600;
	line-height: normal;
}

li.head-salons-item {
	font-size: 14px;
}

li.head-salons-item a span:last-child {
	font-size: 15px;
	font-weight: 500;
}

li.head-salons-item a>span {
	transition: var(--transition-btns)
}

li.head-salons-item a:hover>span {
	color: var(--pink);
}

li.head-salons-item a {
	display: flex;
	align-items: center;
	gap: 3px;
}

li.head-salons-item a span:first-child {
	font-size: 22px;
}

.salon-header-contacts-wr {
	margin-top: 5px;
}

.salon-header-contacts-wr>a:not(:last-child) {
	margin-bottom: 3px;
}

.salon-header-address {
	margin-bottom: 2px;
}

.header-geo-wrapper .dropdown-menu li:not(:first-child) {
	margin-top: 15px;
	border-top: 1px solid var(--light-grey);
	padding-top: 15px;
}

.header-top button.btn.dropdown-toggle.btn-default.header-btn-info {
	display: none;
	font-size: 14px;
	font-weight: 400;
}

.dropdown.header-info-links-dropdown .dropdown-menu {
	padding: 15px 20px;
	border-radius: var(--br-xl);
	border: none;
	box-shadow: var(--shadow-mini-block);
}

.dropdown.header-info-links-dropdown .dropdown-menu li a {
	font-size: 14px;
	font-weight: 500;
	transition: var(--transition-btns)
}

.dropdown.header-info-links-dropdown .dropdown-menu li:hover a {
	color: var(--pink)
}

.dropdown.header-info-links-dropdown .dropdown-menu li:not(:last-child) {
	margin-bottom: 2px;
}


.dropdown.header-info-links-dropdown .dropdown-menu {
	width: 22rem;
	padding: 15px 20px;
	border-radius: var(--br-xl);
	border: none;
	box-shadow: var(--shadow-mini-block);
}

.dropdown.header-info-links-dropdown .dropdown-menu li a {
	font-size: 14px;
	font-weight: 500;
	transition: var(--transition-btns)
}

.dropdown.header-info-links-dropdown .dropdown-menu li:hover a {
	color: var(--pink)
}

.dropdown.header-info-links-dropdown .dropdown-menu li:not(:last-child) {
	margin-bottom: 5px;
}


@media (max-width: 1400px) {
	.header-nav-wrapper nav a {
		padding: 6px;
	}

	.header-geo-wrapper {
		gap: 10px;
	}

	.header-nav-socials-wrapper {
		gap: 7px;
	}
}

@media (max-width: 1200px) {
	.header-nav-wrapper nav a {
		padding: 10px;
	}

	.header-geo-wrapper {
		gap: 10px;
	}

	.header-nav-socials-wrapper {
		gap: 7px;
	}

	.header-nav-wrapper nav,
	span#headerCityName,
	.header-geo-wrapper .btn.dropdown-toggle>span:last-child {
		font-size: 12px;
	}

	.footer-container-grid {
		grid-template-rows: 400px 400px !important;
	}
}

@media (max-width: 992px) {
	.header-nav-wrapper {
		display: none
	}

	.header-nav-socials-wrapper {
		display: none
	}

	.header-top button.btn.dropdown-toggle.btn-default.header-btn-info {
		display: flex;
	}
}

@media (max-width: 576px) {

	span#headerCityName,
	.header-geo-wrapper .btn.dropdown-toggle>span:last-child,
	.header-top button.btn.dropdown-toggle.btn-default.header-btn-info {
		font-size: 12px;
	}

	.header-city-btn .icon-geo,
	.header-geo-wrapper .icon-salons {
		font-size: 22px;
	}

	.header-geo-wrapper .btn.dropdown-toggle,
	.header-city-btn {
		padding: 8px 4px;
	}

	li.head-salons-item a span:last-child,
	.salon-header-time {
		font-size: 14px;
	}

	.salon-header-address {
		margin-bottom: 0px;
		font-size: 14px;
	}

	li.head-salons-item h5 {
		font-size: 16px;
		font-weight: 500;
	}

	.header-geo-wrapper .dropdown-menu {
		min-width: 100vw;
		border-radius: 0;
	}

	.dropdown.header-info-links-dropdown .dropdown-menu li a {
		font-size: 12px;
	}
}


/* header center */

.header-center-wrapper {
	margin-top: 20px;
}

.header-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
}

a.btn.btn-default.btn-pink.header-btn-sales span:first-child {
	font-size: 22px;
	margin-right: 5px;
}

a.btn.btn-default.btn-pink.header-btn-sales {
	padding: 7px;
	font-size: 14px;
	width: auto
}

a.btn.btn-default.btn-pink.header-btn-sales:hover {
	color: #fff;
}

.btn.dropdown-toggle.btn-default.header-btn-info {
	padding: 15px;
	background: rgba(255, 255, 255, 0);
	font-size: 14px;
	font-weight: 500;
	color: var(--user-color);
}

.btn.dropdown-toggle.btn-default.header-btn-info:hover {
	color: var(--pink);
}

/* search-input */
.search-header {
	width: 39%;
	position: relative;
}

input.search__input {
	width: 100%;
	border: none;
	border-radius: var(--br-xl);
	background: rgba(44, 54, 56, 0.07);
	padding: 14px 22px;
	transition: all .6s ease-in-out;
}

input.search__input:focus-visible {
	outline: none;
}

input.search__input:focus-visible,
input.search__input:focus {
	outline: none;
	box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.1);
}

.search-btn {
	position: absolute;
	top: 0;
	bottom: 0;
	border: none;
	background: none;
	outline: 0;
	right: 5px;
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer
}

.search-btn .icon-search {
	font-size: 25px;
	display: block;
}


.header-favourites-btn {
	cursor: pointer;
	position: relative;
}

.header-favourites-btn .icon-heart,
.btn-header-cart .icon-cart,
.header-compare-btn .icon-main-compare {
	font-size: 37px;
	transition: all .15s ease-in-out
}

.header-favourites-btn .icon-heart::before,
.btn-header-cart .icon-cart::before,
.header-compare-btn .icon-main-compare::before {
	transition: all .15s ease-in-out
}

.header-total-btns {
	position: absolute;
	top: -6px;
	right: -10px;
	font-size: 12px;
	font-weight: 500;
	background: var(--pink);
	color: #fff;
	border-radius: 100%;
	padding: 5px 3px;
	display: block;
	width: 21px;
	text-align: center;
	height: 21px;
	line-height: 1;
	transition: all .5s ease
}

.header-favourites-total-btns {
	position: absolute;
	top: -6px;
	right: -10px;
	font-size: 12px;
	font-weight: 500;
	background: var(--pink);
	color: #fff;
	border-radius: 100%;
	padding: 5px 3px;
	display: block;
	width: 21px;
	text-align: center;
	height: 21px;
	line-height: 1;
}

.header-favourites-total-btns.empty {
	opacity: 0;
}

.header-compare-btn {
	cursor: pointer;
	position: relative;
}

.btn-header-cart {
	position: relative;
}

.header-favourites-btn:hover>.icon-heart,
.header-compare-btn:hover>.icon-main-compare,
.btn-header-cart:hover>.icon-cart {
	color: var(--pink);
}

@media (max-width: 1580px) {
	.search-header {
		width: 29%;
	}
}

@media (max-width: 1400px) {
	.header-center {
		gap: 15px;
	}

	.btn.dropdown-toggle.btn-default.header-btn-info {
		padding: 15px 5px;
	}

	input.search__input {
		padding: 14px 15px;
	}
}

@media (max-width: 1200px) {
	a.btn.btn-default.btn-pink.header-btn-sales {
		padding: 5px;
		font-size: 12px;
		width: 22%;
	}

	.header-center {
		gap: 15px;
	}

	.btn.dropdown-toggle.btn-default.header-btn-info {
		font-size: 12px;
	}

	.header-favourites-btn .icon-heart,
	.btn-header-cart .icon-cart,
	.header-compare-btn .icon-main-compare {
		font-size: 32px;
	}

	.search-header {
		width: 40%;
	}

	input.search__input {
		font-size: 12px;
	}

	.search-btn {
		right: 0px;
	}
}

@media (max-width: 992px) {
	.header-center .dropdown.header-info-links-dropdown {
		display: none;
	}

	img.header-logo {
		width: 100%;
	}

	.header-total-btns {
		position: absolute;
		top: -6px;
		right: -10px;
		font-size: 12px;
		font-weight: 500;
		background: var(--pink);
		color: #fff;
		border-radius: 100%;
		padding: 5px 3px;
		display: block;
		width: 21px;
		text-align: center;
		height: 21px;
		line-height: 1;
	}

	a.btn.btn-default.btn-pink.header-btn-sales {
		display: none;
	}

	header .header-favourites-btn,
	header .header-compare-block,
	header .btn-header-cart,
	.header-center .search-header {
		display: none
	}
}


/* 
   HEADER BOTTOM BLOCK
*/

.head-phone-main {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #2c3638;
	color: var(--user-color);
	text-decoration: none;
	font-weight: 500;
	transition: all .6s ease;
	transition: var(--transition-btns);
	justify-content: flex-end;
	gap: 5px;
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.head-phone-main span {
	transition: var(--transition-btns);
	display: inline-block;
	font-size: 24px;
}

.head-phone-main:hover {
	color: var(--pink);
}

.head-phone-main:hover span {
	animation: header_phone_anim 2s ease 0s infinite normal none;
	color: var(--pink)
}

@keyframes header_phone_anim {

	30%,
	70% {
		transform: rotate(0deg);
	}

	35%,
	65% {
		transform: rotate(10deg);
	}

	40%,
	60% {
		transform: rotate(-10deg);
	}

	45%,
	55% {
		transform: rotate(10deg);
	}

	50% {
		transform: rotate(-10deg);
	}
}

.header-bottom {
	display: flex;
	align-items: center;
	gap: 40px;
}

nav#header_mega_menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 14%;
}

.header-bottom-wrapper {
	margin-top: 22px;
	position: relative
}

.header-phone-desktop.head-phone-main {
	padding-left: 0;
	font-size: 20px;
	position: absolute;
	top: 2px;
}

.header-phone-desktop.head-phone-main .head-phone-main span {
	font-size: 27px;
}

.header-mmenu-item .menu-text a h3,
.header-mmenu-item .menu-text-one a h3 {
	font-size: 16px;
	font-weight: 400;
	margin: 0;
	padding: 0;
}

.header-mmenu-item {
	/* 	margin: 0 20px; */
	padding: 6px 20px 15px 20px;
}

.header-mmenu-item .menu-text a,
.header-mmenu-item .menu-text-one a {
	position: relative;
	display: block;
	top: -1px;
	padding-bottom: 3px;
}

.header-mmenu-item .menu-text a:after,
.header-mmenu-item .menu-text-one a:after {
	background: none repeat scroll 0 0 transparent;
	bottom: 0;
	content: "";
	display: block;
	height: 2px;
	left: 50%;
	position: absolute;
	background: var(--pink);
	transition: width 0.3s ease 0s, left 0.3s ease 0s;
	width: 0;
}

.header-mmenu-item:hover .menu-text a:after,
.header-mmenu-item:hover .menu-text-one a:after {
	width: 100%;
	left: 0;
}

@media (max-width: 1600px) {

	.header-mmenu-item .menu-text a h3,
	.header-mmenu-item .menu-text-one a h3 {
		font-size: 15px;
	}
}

@media (max-width: 1400px) {
	.header-bottom {
		gap: 25px;
	}

	.header-mmenu-item .menu-text a h3,
	.header-mmenu-item .menu-text-one a h3 {
		font-size: 14px;
	}

	nav#header_mega_menu {
		margin-left: 16%;
	}

	.header-phone-desktop.head-phone-main {
		font-size: 16px;
	}

	.header-phone-desktop.head-phone-main {
		top: 1px;
	}
}

@media (max-width: 1200px) {
	.header-bottom {
		gap: 10px;
	}

	.header-bottom.head-phone-main span {
		font-size: 20px;
	}


	.header-phone-desktop.head-phone-main {
		font-size: 18px;
	}

	nav#header_mega_menu {
		margin-left: 19%;
	}

	.header-mmenu-item .menu-text a h3,
	.header-mmenu-item .menu-text-one a h3 {
		font-size: 12px;
	}
}

@media (max-width: 992px) {

	.header-phone-desktop.head-phone-main,
	#header_mega_menu {
		display: none !important;
	}
}


/* Desktop mega-menu */
.desktop-sub-menu {
	position: absolute;
	top: 27px;
	left: 0;
	width: 100vw;
	display: none;
	margin: 0 auto;
	background: #fff;
	box-shadow: none;
	padding-top: 45px;
	padding-bottom: 30px;
	opacity: 0;
	z-index: 10
}

@keyframes sub-menu-anime-open {
	0% {
		opacity: 0;
		box-shadow: none;
	}

	1% {
		opacity: 0;
		box-shadow: none;
	}

	100% {
		opacity: 1;
		box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.08);
	}
}

.header-mmenu-item:hover>.desktop-sub-menu {
	display: block;
	animation: sub-menu-anime-open .6s ease-in-out forwards;
}

.sub-menu-col {
	border-radius: var(--br-xl);
	padding: 10px 15px;
	box-shadow: var(--shadow-block);
	height: 230px;
}

h4.sub-menu-category {
	font-size: 15px;
	font-weight: 500;
	margin: 0;
	margin-bottom: 5px;
}

.sub-menu-col ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	border-top: 1px solid var(--light-grey);
	padding-top: 10px;
	overflow-y: auto;
	height: 180px;
}

/* .sub-menu-col ul.custom-scrollbar {
	scrollbar-width: 5px;
	scrollbar-color: var(--scrollbarThumb) var(--scrollbarBg);
}

.sub-menu-col ul.custom-scrollbar::-webkit-scrollbar {
	width: 5px;
} */

.sub-menu-col ul li:not(:last-child) {
	margin-bottom: 5px;
}

.sub-menu-col ul li {
	font-size: 14px;
}

.sub-menu-col ul li:hover>a {
	color: var(--pink);
}

.sub-menu-col ul li a {
	transition: var(--transition-btns)
}

.desktop-sub-menu::after {
	content: "";
	position: absolute;
	top: 20px;
	left: 0;
	right: 0;
	width: 100%;
	height: 1px;
	background-color: #E8E8E8;
}

.header-menu-bottom-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 20px;
}

.header-menu-bottom-container span {
	font-size: 16px;
	font-weight: 500;
}

.header-menu-bottom-container a.btn.btn-default.btn-pink {
	width: 100%;
	max-width: fit-content;
	font-size: 14px;
	padding: 10px 20px;
	font-weight: 400;
}

.header-menu-bottom-container a.btn.btn-default.btn-pink:hover {
	color: #fff
}


/* mega-menu stocks slider */
a.header-menu-sale-wrapper {
	display: block;
	position: relative;
}

a.header-menu-sale-wrapper img {
	width: 100%;
	border-radius: var(--br-xl);
	box-shadow: var(--shadow-block);
	height: 140px;
	object-fit: cover;
	object-position: center center;
}

.swiper.megaMenuStockSlider {
	margin-top: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid var(--light-grey);
}

.header-menu-sale-title {
	position: absolute;
	bottom: 10px;
	right: 10px;
	background: rgba(255, 255, 255, 0.75);
	border-radius: var(--br-lg);
	padding: 2px 12px;
	font-size: 12px;
	font-weight: 500;
}

.megaMenuStockSlider .swiper-button-next,
.megaMenuStockSlider .swiper-button-prev {
	top: var(--swiper-navigation-top-offset, 45%);
}

.header-menu-stock-sale {
	position: absolute;
	top: 5px;
	right: 10px;
	font-size: 12px;
	background: var(--red);
	color: #fff;
	padding: 0px 8px;
	border-radius: var(--br-sm);
	font-weight: 400;
}

@media (max-width: 1400px) {
	a.header-menu-sale-wrapper img {
		height: 150px;
		object-fit: cover;
		object-position: 50% 65%;
	}
}

@media (max-width: 1200px) {
	.sub-menu-col ul li:not(:last-child) {
		margin-bottom: 2px;
	}

	.sub-menu-col ul li {
		font-size: 12px;
	}

	.sub-menu-col ul {
		padding-top: 5px;
	}

	h4.sub-menu-category {
		font-size: 14px;
	}

	.sub-menu-col {
		padding: 12px 10px;
	}
}

/* mobile header catalog */
.dcm__nav {
	position: fixed;
	left: 0;
	z-index: 100010;
	top: 0;
	width: 100%;
	height: 200vh;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
	-webkit-transition: -webkit-transform 0.3s;
	transition: -webkit-transform 0.3s;
	-o-transition: transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
	background: #fff;
	overflow-y: auto;
}

.header-fixed .dcm__nav {
	top: 102px;
}

.dcm__nav--open {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.dcm__wrapper {
	position: absolute;
	top: 3.5em;
	bottom: 0;
	overflow-x: hidden;
	width: 100%;
	height: 86vh;
}

.dcm__wrapper ul {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	overflow-x: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	padding-right: 17px;
	list-style-type: none;
}

.dcm__wrapper ul.dcm__level--current {
	visibility: visible;
}

.dcm__wrapper ul>li {
	display: block;
	width: 100%;
}

.dcm__wrapper ul>li>a,
.dcm__wrapper ul>li>.menu-item-no-link {
	font-size: 14px;
	font-weight: 500;
	position: relative;
	display: flex;
	padding: 1em 2.5em 1em 1.5em;
	color: var(--grey-text);
	-webkit-transition: color 0.1s;
	-o-transition: color 0.1s;
	transition: color 0.1s;
	text-decoration: none;
	justify-content: flex-start;
}



/* .dcm__wrapper ul>li.menu-item-has-children>a::after {
	content: "\e911";
	transform: rotate(180deg);
	font-family: "icomoon";
	position: absolute;
	right: 0;
	padding: 0.25em 1.25em;
	color: #2a2b30;
}

.dcm__wrapper ul>li>a:hover,
.dcm__wrapper ul>li.menu-item-has-children>a:hover::after {
	color: var(--pink);
} */

.dcm__wrapper .menu-item-children-link::after {
	content: "\e911";
	transform: rotate(180deg);
	font-family: "icomoon";
	position: absolute;
	right: 0;
	padding: 0.25em 2em;
	color: #2a2b30;
}

.dcm__wrapper ul>li>a:hover,
.dcm__wrapper .menu-item-children-link:hover::after {
	color: var(--pink);
}

.dcm__wrapper ul>li.current-menu-item>a::before {
	content: ".";
	font-size: 2.5em;
	line-height: 0;
	position: absolute;
	top: 15px;
	left: 6px;
	height: 4px;
	color: var(--pink);
}

ul.dcm-animate-outToRight,
ul.dcm-animate-outToLeft,
ul.dcm-animate-inFromLeft,
ul.dcm-animate-inFromRight {
	visibility: visible;
}

.dcm-animate-outToRight>li {
	-webkit-animation: dcmOutToRightTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
	animation: dcmOutToRightTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.dcm-animate-outToLeft>li {
	-webkit-animation: dcmOutToLeftTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
	animation: dcmOutToLeftTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.dcm-animate-inFromLeft>li {
	-webkit-animation: dcmInFromLeftTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
	animation: dcmInFromLeftTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.dcm-animate-inFromRight>li {
	-webkit-animation: dcmInFromRightTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
	animation: dcmInFromRightTransform 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.dcm-animate-outToLeft>li>a,
.dcm-animate-outToRight>li>a {
	-webkit-animation: dcmOpacityOut 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
	animation: dcmOpacityOut 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.dcm-animate-inFromLeft>li>a,
.dcm-animate-inFromRight>li>a {
	-webkit-animation: dcmOpacityIn 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
	animation: dcmOpacityIn 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

.dcm__breadcrumbs {
	font-size: 0.65em;
	line-height: 1;
	position: relative;
	padding: 2.5em 5rem 1em 2em;
}

.dcm__breadcrumbs a {
	font-weight: bold;
	display: inline-block;
	cursor: pointer;
	vertical-align: middle;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: var(--pink);
	font-size: 8px;
	color: #000;
	font-weight: 600;
	letter-spacing: 0.48px;
}

.dcm__breadcrumbs a>div {
	display: none;
}

.dcm__breadcrumbs a:last-child {
	pointer-events: none;
}

.dcm__breadcrumbs a:hover {
	color: var(--pink);
}

.dcm__breadcrumbs a:not(:last-child)::after {
	content: "\e921";
	font-family: "icomoon";
	display: inline-block;
	padding: 0 0.2em;
	color: #33353e;
}

.dcm__breadcrumbs a:not(:last-child):hover::after {
	color: #33353e;
}

.dcm__back {
	font-size: 1.05em;
	position: absolute;
	z-index: 100;
	top: 0;
	right: 3em;
	margin: 0;
	padding: 1.365em 0.65em 0 0;
	cursor: pointer;
	color: #2a2b30;
	border: none;
	background: none;
}

.dcm__back--hidden {
	pointer-events: none;
	opacity: 0;
}

.dcm__back:hover,
.dcm__back:focus {
	color: var(--pink);
	outline: none;
}

.dcm__icon {
	font-family: "icomoon";
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	text-transform: none;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.dcm__icon--arrow-left:before {
	content: '\e911';
	display: block;
}

.dcm__icon--menu:before {
	content: "\e922";
	font-size: 50px;
}

.dcm__icon--cross:before {
	content: "\e914";
}

.dcm__action {
	margin: 0;
	padding: 0;
	cursor: pointer;
	border: none;
	background: none;
}

.dcm__action:focus {
	outline: none;
}

.dcm__action--open {
	font-size: 35px;
	color: var(--user-color);
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 60px;
	background-color: transparent;
	border-radius: var(--border-radius-sm);
	transition: var(--transition-btns);
	padding: 4px 15px;
}

.dcm__action--close {
	position: absolute;
	font-size: 1.2em;
	top: 1.05em;
	right: 1em;
	color: #45464e;
}

.dcm__action_btn_text {
	display: block;
	font-size: 12px;
	margin-top: -2px;
	letter-spacing: 0.5px;
}

@-webkit-keyframes dcmOpacityOut {
	to {
		opacity: 0;
	}
}

@keyframes dcmOpacityOut {
	to {
		opacity: 0;
	}
}

@-webkit-keyframes dcmOpacityIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes dcmOpacityIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes dcmOutToRightTransform {
	to {
		margin-left: 100%;
	}
}

@keyframes dcmOutToRightTransform {
	to {
		margin-left: 100%;
	}
}

@-webkit-keyframes dcmOutToLeftTransform {
	to {
		margin-left: -100%;
	}
}

@keyframes dcmOutToLeftTransform {
	to {
		margin-left: -100%;
	}
}

@-webkit-keyframes dcmInFromLeftTransform {
	from {
		margin-left: -100%;
	}

	to {
		margin-left: 0;
	}
}

@keyframes dcmInFromLeftTransform {
	from {
		margin-left: -100%;
	}

	to {
		margin-left: 0;
	}
}

@-webkit-keyframes dcmInFromRightTransform {
	from {
		margin-left: 100%;
	}

	to {
		margin-left: 0;
	}
}

@keyframes dcmInFromRightTransform {
	from {
		margin-left: 100%;
	}

	to {
		margin-left: 0;
	}
}


a.mobile-menu-line {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

a.nav-link-mobile {
	font-size: 14px;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

body.body-ov-hidden {
	overflow: hidden;
}

.dcm__wrapper a.head-phone-main {
	font-size: 16px;
	padding: 1em 2.5em 1em 1.2em;
}

.dcm__wrapper svg.phone-head-icon {
	width: 20px;
	height: 20px;
	margin-top: -2px;
}

.dcm-social-wr {
	font-weight: 600;
	position: relative;
	display: block;
	padding: 0em 2.5em 0em 1em;
	color: var(--grey-text);
	transition: color 0.1s;
	text-decoration: none;
}

.dcm-social-wr svg {
	width: 32px;
	height: 32px;
}

button.dcm__action.dcm__action--open.header-hamburger-btn {
	display: none
}

.dcm__wrapper ul.custom-scrollbar {
	scrollbar-width: 7px;
}

.dcm__wrapper ul.custom-scrollbar::-webkit-scrollbar {
	width: 7px;
}

.dcm-social-wr a span {
	font-size: 26px;
}

a.mobile-menu-line {
	display: block !important;
}

@media (max-width: 992px) {
	button.dcm__action.dcm__action--open.header-hamburger-btn {
		display: block
	}
}


/*
Mobile bottom navigation menu
*/

.mobile-bottom-nav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100vw;
	box-shadow: 0px -10px 25px rgba(0, 0, 0, 0.14);
	border-radius: 17px 17px 0px 0px;
	background: #fff;
	z-index: 1001;
}

ul.mobile-bottom-nav-list {
	list-style-type: none;
	margin: 0;
	padding: 10px 0;
	padding-top: 12px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

ul.mobile-bottom-nav-list li {
	position: relative;
}

ul.mobile-bottom-nav-list li a span:first-child {
	font-size: 32px;
	transition: var(--transition-btns)
}

ul.mobile-bottom-nav-list li:hover>a span:first-child {
	color: var(--pink)
}

/* ul.mobile-bottom-nav-list .header-total-btns {
	top: -23px;
	right: -16px;
	width: 25px;
	height: 25px;
	background: #fff;
	color: var(--user-color);
	padding: 5px 1px;
	font-size: 14px;
} */

.header-total-btns.empty {
	opacity: 0
}

/*
mobile modal call
*/

.modal-mobile-call .modal-content {
	border-radius: var(--br-xl);
	box-shadow: var(--shadow-mini-block);
	border: none;
	outline: none;
	width: 20rem;
	margin: 0 auto;
}

.modal-mobile-call .btn-close {
	position: absolute;
	right: 12px;
	top: 9px;
	background-size: contain;
	width: 9px;
	height: 9px;
	z-index: 20;
}

.modal-mobile-call .modal-content .modal-body ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.modal-mobile-call .modal-content .modal-body {
	padding: 2rem;
}

.modal-mobile-call .modal-content .modal-body ul li a span {
	display: block;
}

.modal-mobile-call .modal-content .modal-body ul li a span:last-child {
	font-size: 16px;
	font-weight: 500;
}

.modal-mobile-call .modal-content .modal-body ul li a span:first-child {
	font-size: 25px;
}

.modal-mobile-call .modal-content .modal-body ul li a {
	display: flex;
	align-items: center;
	gap: 7px;
}

.modal-mobile-call .modal-content .modal-body ul li:not(:last-child) {
	margin-bottom: 20px;
}

/*
ajax search desktop
*/

a.btn.btn-default.btn-pink.ajax-search-view-all-send span {
	position: absolute;
	right: -8px;
	top: -7px;
}

.ajax-search-view-all {
	position: relative;
	width: 250px;
}

.ajax-search-product {
	width: 45%;
	border-radius: var(--br-lg);
	box-shadow: var(--shadow-minimal);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 15px 10px;
	height: -moz-max-content;
	height: -webkit-max-content;
	height: max-content;
	transition: all 0.6s ease-in-out;
	gap: 5px;
	line-height: 16px;
}

.ajax-search-product:hover {
	box-shadow: var(--shadow-block);
}

.ajax-search-product img {
	width: auto;
	height: 30px;
	object-fit: contain;
}

.ajax-search-pr-text h4 {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 2px;
}

.ajax-search-pr-text>div {
	color: var(--dark-grey);
	font-size: 11px;
}

.ajax-search-info {
	border-radius: var(--br-lg);
	box-shadow: var(--shadow-mini-block);
	width: 30%;
	padding: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 15px;
}

.ajax-search-history-wr .title-- {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ajax-search-history-wr .title-- span {
	display: block;
	cursor: pointer;
	font-size: 20px;
	transition: all .5s ease-in-out
}

.ajax-search-history-wr .title-- span:hover {
	color: var(--pink)
}

.ajax-search-histories {
	overflow-y: auto;
	height: 170px;
	font-size: 14px;
	color: var(--dark-grey);
	list-style-type: none;
	padding: 0;
	margin-top: 10px;
}

.ajax-search-categories-wr {
	height: 100px;
	overflow-y: auto;
}

.ajax-search-info .title-- {
	margin-bottom: 4px;
	font-weight: 600;
}

.ajax-search-categories {
	color: var(--dark-grey);
}

.ajax-search-block-items-wr {
	display: flex;
	justify-content: space-between;
	gap: 25px;
}

a.ajax-search-view-all {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 250px;
	font-weight: 400;
	height: 40px;
}

.ajax-search-view-all .btn.btn-default.btn-pink {
	width: inherit;
	font-weight: 400;
	height: 40px;
}

.ajax-search-view-all .btn.btn-default.btn-pink:hover {
	color: #fff
}

.ajax-search-empty {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: block;
	margin: auto;
	width: max-content;
	height: max-content;
}

.ajax-search-spinner {
	-webkit-animation: rotator 1.4s linear infinite;
	animation: rotator 1.4s linear infinite;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: max-content;
	height: max-content;
}

@-webkit-keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(270deg);
	}
}

.ajax-search-spinner .path {
	stroke-dasharray: 187;
	stroke-dashoffset: 0;
	transform-origin: center;
	-webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
	animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
	0% {
		stroke: var(--pink);
	}

	25% {
		stroke: var(--green);
	}

	50% {
		stroke: var(--brown);
	}

	75% {
		stroke: var(--white);
	}

	100% {
		stroke: var(--pink);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}

	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}

@keyframes dash {
	0% {
		stroke-dashoffset: 187;
	}

	50% {
		stroke-dashoffset: 46.75;
		transform: rotate(135deg);
	}

	100% {
		stroke-dashoffset: 187;
		transform: rotate(450deg);
	}
}

@media (max-width: 1200px) {
	.ajax-search-block {
		right: -155px;
	}
}

@media (max-width: 992px) {
	.ajax-search-block-items-wr {
		gap: 15px;
		flex-direction: column;
		padding: var(--bs-modal-padding);
	}


	.modal-search.modal .modal-dialog {
		height: 100%;
	}

	.modal-search.modal {
		background: #fff;
	}

	.modal-content.modal-search-content {
		border: none;
		outline: none;
		box-shadow: none;
	}

	.modal-search-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
	}

	.modal-search-wrapper .search-header {
		width: 100%;
	}

	.modal-search-wrapper .btn-close {
		margin-top: -3px;
	}

	.modal-search-info-blocks {
		background: #fff;
		box-shadow: var(--shadow-minimal);
		padding: 12px 15px;
		margin-top: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 25px;
		border-radius: var(--br-lg);
	}

	.modal-sarch-title span:first-child {
		font-size: 15px;
		font-weight: 500;
	}

	.modal-sarch-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
	}

	.modal-sarch-title .icon-delete {
		font-size: 20px;
		padding: 2px;
	}

	ul.search-info-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 5px;
	}

	ul.search-info-list li {
		font-size: 14px;
		color: var(--dark-grey);
	}

	ul.search-info-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-top: 7px;
		height: 105px;
		overflow-y: auto;
	}

	ul.search-info-list.custom-scrollbar {
		scrollbar-width: 6px;
	}

	ul.search-info-list.custom-scrollbar::-webkit-scrollbar {
		width: 6px;
	}

	.modal-search-items {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 14px;
		flex-wrap: wrap;
		margin-top: 22px;
		margin-bottom: 30px;
	}

	.ajax-search-pr-text>div {
		font-size: 11px;
		color: var(--dark-grey);
	}

	.ajax-search-product img {
		object-fit: contain;
		width: 60px !important;
		height: 50px;
	}

	.btn.btn-default.btn-pink.search-btn {
		font-size: 14px;
		font-weight: 400;
	}

	.ajax-search-product {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 7px;
		width: 48%;
		box-shadow: var(--shadow-minimal);
		border-radius: var(--br-lg);
	}

	.ajax-search-pr-text h4 {
		font-size: 12px;
		font-weight: 500;
		margin-bottom: 0;
	}

	.ajax-search-block {
		flex-direction: column;
		padding-bottom: 15px;
	}

	.ajax-search-products-wr.custom-scrollbar {
		order: 2;
	}

	.ajax-search-info {
		border-radius: var(--br-lg);
		padding: 12px;
		gap: 0;
		width: 100%
	}

	.ajax-search-history-wr .title-- span {
		font-size: 18px;
	}

	.ajax-search-histories {
		height: 75px;
		margin-top: 5px;
		margin-bottom: 0;
	}

	.ajax-search-view-all {
		width: 100%;
	}

	a.btn.btn-default.btn-pink.ajax-search-view-all-send span {
		right: -2px;
		top: -9px;
	}

	.ajax-search-empty {
		position: static;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 55vh;
		text-align: center;
		font-size: 14px;
	}
}

@media (max-width: 480px) {
	.modal {
		--bs-modal-padding: 0.4rem;
	}

	.ajax-search-info {
		margin-top: 12px;
	}

	.modal-search-items {
		margin-top: 10px;
		margin-bottom: 25px;
		gap: 12px;
	}
}


@media (max-width: 350px) {
	.ajax-search-product {
		justify-content: center;
		gap: 12px;
		padding: 8px 10px;
		width: 100%;
	}

	.ajax-search-pr-text>div {
		font-size: 14px;
	}

	.ajax-search-pr-text h4 {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 5px;
	}

	.ajax-search-product img {
		width: 100px !important;
		height: 80px;
	}
}

@media (min-width: 992px) {
	.ajax-search-block {
		width: 800px;
		position: absolute;
		z-index: 1001;
		margin-top: 20px;
		background-color: #fff;
		border-radius: var(--br-xxl);
		box-shadow: var(--shadow-block);
		padding: 25px;
		left: 0;
		right: -225px;
		margin-left: auto;
		display: none;
	}

	.ajax-search-block-wr {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.ajax-search-products-block {
		display: flex;
		justify-content: flex-start;
		gap: 20px;
		flex-wrap: wrap;
		width: 100%;
		overflow-y: auto;
		height: max-content;
		padding: 10px 0;
		padding-bottom: 17px;
	}

	.ajax-search-products-wr {
		width: 80%;
		overflow-y: auto;
		height: 250px;
		padding-right: 5px;
	}
}







/* 
Modal Contact Form CallBack
*/

.container-cf-header * {
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.material-icons {
	font-size: 18px;
	margin-left: 4px;
}

.container-cf-header {
	display: grid;
	grid-template-columns: repeat(2, minmax(min-content, 24rem));
	grid-template-rows: repeat(6, min-content);
	align-items: center;
	background: #fff;
	border-radius: var(--br-xxl);
	box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25);
	position: relative
}

@media only screen and (max-width: 992px) {
	.container-cf-header {
		grid-template-columns: 100%;
		/*   grid-template-rows: repeat(6, min-content); */
	}
}


.cf-head {
	grid-row: 1/2;
	grid-column: 1/4;
	padding: 2.8rem 4rem;
	margin-bottom: 1.4rem;
	font-size: 3.2rem;
	color: var(--user-color);
	border-top-left-radius: var(--br-xxl);
	border-top-right-radius: var(--br-xxl);
	background: var(--white);
	font-weight: 700;
}

.form-cf-header-item {
	grid-column: 1/2;
	display: flex;
	flex-direction: column;
	margin: 15px 0 0 4rem;
	position: relative;
	box-shadow: var(--shadow-mini-block);
	border-radius: var(--br-lg);
	padding: 2px 15px;
}

.form-cf-header-item.footer {
	grid-column: 1/2;
	display: flex;
	flex-direction: column;
	margin: 15px 0px;
	position: relative;
	box-shadow: var(--shadow-mini-block);
	border-radius: var(--br-lg);
	padding: 2px 15px;
	justify-content: center;
}


.form__1 {
	grid-row: 2/3;
}

.form__2 {
	grid-row: 3/4;
}

.form__3 {
	grid-row: 4/5;
}

.form__4 {
	grid-row: 5/6;
}


.field-city .form__1 {
	grid-row: 2/3;
}

.field-city .form__33 {
	grid-row: 3/4;
}

.field-city .form__2 {
	grid-row: 4/5;
}

.field-city .form__3 {
	grid-row: 5/6;
}

.field-city .form__4 {
	grid-row: 6/7;
}


.form-cf-header-item.active label {
	transform: translateY(-2.2rem);
	visibility: visible;
	font-size: 18px;
	color: var(--user-color);
}

.form__input {
	font-size: 16px;
	padding: 1rem 0;
	border: none;
	background: transparent;
	padding-right: 35px;
}

.form__input-footer {
	font-size: 16px;
	padding: 0.5rem 0;
	border: none;
	background: transparent;
}

.form__input:focus-visible,
.form__input:focus,
.form__input:active {
	outline: none;
}

.form__btn p {
	margin: 0;
	padding: 0;
}

.form__label {
	font-size: 16px;
	color: #0000007d;
	position: absolute;
	left: 1.2rem;
	top: 1rem;
	pointer-events: none;
	transition: all 0.3s;
	display: flex;
	gap: 10px;
	transition: all .7s ease-in-out;
}

.form__label.footer {
	font-size: 16px;
	color: #0000007d;
	position: absolute;
	left: 1.2rem;
	top: auto;
	pointer-events: none;
	transition: all 0.3s;
	display: flex;
	gap: 10px;
	transition: all .7s ease-in-out;
	align-items: center;
}

textarea.form__input {
	min-height: 165px !important;
}

.form__label>span:first-child,
.form__label.footer>span:first-child {
	font-size: 20px;
}


.form__btn {
	grid-column: 1/2;
	grid-row: 7/8;
	background: var(--pink);
	color: #fff;
	border: none;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 1.3px;
	padding: 1.1rem 0;
	margin: 30px 0 3rem 4rem;
	cursor: pointer;
	border-radius: var(--br-lg);
	box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.15);
	overflow: hidden;
	position: relative;
	transition: all 0.4s;
}

@media only screen and (max-width: 992px) {
	.form__btn {
		margin: 1.8rem 4rem 4rem 4rem;
	}
}

span.cf-label-name {
	transition: all .2s ease;
}

.form__btn:focus {
	outline: 0;
}

.form__btn:hover {
	background-color: var(--pink-hover);
}

.form__btn>* {
	transition: all 0.4s;
	transition-delay: 1s;
	display: inline-block;
}

.form__btn--invisible {
	position: absolute;
	width: 100%;
	left: 0;
	top: -100%;
}

.form__btn--invisible.visible {
	top: 16px;
}

.form__btn--visible {
	display: flex;
	justify-content: center;
	align-items: center;
}

.form__btn--visible.invisible {
	transform: translateY(200%);
}

.container-mail {
	grid-column: 2/3;
	grid-row: 2/6;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 31rem;
	height: 24rem;
	margin-bottom: 4rem;
	transition: all .6s ease
}

.container-mail.mail-success {
	transform: translateX(-45%);
}

button.form__btn.mail-success {
	transform: translateX(67%);
}


@media only screen and (max-width: 992px) {

	.container-mail {
		display: none
	}
}

.mail {
	position: relative;
	top: -4rem;
	left: -6rem;
}

.mail__top {
	position: absolute;
	top: -5.7rem;
	width: 0;
	height: 0;
	border-right: 8rem solid transparent;
	border-left: 8rem solid transparent;
	border-bottom: 5.8rem solid #ffab17;
	z-index: 0;
}

.mail__top.closed {
	transition: transform 0.6s 0.8s, z-index 0.2s 0.4s;
	z-index: 2;
	transition-delay: 0.5s;
	transform-origin: bottom left;
	transform: rotate3d(1, 0, 0, 180deg);
}

.mail__back {
	position: absolute;
	background: #ffab17;
	width: 16rem;
	height: 10rem;
	box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.3);
}

.mail__left {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 8rem solid #ffc867;
	border-top: 5rem solid transparent;
	border-bottom: 5rem solid transparent;
}

.mail__right {
	position: absolute;
	left: 8rem;
	width: 0;
	height: 0;
	border-right: 8rem solid #ffc867;
	border-top: 5rem solid transparent;
	border-bottom: 5rem solid transparent;
}

.mail__bottom {
	position: absolute;
	top: 4.92rem;
	width: 0;
	height: 0;
	border-right: 8rem solid transparent;
	border-left: 8rem solid transparent;
	border-bottom: 5.08rem solid #ffbb43;
}

.mail__letter {
	position: absolute;
	top: -4rem;
	left: 2rem;
	width: 12rem;
	height: 9rem;
	background: #fff;
	box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.15);
	overflow: hidden;
	transition: all 0.8s ease;
}

.mail__letter.move {
	transform: translateY(75px);
}

.mail__letter * {
	background: #e0e0e0;
}

.mail__letter-square {
	position: absolute;
	top: 3rem;
	left: 1rem;
	width: 3.8rem;
	height: 4rem;
}

.mail__letter-square::before {
	content: "";
	position: absolute;
	top: -2rem;
	width: 10rem;
	height: 1.5rem;
	background: inherit;
}

.mail__letter-lines {
	top: 4.9rem;
	left: 5.8rem;
}

.mail__letter-lines,
.mail__letter-lines::before,
.mail__letter-lines::after {
	content: "";
	position: absolute;
	width: 5rem;
	height: 0.3rem;
	background: #e0e0e0;
}

.mail__letter-lines::before {
	top: -1rem;
}

.mail__letter-lines::after {
	top: 1rem;
}

.cf-head h2 {
	font-size: 26px;
	font-weight: 600;
	margin-bottom: 15px;
}

.cf-head p {
	font-size: 16px;
	font-weight: 500;
	padding: 0;
	margin: 0;
}

.cf-head p:last-child {
	margin-top: 10px;
	font-size: 14px;
}

.cf-required-star {
	color: red;
}

.contact-form .btn-close {
	transition: var(--transition-btns);
	position: absolute;
	top: 9px;
	right: 10px;
	padding: 15px;
	display: block;
	background-size: 20px;
	z-index: 2
}

.form-cf-header-item.form__4 {
	box-shadow: none;
	padding: 0;
/* 	position: absolute;
	bottom: 66%; */
}

.form-cf-header-item.form__4 span {
	font-size: 12px;
}

.form-cf-header-item.form__4 svg {
	width: 19px;
	height: 19px;
	color: #fff;
}

.form-cf-header-item.form__4 .checkbox {
	width: 15px;
	height: 15px;
}


@media (max-width: 992px) {
	.form-cf-header-item {
		margin: 10px 15px;
	}

	button.form__btn.mail-success {
		transform: translateX(0);
	}

	.container-mail {
		grid-column: auto;
		grid-row: auto;
		height: 18rem;
	}

	.container-mail.mail-success {
		display: flex;
		transform: translate(18%, 10%);
	}

}

@media (max-width: 768px) {
	.cf-head h2 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.contact-form .btn-close {
		top: 2px;
		right: 2px;
		background-size: 16px;
	}

	.cf-head p:last-child {
		margin-top: 0px;
		font-size: 12px;
		line-height: 18px;
	}

	.cf-head {
		padding: 25px 20px;
		margin-bottom: 10px;
	}

	.form-cf-header-item {
		box-shadow: var(--shadow-minimal);
	}

	.form__label .form__label.footer {
		font-size: 14px;
		left: 1.2rem;
		top: 0.73rem;
	}

	.form__input {
		font-size: 14px;
		padding: 1rem 0;
	}

	textarea.form__input {
		height: 150px !important;
		min-height: 150px !important;
	}

	.container-cf-header,
	.header-cf-callback .modal-content {
		border-radius: var(--br-xl) !important
	}

	.cf-head {
		border-top-left-radius: var(--br-xl);
		border-top-right-radius: var(--br-xl);
	}

	.form__btn {
		margin: 35px 15px;
		margin-top: 10px;
	}

	.container-mail {
		width: calc(100% - 61px);
		margin-bottom: 20px;
	}

	.container-mail.mail-success {
		transform: translateX(0%) !important;
	}

	.form-cf-header-item.form__3 {
		margin-bottom: 30px;
	}

/* 	.form-cf-header-item.form__4 {
		bottom: 75%;
	} */
}

@media (max-width: 480px) {
	.form-cf-header-item.form__4 span {
		font-size: 10px;
	}
}

@media (min-width: 768px) {
	.modal.contact-form .modal-dialog {
		max-width: fit-content;
	}

	.form-cf-header-item.form__4 span {
		font-size: 11px;
	}
}




/*

offcanvas / canvas cart

*/

.cart-canvas-product-options {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-top: 5px;
}

.cart-canvas-product-options>* {
	display: block;
	font-size: 13px;
	line-height: 18px;
}



#header_cart_canvas,
#header_favourites_canvas {
	width: 550px;
}

.cart-canvas-item,
.favourites-canvas-item {
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
	padding: 12px;
	margin-bottom: 30px;
	transition: var(--transition-btns);
}

.cart-canvas-item img,
.favourites-canvas-item img {
	width: 185px;
	height: 120px;
	object-fit: contain;
	padding: 25px;
	border-radius: var(--br-xl);
	box-shadow: var(--shadow-block);
}

.cart-canvas-item-text-wr,
.favourites-canvas-item-text-wr {
	width: 60%;
	margin-bottom: auto;
	margin-top: 7px;
}

.cart-canvas-item-title-wr h4,
.favourites-canvas-item-title-wr h4 {
	font-size: 18px;
	font-weight: 600;
	transition: var(--transition-btns);
	line-height: normal;
}

.cart-canvas-item-title-wr h4:hover,
.favourites-canvas-item-title-wr h4:hover {
	color: var(--pink)
}

.cart-canvas-item-title-wr .icon-delete,
.favourites-canvas-item-title-wr .icon-delete {
	margin-left: auto;
	margin-top: -12px;
	margin-right: 5px;
	font-size: 24px;
	display: block;
	cursor: pointer;
	transition: var(--transition-btns);
}

.cart-canvas-item-title-wr .icon-delete:hover,
.favourites-canvas-item-title-wr .icon-delete:hover {
	color: var(--pink)
}

.cart-canvas-item-end-wr {
	margin-top: 10px;
}

.cart-canvas-item.cart-add-item {
	transform: scale(1.06);
}

.cart-canvas-empty,
.favourites-canvas-empty {
	font-size: 20px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-canvas-bottom-wr,
.favourites-canvas-bottom-wr {
	padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
	border: 1px solid rgba(0, 0, 0, 0.04);
	box-shadow: 0 1px 35px hsla(0, 0%, 25%, 0.2);
	background-color: #fff;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	right: 0;
}

.cart-canvas-total-text,
.favourites-canvas-total-text {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 10px;
}

.cart-canvas-total-price,
.favourites-canvas-total-price {
	font-size: 18px;
	font-weight: 600;
}

.cart-canvas-checkout-btn.btn,
.favourites-canvas-checkout-btn.btn {
	color: #fff;
	padding: 10px 20px;
	border-radius: var(--br-lg)
}

#header_cart_canvas .offcanvas-body {
	width: 100%;
	height: 100%;
	padding-bottom: 130px;
}

.cart-canvas-product-option-cloth button {
	background: none;
	box-shadow: none;
	border: none;
	padding: 0 !important;
	margin: 0;
	width: 30px !important;
	height: 30px !important;
	border-radius: 100%;
}

.cart-canvas-product-option-cloth-img *,
.cart-canvas-product-option-cloth-img {
	border-radius: var(--br-lg);
	border: none;
}

.cart-canvas-product-option-cloth-img img {
	width: 130px;
	height: 100px;
	object-fit: cover;
}

.cart-canvas-product-option-cloth-img .popover-body {
	padding: 0;
	margin: 0;
}

.cart-canvas-product-option-cloth-img .popover-arrow {
	display: none;
}

/*quantity cart*/
.quantity {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
}

.quantity__minus,
.quantity__plus {
	display: block;
	width: 22px;
	height: 23px;
	margin: 0;
	background: var(--light-grey);
	-webkit-text-decoration: none;
	text-decoration: none;
	text-align: center;
	line-height: 23px;
	transition: var(--transition-btns);
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	color: var(--user-color)
}

.quantity__minus {
	border-radius: 7px 0 0 7px;
}

.quantity__plus {
	border-radius: 0 7px 7px 0;
}

.quantity__input {
	width: 32px;
	height: 23px;
	margin: 0;
	padding: 0;
	text-align: center;
	border-top: 2px solid var(--light-grey);
	border-bottom: 2px solid var(--light-grey);
	border-left: 1px solid var(--light-grey);
	border-right: 2px solid var(--light-grey);
	background: #fff;
	color: var(--user-color);
}

.quantity__minus:link,
.quantity__plus:link {
	color: #8184a1;
}

.quantity__minus:visited,
.quantity__plus:visited {
	color: var(--user-color);
}

.quantity__minus:hover,
.quantity__plus:hover {
	background: var(--pink);
	color: #fff;
}

.quantity__input:focus,
.quantity__input:focus-visible,
.quantity__input:focus-within {
	outline-color: var(--light-grey);
	border: var(--light-grey);
	color: var(--user-color);
	margin: 0;
	padding: 0;
}

.cart-canvas-item-text-wr>p,
.favourites-canvas-item-text-wr>p {
	margin: 0;
	padding: 0;
	margin-bottom: 2px;
	font-size: 14px;
	font-weight: 500;
	color: var(--dark-grey);
	margin-top: -4px;
}

.cart-canvas-item-price,
.favourites-canvas-item-price {
	font-size: 17px;
	font-weight: 500;
}

.cart-canvas-item-price.cart-canvas-item-price-old {
	color: var(--dark-grey);
	font-size: 14px;
	font-weight: 400;
	margin-top: 5px;
	text-decoration: line-through;
}

@media (max-width : 576px) {
	#header_cart_canvas {
		width: 100%;
		padding-bottom: 15vh;
	}

	#header_cart_canvas .offcanvas-body {
		width: 100%;
		height: 100%;
		padding: 7px;
	}

}

@media (max-width : 480px) {

	.cart-canvas-item-title-wr h4,
	.favourites-canvas-item-title-wr h4 {
		font-size: 16px;
	}

	.cart-canvas-item-text-wr>p,
	.favourites-canvas-item-text-wr>p {
		margin-bottom: 1px;
		font-size: 14px;
	}

	.cart-canvas-item {
		position: relative;
		height: max-content;
		padding-bottom: 50px;
	}

	.cart-canvas-item-end-wr {
		margin-top: 20px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0px 13px;
		padding-bottom: 12px;
	}

	.cart-canvas-item>a {
		width: 40%;
		margin-bottom: auto;
	}

	.cart-canvas-item img {
		width: 100%;
		padding: 15px;
		height: 115px;
	}

	.cart-canvas-item-text-wr,
	.favourites-canvas-item-text-wr {
		width: 55%;
		margin-bottom: auto;
		margin-top: 7px;
	}

	.cart-canvas-bottom-wr {
		padding: 7px 10px;
	}

	.cart-canvas-total-text {
		font-size: 14px;
		margin-bottom: 4px;
	}

	.cart-canvas-total-price {
		font-size: 16px;
	}
}



/*
***********
MAIN PAGE
***********
*/

:root {
	--home-title-color-line: #e5e5e5;
	--grey-text: #2c3638;
}

.home-title {
	position: relative;
	margin: 0;
	font-size: 34px;
	font-weight: 600;
	line-height: 44px;
}

.home-title span {
	color: var(--pink);
}

.home-title.page-title {
	font-size: 38px;
	margin-bottom: 30px;
}

.home-title.page-subtitle {
	font-size: 26px;
	line-height: normal;
	margin-bottom: 15px;
}

.home-subtitle {
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
}

.home-title-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 35px;
}

a.home-title-link {
	font-size: 18px;
	line-height: 23px;
	transition: var(--transition-btns);
	font-weight: 500;
}

a.home-title-link:hover {
	color: var(--pink)
}

@media (max-width: 1500px) {
	.compare-product-card-slide .product-card-price.sale-red-price {
		font-size: 18px;
	}

	.compare-product-card-slide .product-card-price-old {
		font-size: 12px;
	}
}

@media (max-width: 992px) {
	.home-title {
		font-size: 26px;
		line-height: 38px;
	}

	a.home-title-link {
		font-size: 16px;
	}

	.home-title.page-title {
		font-size: 28px;
		margin-bottom: 30px;
	}
}

@media (max-width: 768px) {
	a.home-title-link {
		display: none
	}

	.home-title-wrapper {
		margin-bottom: 22px;
	}
}

@media (max-width: 576px) {
	.home-title {
		font-size: 20px;
		line-height: 28px;
	}

	.home-title.page-title {
		font-size: 24px;
	}
}

/*
HOME SLIDER
*/

:root {
	--home-slider-height: 500px;
}

.swiper.homeSlider {
	width: 100%;
	height: var(--home-slider-height);
	z-index: 0;
	max-width: 1920px;
}

.homeSlider .swiper-slide>a {
	width: inherit;
	height: inherit;
	overflow: hidden;
}

@media (min-width: 1922px) {
	.homeSlider .swiper-slide>a {
		border-radius: var(--br-xxl);
	}
}

.swiper-pagination.home-slider-pag {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	bottom: 30px;
	left: 25px;
	opacity: 0.3;
	transition: all .7s ease;
}

.swiper-pagination.home-slider-pag .swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	border-radius: 100%;
	opacity: 0.7;
	background: var(--white);
	transition: all .5s ease
}

.swiper-pagination.home-slider-pag .swiper-pagination-bullet-active {
	width: 30px;
	border-radius: 50px;
	background: var(--white);
	opacity: 1;
}

.swiper-pagination.home-slider-pag-mobile {
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 1;
	transition: all 0.7s ease;
	position: static;
	margin-top: 10px;
}

.swiper-pagination.home-slider-pag-mobile .swiper-pagination-bullet {
	width: 7px;
	height: 7px;
	border-radius: 100%;
	opacity: 1;
	background: var(--light-grey);
	transition: all 0.5s ease;
}

.swiper-pagination.home-slider-pag-mobile .swiper-pagination-bullet-active {
	width: 33px;
	border-radius: 50px;
	background: var(--dark-grey);
	opacity: 1;
}

.homeSlider .swiper-slide {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.homeSlider .swiper-slide .home-slider-item {
	display: block;
	width: 100%;
	height: var(--home-slider-height);
	object-fit: cover;
}

.home-slider-arrow {
	color: var(--user-color);
	background: var(--white);
	padding: 30px;
	border-radius: 100%;
	opacity: 0.3;
	-webkit-transition: all .7s ease;
	-o-transition: all .7s ease;
	transition: all .7s ease;
	top: calc(100% - 16rem);
}

.home-slider-arrow::after {
	font-size: 22px;
	font-weight: 600;
	padding-right: 1px;
}

.swiper.homeSlider:hover .home-slider-arrow,
.swiper.homeSlider:hover .swiper-pagination.home-slider-pag,
.swiper-pagination.home-slider-pag-mobile {
	opacity: 1;
}

.homeSlider .container {
	position: relative
}

@media (max-width : 992px) {

	.swiper.homeSlider,
	.homeSlider .swiper-slide .home-slider-item {
		height: 50vw;
	}

	.home-slider-arrow {
		top: calc(100% - 16vw);
	}
}


@media (min-width : 992px) {

	.home-slider-btn-next-mobile,
	.home-slider-btn-prev-mobile,
	.swiper-pagination.home-slider-pag-mobile {
		display: none;
	}
}

@media (min-width : 2000px) {
	.homeSlider .swiper-slide .home-slider-item {
		border-radius: var(--br-xxl);
	}

	.homeSlider .swiper-slide {
		opacity: 0;
		transition: var(--transition-btns);
	}

	.homeSlider .swiper-slide.swiper-slide-active {
		opacity: 1;
	}
}

/*
 POPULAR CATEGORIES/CATEGORY
*/

/*
home popular category
*/

.popular-category-item h2 {
	position: absolute;
	bottom: 10px;
	right: 15px;
	text-align: center;
	font-weight: 500;
	z-index: 2;
	font-size: 18px;
	background: rgba(255, 255, 255, 0.7);
	padding: 12px 15px;
	border-radius: var(--br-lg);
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.popular-category-item::after {
	content: "";
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.05);
	z-index: 1;
	border-radius: inherit;
}

.popular-category-item {
	position: relative;
	display: block;
	transition: var(--transition-images);
	overflow: hidden;
	box-shadow: var(--shadow-block);
	width: 100%;
	height: 100%;
}

/* desktop */
@media (min-width : 992px) {

	#home_categories {
		position: relative;
	}

	.popularCategory {
		overflow: visible
	}

	.popularCategory .swiper-wrapper {
		display: grid;
		grid-template-columns: repeat(2, 1.8fr 1.4fr);
		grid-template-rows: 1.13fr 1.2fr 1fr;
		grid-gap: 30px 30px;
		gap: 30px 30px;
		grid-auto-flow: row dense;
		align-items: stretch;
		grid-template-areas: "left-top center-top-left center-top-right right-top"
			"left-center center-right center-right right-top"
			"left-bottom left-bottom right-bottom right-bottom";
		height: 825px;
	}

	.category-center-top-left {
		grid-area: center-top-left;
	}

	.category-center-top-right {
		grid-area: center-top-right;
	}

	.category-right-top {
		grid-area: right-top;
	}

	.category-left-bottom {
		grid-area: left-bottom;
	}

	.category-left-center {
		grid-area: left-center;
	}

	.category-center-left {
		grid-area: center-left;
	}

	.category-center-right {
		grid-area: center-right;
	}

	.category-right-bottom {
		grid-area: right-bottom;
	}

	.category-left-top {
		grid-area: left-top;
	}



	.popular-category-item img {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		will-change: transform;
		transform: scale(1.1);
		transition: var(--transition-images);
		border-radius: var(--br-xxl);
	}

	.popular-category-item,
	.popular-category-item img {
		border-radius: var(--br-xxl);
	}



	.popular-category-item:hover>img {
		transform: scale(1);
	}

	.btn-home-categories {
		margin: 0 auto;
		margin-top: 50px;
	}

	.btn-home-categories:hover,
	.btn-home-categories:active,
	.btn-home-categories:focus-visible {
		background: var(--pink) !important;
	}

}

@media (max-width : 1400px) {
	.popular-category-item h2 {
		font-size: 16px;
	}

}

@media (max-width : 1200px) {
	.popular-category-item h2 {
		position: absolute;
		bottom: 8px;
		right: 11px;
		font-size: 14px;
		padding: 12px 9px;
	}

	.popularCategory .swiper-wrapper {
		height: 625px;
	}
}

/* mobile */
@media (max-width : 992px) {
	#home_categories .home-title-wrapper {
		margin-bottom: 0;
	}

	.popularCategory.swiper {
		width: 100%;
		height: 100%;
		padding: 22px 0;
	}

	.container.popular-category-container {
		max-width: 100%;
		padding: 0;
	}

	.popularCategory .swiper-slide {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	}

	.popularCategory .swiper-slide img {
		display: block;
		width: 100%;
		height: inherit;
		object-fit: cover;
		border-radius: var(--br-xl);
	}

	.popularCategory .popular-category-item::after {
		height: inherit;
	}

	.popularCategory .swiper-slide {
		width: 80%;
		height: initial;
	}

	.popularCategory .popular-category-item {
		height: 350px;
		box-shadow: var(--shadow-minimal);
		border-radius: var(--br-xl);
	}

	.swiper-scrollbar.popular-category-slider-scrollbar {
		position: static;
	}

	.popularCategory .swiper-wrapper {
		height: 100%;
	}

	.popular-category-item h2 {
		bottom: 15px;
		right: 20px;
		font-size: 20px;
		padding: 12px 15px;
	}

}

@media (max-width : 768px) {
	.popularCategory .popular-category-item {
		height: 260px;
	}
}

@media (max-width : 576px) {
	.popularCategory .popular-category-item {
		height: 175px;
	}

	.popular-category-item h2 {
		position: absolute;
		bottom: 8px;
		right: 11px;
		font-size: 14px;
		padding: 12px 9px;
	}
}


/*

Week selection

*/

.swiper.weekSelectionSlider {
	height: 800px;
	position: relative;
	/* max-width: 1920px; */
}

.week-selection-wrapper img {
	border-radius: var(--br-xxl);
	width: 400px !important;
	height: 350px;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
	-webkit-box-shadow: var(--shadow-block);
	box-shadow: var(--shadow-block);
	z-index: 2;
}

.weekSelectionSlider .swiper-slide {
	-webkit-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}

.weekSelectionSlider .week-selection-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}


.weekSelectionSlider .swiper-slide.swiper-slide-prev img,
.weekSelectionSlider .swiper-slide.swiper-slide-duplicate-prev img {
	transform: translate(calc(100% - 41rem), 19rem);
	opacity: 1
}

.weekSelectionSlider .swiper-slide.swiper-slide-next img,
.weekSelectionSlider .swiper-slide.swiper-slide-duplicate-next img {
	transform: translate(calc(100% - 1rem), 2rem);
	opacity: 1
}

.swiper-slide.week-selection-slide img {
	opacity: 0;
}

.weekSelectionSlider .swiper-slide.swiper-slide-active .week-selection-wrapper,
.weekSelectionSlider .swiper-slide-duplicate-active .week-selection-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.weekSelectionSlider .swiper-slide.swiper-slide-active img {
	opacity: 1;
	width: 100%;
	max-width: 27vw;
	min-width: 450px;
	height: 600px;
	-webkit-transform: translateX(12rem);
	-ms-transform: translateX(12rem);
	transform: translateX(12rem);
}

.week-selection-text-wrapper {
	opacity: 0;
	position: absolute;
	left: -18rem;
	width: 450px;
	-webkit-transition: all 1s ease-out;
	-o-transition: all 1s ease-out;
	transition: all 1s ease-out;
	z-index: 1;
	top: 9rem;
}

.weekSelectionSlider .swiper-slide.swiper-slide-active .week-selection-text-wrapper {
	opacity: 1;
}

.week-selection-text-wrapper p {
	margin: 15px 0;
	line-height: 28px;
	margin: 0;
	margin-bottom: 25px;
}

.swiper-scrollbar.week-selection-scrollbar {
	bottom: 4rem;
	width: 50%;
	left: 0;
	right: 17rem;
	margin: 0 auto;
}

h3.week-selection-title {
	font-weight: 600;
	font-size: 26px;
	line-height: 32px;
	margin: 0;
	margin-bottom: 20px;
}

.week-selection-arrows-wr {
	position: relative;
	margin-top: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	gap: 50px;
}

.week-selection-arrow::before {
	font-size: 35px;
	font-weight: 400;
	color: var(--user-color);
}

.swiper-button-next.week-selection-arrow::before {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.swiper-button-prev.week-selection-arrow::after,
.swiper-button-next.week-selection-arrow::after {
	display: none
}

.week-selection-arrow {
	position: static;
	padding: 0;
	margin: 0;
	transition: var(--transition-btns);
}

.swiper-button-next.week-selection-arrow:hover {
	transform: translateX(10px);
}

.swiper-button-prev.week-selection-arrow:hover {
	transform: translateX(-10px);
}

a.btn.btn-default.btn-week-selection {
	margin-top: 30px;
	border-color: #fff;
	background: #fff;
}

.week-selection-wrapper .btn.btn-default.btn-brown {
	color: var(--bs-white);
	font-weight: 400;
	height: 45px;
}

.swiper.weekSelectionSlider .swiper-wrapper {
	margin-top: 30px;
}

section#week_selection {
	position: relative;
}

@media (max-width : 1400px) {
	.week-selection-text-wrapper {
		width: 32vw;
	}

	.weekSelectionSlider .swiper-slide.swiper-slide-prev img,
	.weekSelectionSlider .swiper-slide.swiper-slide-duplicate-prev img {
		transform: translate(calc(100% - 46rem), 19rem);
	}

	.weekSelectionSlider .swiper-slide.swiper-slide-next img,
	.weekSelectionSlider .swiper-slide.swiper-slide-duplicate-next img {
		transform: translate(calc(100% - 5rem), 2rem);
	}

	.swiper-scrollbar.week-selection-scrollbar {
		right: 5rem;
		width: 70%;
	}

}

@media (max-width : 1200px) {
	.weekSelectionSlider .swiper-slide .week-selection-wrapper {
		-webkit-box-pack: center !important;
		-ms-flex-pack: center !important;
		justify-content: center !important;
		padding: 0 25px !important;
		gap: 30px !important;
	}

	.week-selection-text-wrapper {
		width: 65% !important;
		position: static !important;
	}

	.weekSelectionSlider .week-selection-wrapper,
	.weekSelectionSlider .swiper-slide img,
	.week-selection-text-wrapper {
		-webkit-transform: translate(0, 0) !important;
		-ms-transform: translate(0, 0) !important;
		transform: translate(0, 0) !important;
		-webkit-transition: none !important;
		-o-transition: none !important;
		transition: none !important;
		opacity: 1 !important;
		position: static !important
	}

	.weekSelectionSlider .swiper-slide img {
		width: 100% !important;
		max-width: 100% !important;
		min-width: 35% !important;
		height: 500px !important;
	}

	.weekSelectionSlider .swiper-slide {
		opacity: 0;
	}

	.swiper-slide.week-selection-slide.swiper-slide-active {
		opacity: 1;
	}

	.swiper-scrollbar.week-selection-scrollbar {
		position: static;
		width: 100%;
		margin-top: 40px;
	}

	h3.week-selection-title {
		font-size: 28px;
		line-height: 40px;
		margin-bottom: 20px;
	}

	.week-selection-arrows-wr {
		margin-top: 40px;
	}

	section#home_services {
		margin-top: 100px;
	}

	.swiper.weekSelectionSlider {
		height: auto;
	}

	section#home_services::after {
		display: none;
	}

	.swiper.weekSelectionSlider .swiper-wrapper {
		margin-top: 30px;
	}

	#week_selection.mt-sc-lg {
		margin-top: 90px;
	}
}


@media (max-width : 992px) {
	.weekSelectionSlider .swiper-slide img {
		height: 350px !important;
	}

	.week-selection-text-wrapper {
		width: 90% !important;
	}
}

@media (max-width : 768px) {
	.week-selection-text-wrapper {
		display: inline-block;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
		width: 100% !important;
	}

	.weekSelectionSlider .swiper-slide img {
		height: 400px !important;
		min-width: 100% !important;
		object-position: 50% 62%;
	}

	.weekSelectionSlider .swiper-slide .week-selection-wrapper {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.week-selection-wrapper img {
		border-radius: var(--br-xl);
	}

	h3.week-selection-title {
		font-size: 22px;
	}

	.week-selection-arrows-wr {
		display: none
	}
}

@media (max-width : 576px) {
	.weekSelectionSlider .swiper-slide img {
		height: 300px !important;
	}

	.week-selection-text-wrapper p {
		font-size: 16px;
		line-height: 27px;
	}

	.weekSelectionSlider .swiper-slide .week-selection-wrapper {
		padding: 0 15px !important;
	}

	h3.week-selection-title {
		font-size: 18px;
	}
}

@media (max-width : 480px) {
	.weekSelectionSlider .swiper-slide img {
		height: 200px !important;
	}

	a.btn.btn-default.btn-grey.btn-week-selection.btn-brown {
		width: 100%;
	}

	img.home-about-img {
		margin: 10px 0;
	}

	a.btn.btn-default.btn-week-selection {
		width: 100%;
	}
}



/*

product card styles

*/

:root {
	--product-card-img-height: 200px;
}

.swiper-slide.product-card-slide {
	padding: 17px 5px;
}

.product-card-wrapper {
	border-radius: var(--br-xl);
	padding: 20px;
	box-shadow: var(--shadow-mini-block);
	height: 100%;
}

.product-card-img-wrapper {
	width: 100%;
	height: var(--product-card-img-height);
	display: block;
	position: relative;
	margin-bottom: 10px;
}

.product-card-main-img {
	width: 100%;
	height: var(--product-card-img-height);
	object-fit: cover;
	border-radius: var(--br-xl);
}

.btn-product-card span,
.btn-product-card svg {
	font-size: 20px;
	display: block;
	opacity: 0.6;
	transition: var(--transition-btns);
}

.btn-product-card svg {
	width: 20px;
	height: 20px;
}

.btn-product-card svg * {
	transition: var(--transition-btns);
}

.btn-product-card {
	position: absolute;
	right: 12%;
	top: 9%;
	border: none;
	padding: 0;
	z-index: 5;
	background: var(--white);
	border-radius: 100%;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.favorite-btn-product-card.favorite-add.btn-product-card {
	right: 75px;
}

.compare-btn-product-card.compare-add.btn-product-card {
	right: 30px;
}

.product-card-name {
	font-size: 24px;
	font-weight: 600;
	margin: 0;
	margin-top: 10px;
	display: inline-block;
	transition: var(--transition-btns);
}

.product-card-name:hover {
	color: var(--pink)
}

.product-card-subname {
	font-size: 12px;
	font-weight: 400;
}

.product-card-bottom-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: start;
	margin-top: 12px;
	flex-direction: column;
}

.product-card-price-discount {
	font-size: 14px;
	margin-top: 4px;
}

.product-card-price-discount span {
	color: var(--red);
	font-weight: 500;
	font-size: 16px;
}

.product-card-price-wrapper {
	display: flex;
	align-items: center;
}

.product-card-price.sale-red-price {
	font-size: 22px;
	font-weight: 500;
	margin-right: 7px;
	font-family: "Roboto+Condensed", sans-serif;
	font-weight: 700;
}

.product-card-price-old {
	color: rgba(0, 0, 0, 0.4196078431);
	text-decoration: line-through;
	font-size: 18px;
	font-weight: 500;
	font-family: 'Roboto+Condensed', sans-serif;
	font-weight: 600;
}

.product-card-btn-add {
	width: 100%;
	margin-top: 18px;
	font-weight: 400;
	color: #ffffff;
	height: 45px;
}

.product-card-btn-add:hover {
	color: var(--user-color);
}



.product-card-hover-wr.pch-item {
	position: absolute;
	height: var(--product-card-img-height);
	top: 0;
	z-index: 2;
}

.product-card-hover-wr.pch-img {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.product-card-hover-wr.pch-item::before {
	content: "";
	position: absolute;
	bottom: -11px;
	width: 100%;
	height: 2px;
	background: var(--dark-grey);
	opacity: 0;
}

.product-card-hover-wr.pch-item:hover::before {
	opacity: 1 !important;
}


.product-card-sale {
	background: var(--brigth-red);
	font-size: 14px;
	display: block;
	padding: 4px 2px 4px 4px;
	width: 42px;
	height: 42px;
	line-height: 1;
	border-radius: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-card-reviews-stars span {
	font-size: 22px;
	color: var(--orange);
}

.product-card-reviews-wr {
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 30px;
	width: max-content;
	margin-top: 5px;
	border-radius: var(--br-sm);
	background: #0000;
	transition: all .5s ease-in-out;
}

a.product-card-reviews-wr:hover {
	text-decoration: underline;
}

span.product-card-reviews-total {
	font-size: 14px;
	margin-left: 5px;
	margin-top: 3px;
	color: var(--dark-grey);
}

.pc-img-item {
	width: 100%;
	height: var(--product-card-img-height);
	position: absolute;
	top: 0;
}

.product-card-title-wr {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.product-card-title-wr>* {
	margin-bottom: 0 !important
}

.product-card-img-wrapper:hover~button.btn-product-card span,
.product-card-img-wrapper:hover~button.btn-product-card svg {
	opacity: 1;
}

button.compare-btn-product-card.compare-add.btn-product-card span {
	padding-bottom: 2px;
	color: var(--user-color)
}

.btn-product-card:hover>span {
	opacity: 1;
}

.swiper.homeProductCardSlider {
	padding: 10px 5px;
}

/* #home_bestsellers .product-card-title-wr {
	margin-bottom: 0;
} */

.no-slides .product-card-hover-wr.pch-item::before {
	display: none
}



@media (max-width : 1400px) {
	:root {
		--product-card-img-height: 195px;
	}
}

@media (max-width : 992px) {
	:root {
		--product-card-img-height: 32vw;
	}

	.product-card-hover-wr.pch-item::before {
		opacity: 0.3;
	}

	.pc-img-item {
		display: none;
	}

	.pc-img-item.active {
		display: block;
	}

	a.product-card-img-wrapper::after {
		content: "";
		position: absolute;
		bottom: -11px;
		width: 100%;
		height: 2px;
		background: var(--dark-grey);
		opacity: 0.3;
	}

	.pc-img-item.no-slides~a.product-card-img-wrapper::after {
		display: none
	}

	.product-card-hover-wr.pch-item::before {
		width: 75%;
	}

	.compare-btn-product-card span {
		opacity: 1;
	}

	#home_bestsellers .container.container-product-card {
		max-width: 100%
	}

	.swiper-slide.product-card-slide {
		width: 350px;
	}

	:root {
		--product-card-img-height: 27vw;
	}

	.product-card-wrapper {
		box-shadow: var(--shadow-minimal);
	}
}

@media (max-width : 768px) {
	.product-card-wrapper {
		border-radius: var(--br-xl);
	}
}

@media (max-width : 576px) {
	:root {
		--product-card-img-height: 34vw;
	}
}

@media (max-width : 480px) {
	:root {
		--product-card-img-height: 48vw;
	}

	.swiper-slide.product-card-slide {
		width: 300px;
	}

	button.btn-product-card {
		top: 10%;
	}

	.product-card-name {
		font-size: 20px;
		margin-top: 12px;
	}

	.product-card-subname {
		font-size: 12px;
		margin-top: 0px;
	}

	.product-card-reviews-stars span {
		font-size: 20px;
	}

	.product-card-sale {
		font-size: 12px;
		padding: 1px 0 0 2px;
		width: 33px;
		height: 33px;
	}

	.product-card-price-old {
		font-size: 14px;
	}

	.product-card-bottom-wrapper {
		margin-top: 7px;
	}

	.product-card-btn-add {
		margin-top: 15px;
		height: 40px;
		font-size: 14px;
	}

}

@media (min-width : 992px) {
	.product-card-hover-wr.pch-img {
		position: absolute;
		top: 0;
		z-index: 1;
		display: none;
	}

	.product-card-img-wrapper>div:first-child>div:last-child {
		display: block;
	}

	.product-card-hover-wr.pch-item:hover+.product-card-hover-wr.pch-img {
		display: block;
	}

	.product-card-img-wrapper:hover .product-card-hover-wr.pch-item::before {
		opacity: 0.3;
	}

}

/* 

HOME NEWS SLIDER

 */

#home_news .product-card-title-wr {
	margin-bottom: 0;
}

.swiper.homeNewsSlider .home-news-item {
	position: relative;
	height: inherit;
	display: block;
}

.swiper.homeNewsSlider .swiper-slide img {
	width: 100%;
	height: inherit;
	object-fit: cover;
	position: absolute;
	top: 0;
	z-index: 1;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
}

.swiper.homeNewsSlider {
	padding: 30px 0;
}

.home-news-items-wr {
	position: absolute;
	bottom: 10px;
	left: 15px;
	z-index: 5;
	color: #fff;
	height: 100px;
}

.swiper.homeNewsSlider .home-news-item::after {
	content: "";
	position: absolute;
	top: 0;
	background: rgb(0 0 0 / 25%);
	width: 100%;
	height: inherit;
	z-index: 2;
	border-radius: var(--br-xxl);
}

.home-news-items-wr h3 {
	margin: 0;
	font-weight: 500;
	font-size: 18px;
	margin-top: 5px;
	line-height: 24px;
}

.home-news-item-date>span {
	font-size: 14px;
}

.homeNewsSlider .swiper-slide {
	height: 315px;
}

@media (max-width: 1400px) {
	.homeNewsSlider .swiper-slide {
		height: 260px;
	}
}

@media (max-width: 992px) {
	.home-news-items-wr h3 {
		font-size: 16px;
		line-height: 21px;
	}
}

@media (max-width: 768px) {
	.homeNewsSlider .swiper-slide {
		height: 300px;
	}

	.swiper.homeNewsSlider .swiper-slide img {
		border-radius: var(--br-xl);
	}

	.swiper.homeNewsSlider .home-news-item::after {
		border-radius: var(--br-xl);
	}
}

@media (max-width: 480px) {
	.swiper.homeNewsSlider .swiper-slide img {
		box-shadow: var(--shadow-mini-block);
	}

	.homeNewsSlider .swiper-slide {
		height: 230px;
	}

	.home-news-items-wr h3 {
		font-size: 14px;
		line-height: 20px;
		margin-top: 0;
	}

	.home-news-item-date>span {
		font-size: 13px;
	}
}

/*

POPULAR INTERIORS

*/

.swiper-slide.popular-interioirs-item img {
	width: 100%;
	border-radius: var(--br-xxl);
	height: 550px;
	object-fit: cover;
	box-shadow: var(--shadow-block);
}

section#popular_interiors .product-card-title-wr {
	justify-content: space-between;
	margin-bottom: 15px;
}

div#nav-tab_popularInteriors {
	border: none;
	box-shadow: var(--shadow-block);
	flex-wrap: nowrap;
	padding: 10px 12px;
	border-radius: var(--br-xxl);
	gap: 30px;
}

div#nav-tab_popularInteriors .nav-link {
	border: none;
	color: var(--user-color);
	font-size: 16px;
	font-weight: 500;
	background: #fff0;
	border-radius: var(--br-xl);
}

div#nav-tab_popularInteriors .nav-link.active {
	background: var(--white);
}

.swiper-slide.popular-interioirs-item {
	position: relative;
}

.home-interior-btn-wr {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.home-interior-btn {
	background: #fff;
	width: 25px;
	height: 25px;
	border: none;
	border-radius: 100%;
	transition: var(--transition-btns);
	border: 6px solid var(--user-color);
	position: absolute;
}

.home-interior-btn:hover {
	border: 6px solid var(--user-color);
}

.popover.home-interior-popover {
	border: none;
	z-index: 1;
	background: none;
	border-radius: var(--br-lg);
}

.popover.home-interior-popover::after {
	content: "";
	position: absolute;
	top: 0;
	background: #ffffffcf;
	width: 100%;
	height: 100%;
	z-index: 2;
	border-radius: var(--br-lg);
	backdrop-filter: blur(7px);
}

.popover.home-interior-popover .popover-body {
	position: relative;
	z-index: 3;
	color: var(--user-color);
	font-weight: 500;
}

.popover.home-interior-popover .popover-arrow {
	margin-bottom: 2px;
	opacity: 0.6;
}

.swiper-pagination.popular-interiors-pagination span.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background: var(--light-grey);
	opacity: 1;
	transition: var(--transition-btns);
}

.swiper-pagination.popular-interiors-pagination {
	position: static;
	margin-top: 30px;
}

.swiper-pagination.popular-interiors-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: var(--dark-grey);
	width: 30px;
	height: 12px;
	border-radius: var(--br-sm);
}

.swiper.popularInteriorsSlider {
	padding: 25px 0;
}

@media (max-width: 1400px) {
	.swiper-slide.popular-interioirs-item img {
		height: 450px;
	}
}

@media (max-width: 992px) {
	section#popular_interiors .product-card-title-wr {
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	section#popular_interiors .product-card-title-wr nav {
		margin-top: 25px;
	}
}

@media (max-width: 768px) {
	.swiper-slide.popular-interioirs-item img {
		height: 350px;
		border-radius: var(--br-xl);
	}

	.swiper-pagination.popular-interiors-pagination {
		margin-top: 20px;
	}

	.swiper-pagination.popular-interiors-pagination span.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
	}

	.swiper-pagination.popular-interiors-pagination span.swiper-pagination-bullet.swiper-pagination-bullet-active {
		height: 10px;
	}

	div#nav-tab_popularInteriors {
		padding: 7px 8px;
		gap: 10px;
	}
}

@media (max-width: 480px) {
	.swiper-slide.popular-interioirs-item img {
		height: 220px;
	}

	.home-interior-btn {
		width: 23px;
		height: 23px;
		padding: 7px;
	}

	div#nav-tab_popularInteriors {
		padding: 5px;
		gap: 5px;
	}

	div#nav-tab_popularInteriors .nav-link {
		font-size: 12px;
		padding: 5px 10px;
	}

	.swiper-pagination.popular-interiors-pagination {
		margin-top: 12px;
	}
}

/*
HOME ARTICLES
*/

:root {
	--articles-item-h: 770px
}

.swiper.articlesSlider {
	height: var(--articles-item-h);
	padding: 20px 15px;
}

.articles-slider-item img {
	width: 100%;
	height: 175px;
	object-fit: cover;
	display: block;
	border-radius: var(--br-xl);
}

.articles-slider-item p {
	font-size: 14px;
	line-height: 20px;
}

.articles-slider-item {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px
}

.articles-slider-item h6 {
	line-height: 22px;
}

.articles-main-block {
	align-self: flex-start;
	height: calc(var(--articles-item-h) - 3%);
	box-shadow: var(--shadow-block);
	padding: 30px;
	border-radius: var(--br-xxl);
	display: flex;
	flex-direction: column;
}

.articles-main-block img {
	height: 100%;
	display: block;
	object-fit: cover;
	border-radius: var(--br-xxl);
}

.articles-main-block h6 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	margin-top: 25px;
	margin-bottom: 12px;
}

.articles-main-block p {
	line-height: 23px;
}

.articles-main-block .btn.btn-default {
	width: 270px;
	height: 40px;
	color: var(--user-color);
	font-weight: 500;
	font-size: 14px;
}

.swiper.articlesSlider .swiper-slide {
	box-shadow: var(--shadow-mini-block);
	border-radius: var(--br-xxl);
	padding: 24px;
}

.articles-slider-item>div:first-child {
	width: 40%;
}

.articles-slider-item>div:last-child {
	width: 57%;
}

.swiper-button-prev.articles-arrow {
	background-image: url("data:image/svg+xml,%3Csvg width=%2716%27 height=%27111%27 viewBox=%270 0 16 111%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M9.03914 1.27068C8.64862 0.88015 8.01545 0.88015 7.62493 1.27068L1.26097 7.63464C0.870444 8.02516 0.870443 8.65832 1.26097 9.04885C1.65149 9.43938 2.28466 9.43938 2.67518 9.04885L8.33204 3.392L13.9889 9.04885C14.3794 9.43938 15.0126 9.43938 15.4031 9.04885C15.7936 8.65833 15.7936 8.02516 15.4031 7.63464L9.03914 1.27068ZM9.33203 110.269L9.33204 1.97778L7.33204 1.97778L7.33203 110.269L9.33203 110.269Z%27 fill=%27black%27/%3E%3C/svg%3E");
	background-repeat: no-repeat;
}

.swiper-button-next.articles-arrow {
	background-image: url("data:image/svg+xml,%3Csvg width=%2716%27 height=%27110%27 viewBox=%270 0 16 110%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M7.62492 109.151C8.01544 109.541 8.64861 109.541 9.03913 109.151L15.4031 102.787C15.7936 102.396 15.7936 101.763 15.4031 101.373C15.0126 100.982 14.3794 100.982 13.9889 101.373L8.33203 107.03L2.67517 101.373C2.28465 100.982 1.65148 100.982 1.26096 101.373C0.870435 101.763 0.870435 102.396 1.26096 102.787L7.62492 109.151ZM7.33203 0.152222L7.33203 108.444L9.33203 108.444L9.33203 0.152222L7.33203 0.152222Z%27 fill=%27black%27/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
}

.articles-arrow::after {
	display: none;
}

.articles-arrow {
	position: static;
	height: 110px;
	transition: var(--transition-btns);
}

.articles-arrows-wr {
	display: flex;
	flex-direction: column;
	gap: 55px;
}

.swiper-button-prev.articles-arrow:hover {
	transform: translateY(-15px);
}

.swiper-button-next.articles-arrow:hover {
	transform: translateY(15px);
}

.articles-slider-item a.btn.btn-default {
	color: var(--user-color);
	font-weight: 400;
	font-size: 14px;
}

.swiper-scrollbar.articles-scrollbar {
	position: static;
	margin: 0;
	width: 100%;
	margin-top: 20px;
}

section#home_articles .home-title-wrapper {
	margin-bottom: 10px;
}

@media (max-width: 1400px) {
	.articles-slider-item p {
		font-size: 12px;
		line-height: 16px;
	}

	.articles-slider-item h6 {
		font-size: 14px;
		line-height: 19px;
	}

	.articles-slider-item a.btn.btn-default {
		font-size: 12px;
	}

	.swiper.articlesSlider .swiper-slide {
		padding: 15px;
	}

	.articles-slider-item img {
		height: 185px;
	}
}

@media (max-width: 1200px) {
	.swiper.articlesSlider {
		height: 100%;
		padding: 20px 10px;
	}

	.swiper.articlesSlider .swiper-slide {
		padding: 25px;
	}

	.articles-slider-item {
		flex-direction: column;
	}

	.articles-slider-item>div {
		width: 100% !important
	}

	.articles-slider-item h6 {
		font-size: 18px;
		line-height: 28px;
	}

	.articles-slider-item p {
		font-size: 16px;
		line-height: 24px;
	}

	.articles-slider-item a.btn.btn-default {
		height: 40px;
		font-size: 14px;
	}

	.articles-slider-item img {
		height: 230px;
	}
}

@media (max-width: 992px) {
	.articles-slider-item img {
		height: 300px;
	}
}

@media (max-width: 768px) {
	.swiper.articlesSlider .swiper-slide {
		border-radius: var(--br-xl);
	}

	.swiper.articlesSlider .swiper-slide {
		padding: 20px;
	}

	.articles-slider-item img {
		height: 250px;
		border-radius: var(--br-xl);
	}
}

@media (max-width: 480px) {
	.articles-slider-item img {
		height: 160px;
	}

	.articles-slider-item h6 {
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 10px;
	}

	.articles-slider-item p {
		font-size: 12px;
		line-height: 20px;
	}

	.articles-slider-item a.btn.btn-default {
		height: 35px;
		font-size: 12px;
	}
}


/*

HOME ABOUT

*/

section#home_about .home-title {
	text-align: center;
}

section#home_about .home-title>span:nth-child(2) {
	color: var(--green);
}

section#home_about .home-title>span:nth-child(3) {
	color: var(--brown);
}

section#home_about .home-title>span:nth-child(4) {
	color: var(--white);
}

section#home_about .home-title {
	text-align: center;
	margin-bottom: 35px;
}

video#home_video {
	width: 100%;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	position: relative;
	object-fit: cover;
	height: 400px;
}

:root {
	--about-video-btn-size: 70px
}

.home-video-wrapper {
	position: relative;
}

.home-video-btn {
	align-items: center;
	display: flex;
	justify-content: center;
	position: absolute;
	bottom: -16%;
	left: 0;
	right: 0;
	z-index: 2;
}

.fondo {
	background: var(--pink);
	border-radius: 50%;
	box-shadow: 0 1px 2.2px rgba(0, 0, 0, 0.051),
		0 2.3px 5.3px rgba(0, 0, 0, 0.059), 0 4.4px 10px rgba(0, 0, 0, 0.06),
		0 7.8px 17.9px rgba(0, 0, 0, 0.059), 0 14.6px 33.4px rgba(0, 0, 0, 0.059),
		0 35px 80px rgba(0, 0, 0, 0.07);
	cursor: pointer;
	height: var(--about-video-btn-size);
	position: absolute;
	width: var(--about-video-btn-size);
}

.home-video-btn:active .fondo {
	background: var(--pink);
}

.icono {
	height: 150px;
	transform: rotate(-120deg);
	transition: transform 500ms;
	width: 150px;
}

.parte {
	background: white;
	height: 150px;
	position: absolute;
	width: 150px;
}

.izquierda {
	clip-path: polygon(43.77666% 55.85251%,
			43.77874% 55.46331%,
			43.7795% 55.09177%,
			43.77934% 54.74844%,
			43.77855% 54.44389%,
			43.77741% 54.18863%,
			43.77625% 53.99325%,
			43.77533% 53.86828%,
			43.77495% 53.82429%,
			43.77518% 53.55329%,
			43.7754% 53.2823%,
			43.77563% 53.01131%,
			43.77585% 52.74031%,
			43.77608% 52.46932%,
			43.7763% 52.19832%,
			43.77653% 51.92733%,
			43.77675% 51.65633%,
			43.77653% 51.38533%,
			43.7763% 51.11434%,
			43.77608% 50.84334%,
			43.77585% 50.57235%,
			43.77563% 50.30136%,
			43.7754% 50.03036%,
			43.77518% 49.75936%,
			43.77495% 49.48837%,
			44.48391% 49.4885%,
			45.19287% 49.48865%,
			45.90183% 49.48878%,
			46.61079% 49.48892%,
			47.31975% 49.48906%,
			48.0287% 49.4892%,
			48.73766% 49.48934%,
			49.44662% 49.48948%,
			50.72252% 49.48934%,
			51.99842% 49.4892%,
			53.27432% 49.48906%,
			54.55022% 49.48892%,
			55.82611% 49.48878%,
			57.10201% 49.48865%,
			58.3779% 49.4885%,
			59.6538% 49.48837%,
			59.57598% 49.89151%,
			59.31883% 50.28598%,
			58.84686% 50.70884%,
			58.12456% 51.19714%,
			57.11643% 51.78793%,
			55.78697% 52.51828%,
			54.10066% 53.42522%,
			52.02202% 54.54581%,
			49.96525% 55.66916%,
			48.3319% 56.57212%,
			47.06745% 57.27347%,
			46.11739% 57.79191%,
			45.42719% 58.14619%,
			44.94235% 58.35507%,
			44.60834% 58.43725%,
			44.37066% 58.41149%,
			44.15383% 58.27711%,
			43.99617% 58.0603%,
			43.88847% 57.77578%,
			43.82151% 57.43825%,
			43.78608% 57.06245%,
			43.77304% 56.66309%,
			43.773% 56.25486%);
	transition: clip-path 500ms;
}

.derecha {
	clip-path: polygon(43.77666% 43.83035%,
			43.77874% 44.21955%,
			43.7795% 44.59109%,
			43.77934% 44.93442%,
			43.77855% 45.23898%,
			43.77741% 45.49423%,
			43.77625% 45.68961%,
			43.77533% 45.81458%,
			43.77495% 45.85858%,
			43.77518% 46.12957%,
			43.7754% 46.40056%,
			43.77563% 46.67156%,
			43.77585% 46.94255%,
			43.77608% 47.21355%,
			43.7763% 47.48454%,
			43.77653% 47.75554%,
			43.77675% 48.02654%,
			43.77653% 48.29753%,
			43.7763% 48.56852%,
			43.77608% 48.83952%,
			43.77585% 49.11051%,
			43.77563% 49.38151%,
			43.7754% 49.65251%,
			43.77518% 49.9235%,
			43.77495% 50.1945%,
			44.48391% 50.19436%,
			45.19287% 50.19422%,
			45.90183% 50.19408%,
			46.61079% 50.19394%,
			47.31975% 50.1938%,
			48.0287% 50.19366%,
			48.73766% 50.19353%,
			49.44662% 50.19338%,
			50.72252% 50.19353%,
			51.99842% 50.19366%,
			53.27432% 50.1938%,
			54.55022% 50.19394%,
			55.82611% 50.19408%,
			57.10201% 50.19422%,
			58.3779% 50.19436%,
			59.6538% 50.1945%,
			59.57598% 49.79136%,
			59.31883% 49.39688%,
			58.84686% 48.97402%,
			58.12456% 48.48572%,
			57.11643% 47.89493%,
			55.78697% 47.16458%,
			54.10066% 46.25764%,
			52.02202% 45.13705%,
			49.96525% 44.01371%,
			48.3319% 43.11074%,
			47.06745% 42.4094%,
			46.11739% 41.89096%,
			45.42719% 41.53667%,
			44.94235% 41.3278%,
			44.60834% 41.24561%,
			44.37066% 41.27137%,
			44.15383% 41.40575%,
			43.99617% 41.62256%,
			43.88847% 41.90709%,
			43.82151% 42.24461%,
			43.78608% 42.62041%,
			43.77304% 43.01978%,
			43.773% 43.428%);
	transition: clip-path 500ms;
}

.puntero {
	border-radius: 50%;
	cursor: pointer;
	height: var(--about-video-btn-size);
	position: absolute;
	-webkit-tap-highlight-color: transparent;
	width: var(--about-video-btn-size);
}

.active .icono {
	transform: rotate(-90deg);
}

.active .izquierda {
	clip-path: polygon(56.42249% 57.01763%,
			54.93283% 57.0175%,
			53.00511% 57.01738%,
			50.83554% 57.01727%,
			48.62036% 57.01718%,
			46.55585% 57.01709%,
			44.83822% 57.01702%,
			43.66373% 57.01698%,
			43.22863% 57.01696%,
			42.86372% 57.01904%,
			42.56988% 57.01621%,
			42.3402% 56.99486%,
			42.16778% 56.94152%,
			42.0457% 56.84267%,
			41.96705% 56.68478%,
			41.92493% 56.45432%,
			41.91246% 56.13777%,
			41.91258% 55.76282%,
			41.9129% 55.37058%,
			41.91335% 54.96757%,
			41.91387% 54.56032%,
			41.91439% 54.15537%,
			41.91485% 53.75926%,
			41.91517% 53.3785%,
			41.91529% 53.01965%,
			41.94275% 52.72355%,
			42.02117% 52.51653%,
			42.14465% 52.38328%,
			42.30727% 52.30854%,
			42.50308% 52.27699%,
			42.72619% 52.27341%,
			42.97065% 52.28248%,
			43.23056% 52.2889%,
			43.94949% 52.28896%,
			45.45083% 52.28912%,
			47.47445% 52.28932%,
			49.76027% 52.28957%,
			52.04818% 52.28981%,
			54.07805% 52.29003%,
			55.5898% 52.29019%,
			56.32332% 52.29024%,
			56.58221% 52.28816%,
			56.83726% 52.28948%,
			57.07897% 52.30593%,
			57.29794% 52.34898%,
			57.48468% 52.43029%,
			57.62978% 52.56146%,
			57.72375% 52.7541%,
			57.75718% 53.01981%,
			57.75713% 53.37763%,
			57.75699% 53.81831%,
			57.75679% 54.31106%,
			57.75657% 54.82507%,
			57.75635% 55.32958%,
			57.75615% 55.79377%,
			57.75601% 56.18684%,
			57.75596% 56.47801%,
			57.7549% 56.50122%,
			57.74034% 56.5624%,
			57.6955% 56.64887%,
			57.60334% 56.748%,
			57.44691% 56.84712%,
			57.20925% 56.93358%,
			56.87342% 56.99471%);
}

.active .derecha {
	clip-path: polygon(56.42249% 42.44625%,
			54.93283% 42.44637%,
			53.00511% 42.44649%,
			50.83554% 42.4466%,
			48.62036% 42.4467%,
			46.55585% 42.44679%,
			44.83822% 42.44685%,
			43.66373% 42.4469%,
			43.22863% 42.44691%,
			42.86372% 42.44483%,
			42.56988% 42.44767%,
			42.3402% 42.46902%,
			42.16778% 42.52235%,
			42.0457% 42.6212%,
			41.96705% 42.77909%,
			41.92493% 43.00956%,
			41.91246% 43.32611%,
			41.91258% 43.70105%,
			41.9129% 44.0933%,
			41.91335% 44.49631%,
			41.91387% 44.90355%,
			41.91439% 45.3085%,
			41.91485% 45.70462%,
			41.91517% 46.08537%,
			41.91529% 46.44422%,
			41.94275% 46.74032%,
			42.02117% 46.94735%,
			42.14465% 47.0806%,
			42.30727% 47.15534%,
			42.50308% 47.18688%,
			42.72619% 47.19047%,
			42.97065% 47.1814%,
			43.23056% 47.17497%,
			43.94949% 47.17491%,
			45.45083% 47.17476%,
			47.47445% 47.17455%,
			49.76027% 47.1743%,
			52.04818% 47.17406%,
			54.07805% 47.17384%,
			55.5898% 47.17369%,
			56.32332% 47.17363%,
			56.58221% 47.17571%,
			56.83726% 47.17439%,
			57.07897% 47.15795%,
			57.29794% 47.1149%,
			57.48468% 47.03359%,
			57.62978% 46.90242%,
			57.72375% 46.70977%,
			57.75718% 46.44406%,
			57.75713% 46.08625%,
			57.75699% 45.64557%,
			57.75679% 45.15282%,
			57.75657% 44.6388%,
			57.75635% 44.1343%,
			57.75615% 43.6701%,
			57.75601% 43.27703%,
			57.75596% 42.98586%,
			57.7549% 42.96265%,
			57.74034% 42.90148%,
			57.6955% 42.815%,
			57.60334% 42.71587%,
			57.44691% 42.61675%,
			57.20925% 42.53029%,
			56.87342% 42.46916%);
}

.home-video-sound-btn {
	display: flex;
	flex-direction: row;
	gap: 6px;
	position: absolute;
	bottom: 20px;
	left: 25px;
	transition: all 0.5s ease-in-out;
	z-index: 4;
	opacity: 0;
	cursor: pointer;
	width: 60px;
	height: 45px;
}

.home-video-sound-btn.active {
	opacity: 1;
}

.home-video-sound-btn span {
	display: inline-block;
	width: 6px;
	height: 45px;
	background-color: #fff;
	will-change: transform;
	transform-origin: bottom;
	border-radius: 50px;
}

.home-video-sound-btn.sound-on span {
	animation: bounce 1.8s linear infinite;
}

.home-video-sound-btn span:nth-child(2) {
	animation-delay: 0.4s;
}

.home-video-sound-btn span:nth-child(3) {
	animation-delay: 0.7s;
}

.home-video-sound-btn span:nth-child(4) {
	animation-delay: 0.5s;
}

.home-video-sound-btn span:nth-child(2n) {
	height: 30px;
	margin-top: auto;
}

.home-video-sound-btn span:nth-child(1) {
	height: 40px;
	margin-top: auto;
}

.home-video-sound-btn span:last-child {
	height: 20px;
}

@keyframes bounce {
	0% {
		transform: scaleY(1);
	}

	20% {
		transform: scaleY(0.3);
	}

	40% {
		transform: scaleY(0.7);
	}

	60% {
		transform: scaleY(0.6);
	}

	80% {
		transform: scaleY(0.4);
	}

	100% {
		transform: scaleY(1);
	}
}

#home_about p {
	font-size: 18px;
	font-weight: 500;
	line-height: 30px;
	padding-top: 5px;
}

.home-about-numbers {
	display: flex;
	align-items: flex-start;
	gap: 40px;
	text-align: center;
	margin-top: 35px;
}

.home-about-number {
	width: 120px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--white);
	border-radius: var(--br-lg);
	font-size: 28px;
	font-weight: 700;
}

.home-about-number-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 12px;
	width: 180px;
}

.home-about-text {
	font-weight: 500;
}

@media (max-width: 1500px) {
	.home-about-text {
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
	}
}

@media (max-width: 1400px) {
	video#home_video {
		height: 300px;
	}
}

@media (max-width: 1200px) {
	#home_about p {
		font-size: 16px;
		line-height: 28px;
	}

	.home-about-numbers {
		gap: 25px;
		margin-top: 25px;
	}

	.home-about-number {
		width: 100px;
		height: 80px;
		font-size: 20px;
	}

	.home-about-text {
		font-size: 12px;
		line-height: 16px;
	}

}

@media (max-width: 992px) {
	video#home_video {
		height: 370px;
	}
}

@media (max-width: 576px) {
	video#home_video {
		height: 300px;
		border-radius: var(--br-xl)
	}

	.home-about-numbers {
		flex-wrap: wrap;
		justify-content: center;
		gap: 30px;
	}

	:root {
		--about-video-btn-size: 60px;
	}

	.home-video-btn {
		bottom: -21%;
	}

	.home-about-number {
		width: 100%;
		height: 110px;
		font-size: 38px;
	}

	.home-about-number-block {
		width: 100%;
		gap: 15px
	}

	.home-about-text {
		font-size: 14px;
		line-height: 20px;
	}
}

@media (max-width: 480px) {
	video#home_video {
		height: 220px;
	}

	.home-video-btn {
		bottom: -28%;
	}
}


/*
SHARE HISTORY FRENDOM
*/

.swiper.sliderShareHistory {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding: 0 50px;
	margin-top: 60px;
}

.swiper.sliderShareHistory .swiper-slide {
	overflow: hidden;
	position: relative;
}

.swiper.sliderShareHistory .swiper-slide .history-author {
	width: 80px;
	height: 80px;
	margin: 0px auto;
	text-align: center;
	display: flex;
	box-shadow: 0 0 0 2px #fff, 0 0 0 3px #ffffff;
	border-width: 4px;
	border-style: solid;
	background: linear-gradient(white, white) padding-box, linear-gradient(to right, #f6b256, #d22263) border-box;
	border-radius: 50em;
	position: absolute;
	border: 4px solid transparent;
	top: 0;
	left: 0;
	right: 0;
}


.swiper.sliderShareHistory .swiper-slide .history-main-img {
	width: 100%;
	border-radius: 50px;
	height: 17vw;
	object-fit: cover;
	margin-top: 44px;
}

.swiper.sliderShareHistory .swiper-slide>div {
	text-align: center;
	margin-top: 12px;
	font-weight: 500;
	font-size: 14px;
}

.swiper.sliderShareHistory .swiper-wrapper>div:nth-child(2n) {
	margin-top: 35px;
}

@media (max-width: 1500px) {
	.swiper.sliderShareHistory .swiper-slide .history-main-img {
		height: 22vw;
	}
}

@media (max-width: 1200px) {
	.swiper.sliderShareHistory .swiper-slide .history-main-img {
		height: 260px;
	}
}

@media (max-width: 768px) {
	.swiper.sliderShareHistory .swiper-slide .history-main-img {
		border-radius: var(--br-xl);
	}

	.swiper.sliderShareHistory {
		margin-top: 40px;
	}
}

@media (max-width: 480px) {
	.swiper.sliderShareHistory .swiper-slide .history-main-img {
		height: 250px;
	}

	.swiper.sliderShareHistory {
		padding: 0 30px;
	}

	.swiper.sliderShareHistory .swiper-wrapper>div:nth-child(2n) {
		margin-top: 0;
	}
}


/*
HOME SUBSCRIBES
*/

section#home_subscribe {
	background: rgba(231, 228, 223, 0.55);
	padding: 40px 0;
}

.home-subscribe-title {
	font-weight: 600;
	font-size: 34px;
	line-height: 44px;
	margin-bottom: 20px;
}

p.home-subscribe-text {
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 35px;
}

.subscribe-form-wrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.subscribe-form-wrapper.footer {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-direction: column;
}

.subscribe-form-wrapper .form-cf-header-item {
	width: 60%;
	height: 100%;
	margin: 0;
	background: #fff;
}

.subscribe-form-wrapper.footer .form-cf-header-item.footer {
	width: 100%;
	height: 100%;
	margin: 0;
	background: #fff;
}

.subscribe-form-wrapper .form-cf-header-item input {
	background: #fff;
}

input.btn.btn-default.btn-pink.subscribe-btn {
	color: #fff;
	font-weight: 400;
	width: 40%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	padding: 20px 0;
	box-shadow: var(--shadow-mini-block);
}

input.btn.btn-default.btn-pink.subscribe-btn.footer {
	color: #fff;
	font-weight: 400;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	padding: 10px 0;
	box-shadow: var(--shadow-mini-block);
}

.home-subscribes-svg {
	width: 100%;
	height: auto;
	position: relative;
}

.home-subscribes-svg>g:nth-child(2n) {
	animation-name: floating;
	animation-duration: 5s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.home-subscribes-svg>g:nth-child(3n) {
	animation-name: floating2;
	animation-duration: 4s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.home-subscribes-svg>g:nth-child(3n) {
	animation-name: floating2;
	animation-duration: 4s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.home-subscribes-svg>g:nth-child(6) {
	animation-name: floating2;
	animation-duration: 4s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.home-subscribes-svg>g {
	animation-name: floating3;
	animation-duration: 3s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.home-subscribes-svg .magnet {
	animation: none !important;
}

@keyframes floating6el {
	0% {
		transform: translate(0, 0);
	}

	50% {
		transform: translate(1px, 2px);
	}

	100% {
		transform: translate(0, 0);
	}
}

@keyframes floating {
	0% {
		transform: translate(0, 0);
	}

	50% {
		transform: translate(3px, 5px);
	}

	100% {
		transform: translate(0, 0);
	}
}

@keyframes floating2 {
	0% {
		transform: translate(0, 0);
	}

	50% {
		transform: translate(2px, 4px);
	}

	100% {
		transform: translate(0, 0);
	}
}

@keyframes floating3 {
	0% {
		transform: translate(0, 0);
	}

	50% {
		transform: translate(4px, 8px);
	}

	100% {
		transform: translate(0, 0);
	}
}

@media (max-width: 992px) {
	.home-subscribes-svg {
		width: 800px;
		height: 500px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		opacity: 0.3;
	}

	section#home_subscribe {
		position: relative;
		overflow: hidden;
	}

	section#home_subscribe .row>div>div,
	section#home_subscribe .row>div>p,
	section#home_subscribe .row>div>form {
		position: relative;
		z-index: 3;
	}

	input.btn.btn-default.btn-pink.subscribe-btn {
		height: 50px;
		padding: 15px;
	}
}

@media (max-width: 768px) {
	.home-subscribe-title {
		font-size: 22px;
		line-height: 33px;
		margin-bottom: 15px;
	}

	p.home-subscribe-text {
		font-size: 14px;
		line-height: 25px;
		margin-bottom: 20px;
	}

	input.btn.btn-default.btn-pink.subscribe-btn {
		height: 44px;
		font-size: 14px;
	}
}

@media (max-width: 480px) {
	.subscribe-form-wrapper {
		flex-wrap: wrap;
		gap: 15px;
	}

	.subscribe-form-wrapper .form-cf-header-item {
		width: 100%;
	}

	input.btn.btn-default.btn-pink.subscribe-btn {
		width: 100%
	}

}


/*

HOME END SECTION
*/

section#home_end .home-title {
	margin-bottom: 25px;
}

section#home_end img {
	width: 100%;
	object-fit: cover;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	height: 300px;
}

section#home_end .home-end-images-wr img {
	width: 48%;
	height: 200px;
}

section#home_end p {
	line-height: 29px;
}


@media (max-width: 1500px) {
	section#home_end p {
		line-height: 26px;
		margin-bottom: 8px;
		font-size: 14px;
	}
}

@media (max-width: 1200px) {
	section#home_end .home-end-images-wr img {
		height: 170px;
	}

	section#home_end img {
		border-radius: var(--br-xl);
		height: 356px;
	}
}

@media (max-width: 992px) {
	.home-end-images-wr {
		margin-top: 20px;
	}
}

@media (max-width: 576px) {
	section#home_end .home-end-images-wr img {
		height: 120px;
	}

	section#home_end img {
		height: 220px;
	}
}


/*

COMPARE PAGE

*/

div#product-compare {
	margin-top: 35px;
}

.compare-wrapper {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.arrows-compare-wr {
	position: absolute;
	top: -1%;
	right: 2%;
	z-index: 2;
	margin-left: auto;
	display: flex;
	align-items: center;
	gap: 30px;
}

.compare-wrapper aside {
	width: 15%;
	padding-right: 15px;
	margin-top: var(--comp-pr-card-h);
	padding-top: 45px;
	display: flex;
	flex-direction: column;
}

@media (max-width: 1400px) {
	.compare-wrapper aside {
		padding-top: 85px;
	}
}

.swiper.pageCompare {
	padding: 15px;
}

div#v-pills-compareCategoriesTabContent {
	width: 80%;
}

:root {
	--comp-pr-card-h: 370px
}

.compare-product-card-slide .product-card-wrapper {
	border-radius: var(--br-xl);
	box-shadow: none;
	height: var(--comp-pr-card-h);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0;
	padding-top: 40px;
	height: max-content;
	padding-bottom: 35px;
}

.swiper-slide.product-card-slide.compare-product-card-slide {
	box-shadow: var(--shadow-minimal);
	padding: 10px;
	border-radius: var(--br-xl);
}

.compare-product-card-slide .product-card-sale {
	position: absolute;
	width: 32px;
	height: 32px;
	font-size: 11px;
	font-weight: 400;
	padding: 0px 0 0 2px;
	left: 6%;
}

.compare-product-card-slide .btn-product-card {
	right: 6% !important;
}

.compare-product-card-slide .favorite-btn-product-card.favorite-add.btn-product-card {
	right: 22% !important;
}

.compare-product-card-slide .product-card-name {
	font-size: 20px;
	font-weight: 500;
}

.compare-product-card-slide .product-card-subname {
	font-size: 14px;
	margin-top: 0px;
}

.compare-product-card-slide .product-card-price.sale-red-price {
	font-size: 22px;
}

.compare-product-card-slide .product-card-price-old {
	font-size: 14px;
}

.compare-product-card-slide .product-card-btn-add {
	font-size: 14px;
	margin-top: 15px;
	height: 36px;
}

.compare-product-card-slide .product-card-main-img {
	height: 120px;
	object-fit: contain;
	padding: 15px;
}

.compare-attributes-wr {
	cursor: auto;
}

:root {
	--compare-atr-title-h: 120px;
}

.compare-attribute-title {
	font-size: 18px;
	font-weight: 500;
	height: var(--compare-atr-title-h)
}

.compare-attribute-item {
	height: var(--compare-atr-title-h);
	padding: 0 8px;
	text-align: center;
}

.compare-attribute-item hr {
	width: 60px;
	margin: 0 auto;
}

.compare-categories-links {
	gap: 25px;
	padding: 5px;
}

.nav.nav-pills.compare-categories-links button.nav-link {
	background-color: #fff;
	color: var(--user-color);
	font-size: 16px;
	box-shadow: var(--shadow-mini-block);
	padding: 14px 25px;
	border-radius: var(--br-lg);
	line-height: 1;
	transition: var(--transition-btns);
	position: relative;
}

.compare-close-category {
	position: absolute;
	top: -19%;
	right: -6%;
	display: block;
	font-size: 13px;
	padding: 4px;
	background: #fff;
	color: var(--red);
	border-radius: 100%;
	box-shadow: var(--shadow-minimal);
	border: 1px solid rgba(0, 0, 0, 0.0588235294);
	transition: var(--transition-btns);
	z-index: 2;
}

.compare-close-category:hover {
	background: var(--red);
	color: #fff
}

.nav.nav-pills.compare-categories-links button.nav-link:hover {
	box-shadow: var(--shadow-block);
}

.nav.nav-pills.compare-categories-links button.nav-link.active {
	background-color: var(--pink);
	color: #fff;
}

.compare-product-card-slide .product-card-sale,
.compare-product-card-slide .btn-product-card,
.compare-product-card-slide .btn-product-card {
	top: 17px
}

.compare-empty-wr {
	margin: 50px auto;
	background: #fff;
	box-shadow: var(--shadow-block);
	padding: 30px;
	border-radius: var(--br-xxl);
	height: 350px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	overflow: hidden;
	z-index: 2;
}

.compare-empty-wr.not-found404>div {
	position: relative;
	z-index: 3;
}

.compare-empty-wr.not-found404::after {
	content: "404";
	position: absolute;
	left: 0;
	right: 0;
	font-size: 550px;
	z-index: 1;
	color: #00000012;
}

.favorite-add.active svg path:first-child,
.favorite-add.active svg path:last-child {
	fill: var(--red);
}

.btn-product-card svg path:first-child {
	fill: #fff0;
}

.product-card-top-icon-wrapper {
	position: absolute;
	top: 6%;
	z-index: 5;
	left: 23px;
	display: flex;
	gap: 10px;
}

.product-card-bestseller-icon {
	background-color: #fc0;
	font-weight: 500;
}

.product-card-latest-icon {
	font-weight: 500;
	color: #fff;
	background-color: var(--red);
}

.product-card-top-icon {
	width: -moz-max-content;
	width: -webkit-max-content;
	width: max-content;
	padding: 6px 8px;
	padding-bottom: 8px;
	border-radius: var(--br-sm);
	font-size: 13px;
	line-height: 1;
}


@media (max-width: 1200px) {
	.product-card-top-icon {
		font-size: 12px;
	}
}


@media (max-width: 992px) {
	.compare-empty-wr.not-found404::after {
		font-size: 400px;
	}
}

@media (max-width: 576px) {
	.compare-empty-wr.not-found404::after {
		left: -30px;
		font-size: 220px;
	}

	.product-card-top-icon-wrapper {
		position: static;
		margin-top: 20px;
		gap: 7px;
	}

	.product-card-top-icon {
		font-size: 10px;
		line-height: normal;
		padding-top: 4px;
		padding-bottom: 5px;
	}
}

.compare-empty-title {
	font-size: 32px;
	font-weight: 500;
	margin-bottom: 30px;
	line-height: 42px;
}

.compare-empty-text {
	font-size: 20px;
	margin-bottom: 20px;
}

.compare-empty-wr a {
	width: 300px !important;
	height: 50px !important;
	font-size: 20px !important;
}

.compare-empty-text .icon-compare {
	font-size: 23px;
}

.compare-attribute-title-mob {
	background: #0000000f;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	padding: 5px;
	border-radius: var(--br-lg);
}

.compare-product-card-slide .compare-add .icon-compare {
	color: var(--red);
}

@media (max-width: 1400px) {
	div#v-pills-compareCategoriesTabContent {
		padding-top: 40px;
	}

	.arrows-compare-wr {
		top: 0%;
	}
}

@media (max-width: 992px) {
	.compare-empty-title {
		font-size: 26px;
		margin-bottom: 20px;
		line-height: 36px;
	}

	.compare-empty-text {
		font-size: 18px;
		line-height: 26px;
	}

	.compare-attribute-title {
		font-size: 16px;
	}
}

@media (max-width: 768px) {
	.compare-empty-wr a {
		width: 100% !important
	}

	.compare-wrapper aside {
		display: none
	}

	.compare-wrapper {
		flex-wrap: wrap
	}

	div#v-pills-compareCategoriesTabContent {
		width: 100%;
	}

	:root {
		--compare-atr-title-h: 80px;
	}

	.compare-attribute-item {
		overflow-y: auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

}

@media (max-width: 576px) {
	.compare-empty-wr {
		margin: 30px auto;
		padding: 20px;
		border-radius: var(--br-xl)
	}

	.compare-empty-title {
		font-size: 20px;
		line-height: 30px;
	}

	.compare-empty-text {
		font-size: 16px;
		line-height: 23px;
	}

	.compare-empty-wr a {
		height: 42px !important;
		font-size: 16px !important;
	}

	.compare-categories-links {
		gap: 18px;
		padding: 0;
		justify-content: center;
	}

	.swiper.pageCompare {
		padding: 0px;
	}
}

@media (max-width: 480px) {
	.compare-product-card-slide .btn-product-card {
		width: 25px;
		height: 25px;
	}

	.compare-product-card-slide .favorite-btn-product-card.favorite-add.btn-product-card {
		right: 26% !important;
	}

	.compare-product-card-slide .product-card-sale {
		width: 25px;
		height: 25px;
		font-size: 9px;
	}

	.compare-product-card-slide .product-card-main-img {
		height: 100px;
		border-radius: var(--br-sm);
	}

	.compare-product-card-slide .product-card-wrapper {
		border-radius: var(--br-sm);
	}

	:root {
		--comp-pr-card-h: 265px;
	}

	.compare-product-card-slide .product-card-sale,
	.compare-product-card-slide .btn-product-card,
	.compare-product-card-slide .btn-product-card {
		top: 8px;
	}

	.compare-product-card-slide .product-card-name {
		font-size: 18px;
		margin-top: 8px;
	}

	.compare-product-card-slide .product-card-subname {
		font-size: 11px;
		margin-top: 0px;
	}

	.compare-product-card-slide .product-card-price.sale-red-price {
		font-size: 16px;
	}

	.compare-product-card-slide .product-card-price-old {
		font-size: 12px;
	}

	.product-card-price-wrapper {
		flex-wrap: wrap;
	}

	.swiper-slide.product-card-slide.compare-product-card-slide {
		padding: 5px;
	}

	.compare-product-card-slide .product-card-btn-add {
		font-size: 11px;
		margin-top: 15px;
		height: 30px;
		padding: 5px;
	}

	.compare-attribute-title-mob {
		font-size: 12px;
		line-height: 18px;
	}

	.compare-attribute-item {
		font-size: 11px;
		line-height: 16px;
	}

	.swiper.pageCompare {
		padding: 10px 4px;
	}

}

/*

ARTICLES CATEGORY PAGE

*/

.cat-articles-hero img {
	width: 100%;
	border-radius: var(--br-xxl);
	object-fit: cover;
	height: 400px;
}

.cat-articles-hero {
	position: relative;
}

.cat-articles-hero::after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: var(--br-xxl);
	background: rgba(0, 0, 0, 0.20);
	z-index: 2;
}

.cat-arcitles-hero-title {
	position: absolute;
	bottom: 6%;
	left: 2%;
	z-index: 3;
	color: #fff;
	font-weight: 600;
	font-size: 44px;
	letter-spacing: 1px;
}

.cat-articles-hero {
	position: relative;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	margin-top: 30px;
	margin-bottom: 40px;
}

.cat-articles-item {
	position: relative;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	display: block;
	height: 310px;
}

.cat-articles-item img {
	width: 100%;
	border-radius: var(--br-xxl);
	height: inherit;
	object-fit: cover;
}

.cat-articles-item::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.25);
	border-radius: var(--br-xxl);
	z-index: 1;
}

.cat-acticle-item-text-wr {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 2;
	color: #fff;
	padding: 15px;
}

.cat-acticle-item-text-wr h2 {
	font-size: 18px;
	font-weight: 500;
	line-height: 25px;
}

.act-article-item-date {
	font-size: 14px;
	font-weight: 500;
}



@media (max-width: 1500px) {
	.cat-articles-item {
		height: 250px;
	}
}

@media (max-width: 1200px) {
	.cat-articles-hero img {
		height: 300px;
	}

	.cat-arcitles-hero-title {
		bottom: 8%;
		left: 3%;
		font-weight: 500;
		font-size: 32px;
	}

	.cat-articles-hero {
		margin-bottom: 25px;
	}
}

@media (max-width: 576px) {

	.cat-articles-hero img {
		height: 115px;
		border-radius: var(--br-xl);
	}

	.cat-articles-hero::after {
		border-radius: var(--br-xl);
	}

	.cat-arcitles-hero-title {
		bottom: 9%;
		left: 3%;
		font-weight: 500;
		font-size: 21px;
		letter-spacing: 0px;
	}

	.cat-articles-item,
	.cat-articles-item img,
	.cat-articles-item::after {
		border-radius: var(--br-xl);
	}

	.cat-acticle-item-text-wr {
		padding: 7px 10px 5px 10px;
	}

	.cat-articles-item {
		height: 220px;
	}

	.act-article-item-date {
		font-size: 12px;
		font-weight: 400;
	}

	.cat-acticle-item-text-wr h2 {
		font-size: 16px;
		font-weight: 500;
		line-height: 21px;
	}

}

/*

ARTICLE CART PAGE

*/

div#article-cart {
	margin-top: 60px;
}

.article-date {
	font-size: 16px;
	font-weight: 500;
}

.article-title-wr h1 {
	font-size: 32px;
	font-weight: 600;
	line-height: 42px;
	margin-top: 5px;
}

div#article-cart p {
	font-size: 18px;
	line-height: 32px;
}

.article-date>span:first-child {
	font-size: 20px;
	display: inline-block;
	transform: translateY(2px);
}

.swiper.article-cart-slider .swiper-slide img {
	border-radius: inherit;
	width: inherit;
	height: inherit;
	object-fit: cover;
	object-position: 50% 70%
}

.swiper.article-cart-slider .swiper-slide {
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xxl);
}

.swiper.article-cart-slider .swiper-slide .swiper-slide-shadow {
	border-radius: inherit;
}

.swiper.articleCartSlider1 .swiper-slide,
.swiper.articleCartSlider1 .swiper-slide .swiper-slide-shadow {
	height: 450px;
}

.swiper.articleCartSlider1 {
	width: 100%;
	padding-right: 30px;
}

img.article-hero-img {
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	height: 450px;
	object-fit: cover;
	margin-top: 60px;
}

.swiper-pagination.article-cart-pag {
	position: static;
	margin-top: 20px;
}

.swiper-pagination.article-cart-pag span.swiper-pagination-bullet {
	width: 13px;
	height: 13px;
	background: var(--dark-grey);
	opacity: 0.4;
	transition: all .6s ease-in-out
}

.swiper-pagination.article-cart-pag span.swiper-pagination-bullet-active {
	opacity: 1;
	width: 25px;
	border-radius: 9px;
}

.article-text2 {
	padding-top: 15px;
	padding-left: 30px;
}

.swiper.article-cart-slider {
	border-radius: var(--br-xxl);
}

.swiper.articleCartSlider2 {
	height: 425px;
}


@media (max-width: 1200px) {
	.article-text2 {
		padding-left: 0;
	}

	.article-title-wr h1 {
		font-size: 24px;
		line-height: 36px;
	}

	div#article-cart p {
		font-size: 16px;
		line-height: 28px;
	}

	img.article-hero-img {
		height: 400px;
		margin-top: 85px;
	}

	.swiper.articleCartSlider2 {
		height: 315px;
	}

}

@media (max-width: 992px) {
	img.article-hero-img {
		margin-top: 0;
		height: auto;
	}

	.swiper.articleCartSlider1 {
		width: 50%;
		margin: 0 auto;
		padding: 0;
	}

	.swiper.articleCartSlider1 .swiper-slide,
	.swiper.articleCartSlider1 .swiper-slide .swiper-slide-shadow {
		height: 500px;
	}

	.article-text2 {
		padding: 0;
	}

	div#article-cart {
		margin-top: 30px;
	}

	.swiper.articleCartSlider2,
	.swiper.articleCartSlider3 {
		height: 450px;
	}

}

@media (max-width: 768px) {

	.swiper.articleCartSlider2,
	.swiper.articleCartSlider3 {
		height: 320px;
	}

	.swiper.articleCartSlider1 .swiper-slide,
	.swiper.articleCartSlider1 .swiper-slide .swiper-slide-shadow {
		height: 400px;
	}

}

@media (max-width: 576px) {
	.swiper.articleCartSlider1 {
		width: 70%;
	}

	.swiper.article-cart-slider .swiper-slide,
	.swiper.article-cart-slider,
	img.article-hero-img {
		border-radius: var(--br-xl);
	}

	.swiper-pagination.article-cart-pag {
		margin-top: 5px;
	}

	.swiper-pagination.article-cart-pag span.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
	}

	.swiper.articleCartSlider1 .swiper-slide,
	.swiper.articleCartSlider1 .swiper-slide .swiper-slide-shadow {
		height: 500px;
	}

}

@media (max-width: 480px) {

	.swiper.articleCartSlider2,
	.swiper.articleCartSlider3 {
		height: 250px;
	}

	.swiper.articleCartSlider1 {
		width: 80%;
	}

	.swiper.articleCartSlider1 .swiper-slide,
	.swiper.articleCartSlider1 .swiper-slide .swiper-slide-shadow {
		height: 350px;
	}

}

/*

PROMO CATEGORY PAGE

*/

a.promotion-category-item {
	display: block;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
}

a.promotion-category-item img {
	height: 400px;
	border-radius: var(--br-xxl);
	object-fit: cover;
}


@media (max-width: 1600px) {
	a.promotion-category-item img {
		height: 350px;
	}
}

@media (max-width: 1500px) {
	a.promotion-category-item img {
		height: 340px;
	}
}

@media (max-width: 1400px) {
	a.promotion-category-item img {
		height: 300px;
	}
}

@media (max-width: 1200px) {
	a.promotion-category-item img {
		height: 255px;
	}
}

@media (max-width: 992px) {
	a.promotion-category-item img {
		height: auto;
	}

}

@media (max-width: 768px) {
	a.promotion-category-item img {
		border-radius: var(--br-xl);
	}
}

/* 

PROMO CARD

*/

img.promo-card-main-img {
	width: 100%;
	border-radius: var(--br-xxl);
	height: auto;
}

div#promo-card .catalog-products-wrapper {
	margin-top: 40px;
}

div#promo-card .catalog-layout-mode-wr {
	margin-top: 0;
}

.promo-card-layout {
	margin: 45px 0;
}

@media (max-width: 768px) {
	img.promo-card-main-img {
		width: 100%;
		border-radius: var(--br-xl);
		height: auto;
		margin-bottom: 30px;
	}

	div#promo-card .home-title.page-title {
		margin-bottom: 20px;
		width: 100%;
		text-align: center;
	}

	div#promo-card .catalog-total {
		display: none;
	}

	div#promo-card .catalog-products-wrapper {
		margin-top: 25px;
	}
}




/*

CATALOG

*/

.catalog-total {
	height: max-content;
	margin-left: 5px;
	font-weight: 500;
	font-size: 14px;
}

.catalog-title-wr {
	display: flex;
	position: relative;
	margin-top: 30px;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.catalogCategoriesSlider .swiper-slide {
	background: var(--white);
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 12px;
	border-radius: var(--br-xl);
}

.swiper.catalogCategoriesSlider {
	padding: 30px 10px;
	padding-right: 10px;
}

.catalogCategoriesSlider .swiper-slide a {
	width: inherit;
	height: inherit;
	display: flex;
	align-items: center;
	justify-content: center;
}

.catalog-filter-btns-wr {
	margin-top: 45px;
}

div#product-category .home-title.page-title {
	margin-bottom: 5px;
}

.swiper-scrollbar.catalog-categories-scrollbar {
	height: var(--swiper-scrollbar-size, 6px);
}

a.catalog-popular-filter {
	width: -webkit-max-content;
	width: max-content;
	height: 42px;
	padding-right: 32px;
	padding-left: 32px;
	color: var(--user-color);
	background: rgb(231 228 223);
	font-size: 14px;
	transition: all 0.6s ease-in-out;
}

a.catalog-popular-filter:hover {
	box-shadow: none;
}

button.catalog-filters-btn {
	width: 185px;
	font-weight: 500;
	gap: 10px;
	font-size: 14px;
	height: 42px;
	box-shadow: var(--shadow-mini-block);
	transition: all .6s ease-in-out;
}

button.catalog-filters-btn:hover {
	box-shadow: var(--shadow-block)
}

button.catalog-filters-btn .icon-filter {
	font-size: 23px;
}

/* sort custom select */
.custom-select-wrapper {
	position: relative;
	display: inline-block;
	user-select: none;
}

.custom-select-wrapper select {
	display: none;
}

.custom-select {
	position: relative;
	display: inline-block;
}

.custom-select-trigger {
	position: relative;
	min-width: 135px;
	padding: 0 85px 0 25px;
	font-size: 14px;
	height: 42px;
	display: flex;
	align-items: center;
	font-weight: 500;
	color: #fff;
	line-height: 50px;
	background-color: var(--pink);
	border-radius: var(--br-xl);
	cursor: pointer;
	transition: all .6s ease-in-out;
	box-shadow: var(--shadow-mini-block);
}

.custom-select-trigger:hover {
	box-shadow: var(--shadow-block);
	background-color: var(--pink-hover);
}

.custom-select-trigger:after {
	position: absolute;
	display: block;
	content: '';
	width: 10px;
	height: 10px;
	top: 50%;
	right: 25px;
	margin-top: -3px;
	border-bottom: 1px solid #fff;
	border-right: 1px solid #fff;
	transform: rotate(45deg) translateY(-50%);
	transition: all .35s ease-out;
	transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
	margin-top: 3px;
	transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
	position: absolute;
	display: block;
	top: 100%;
	left: 0%;
	right: 0;
	width: 100%;
	margin: 10px 0;
	border-radius: var(--br-xl);
	box-sizing: border-box;
	background: #fff;
	transition: all .5s ease-in-out;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transform: translateY(-15px);
	border: 0;
	z-index: 10;
}

.custom-select.opened .custom-options {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
	transform: translateY(0);
	box-shadow: var(--shadow-mini-block);
}

.option-hover:before {
	background: #f9f9f9;
}

.custom-option {
	position: relative;
	display: block;
	padding: 0 17px;
	font-size: 14px;
	font-weight: 500;
	color: var(--user-color);
	line-height: 42px;
	cursor: pointer;
	transition: all 0.05s ease-in-out;
}

.custom-option:first-of-type {
	border-radius: var(--br-xl) var(--br-xl) 0 0;
	border-top-right-radius: var(--br-xl);
	border-top-left-radius: var(--br-xl);
}

.custom-option:last-of-type {
	border-bottom: 0;
	border-radius: 0 0 4px 4px;
	border-bottom-right-radius: var(--br-xl);
	border-bottom-left-radius: var(--br-xl);
}

.custom-option:hover,
.custom-option.selection {
	color: #fff;
	background-color: var(--pink);
}

/* catalog products */

.category-description {
	background: #fff;
	box-shadow: var(--shadow-block);
	padding: 17px;
	border-radius: var(--br-xxl);
	margin-top: 30px;
	font-size: 16px;
	line-height: 23px;
}

.catalog-products-wrapper {
	margin-top: 70px;
}

.catalog-products-wrapper .product-card-wrapper {
	position: relative;
}

.catalog-products-wrapper .btn-product-card {
	top: 6%;
}

.product-card-bottom-block {
	height: max-content;
	padding: 15px 19px 19px 19px;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 10;
	background: #fff;
	box-shadow: 6px 24px 25px -9px rgba(0, 0, 0, 0.1215686275);
	border-bottom-left-radius: var(--br-xl);
	border-bottom-right-radius: var(--br-xl);
	display: none;
}

.product-card-bottom-block.open {
	display: block !important;
}

button.product-card-open-bottom-block {
	display: none;
	margin: 0 auto;
	text-align: center;
	position: relative;
	background: none;
	border: none;
	margin-top: 20px;
	margin-bottom: 10px;
	transition: all .5s ease-in-out;
}

button.product-card-open-bottom-block::after {
	position: absolute;
	display: block;
	content: "";
	width: 10px;
	height: 10px;
	top: 0;
	left: 0;
	margin: 0 auto;
	right: 0;
	border-bottom: 1px solid var(--user-color);
	border-right: 1px solid var(--user-color);
	transform: rotate(45deg) translateY(-50%);
	transition: all 0.35s ease-out;
	transform-origin: 50% 0;
	padding: 8px;
}

.catalog-product-item-full button.product-card-open-bottom-block {
	display: none !important;
}

@media (max-width: 768px) {
	button.product-card-open-bottom-block::after {
		padding: 5px;
	}

	button.product-card-open-bottom-block {
		margin-top: 15px;
		margin-bottom: 5px;
	}
}

@media (max-width: 576px) {
	.product-card-bottom-block {
		padding: 15px 10px 19px 10px;
	}
}

button.open.product-card-open-bottom-block::after {
	transform: rotate(225deg) translateY(-50%);
}

.product-card-bottom-block>div>span {
	font-size: 14px;
	font-weight: 500;
}

.product-card-bottom-block>div>hr {
	margin: 10px 0;
	margin-top: 2px;
}

.product-card-options {
	background: var(--white);
	padding: 10px;
	border-radius: var(--br-lg);
	position: relative;
	margin-top: 15px;
}

.product-card-modules {
	background: var(--white);
	padding: 10px;
	border-radius: var(--br-lg);
	position: relative;
}

.product-card-option {
	font-size: 12px;
	line-height: 22px;
}

.product-card-option span:first-child {
	font-weight: 500;
}

.product-card-options .icon-sizes {
	position: absolute;
	top: 6px;
	right: 6px;
	font-size: 21px;
	background: #fff;
	padding: 4px;
	border-radius: 100%;
	line-height: 22px;
}

.product-card-promotions {
	background: var(--white);
	padding: 10px;
	border-radius: var(--br-lg);
	position: relative;
	margin-top: 15px;
}

.product-card-promotions-wr {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.product-card-promotion {
	font-size: 11px;
	background: var(--pink);
	color: #fff;
	padding: 1px 5px;
	border-radius: var(--br-sm);
}

.product-card-promotions-wr>div:nth-child(2n) {
	background: var(--green)
}

.product-card-promotions-wr>div:nth-child(3n) {
	background: var(--brown)
}

.catalog-products-wrapper .row>div {
	padding: calc(var(--bs-gutter-x) * 0.5);
}

@media (max-width: 576px) {
	.product-card-bottom-block>div>span {
		font-size: 10px;
		font-weight: 500;
	}
}

/*category pagination*/
.category-pagination-wrapper {
	margin-top: 40px;
}

.pagination {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 12px;
}

.pagination li {
	font-size: 21px;
	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	text-align: center;
}

.pagination li.active {
	border: 2px solid var(--grey-text);
	border-radius: 100%;
	width: 35px;
	height: 35px;
	line-height: 1;
	padding: 5px;
	font-weight: 400;
	color: var(--grey-text);
}

.pagination li a {
	text-decoration: none;
	color: #797979;
	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	font-weight: 400;
}

.pagination li a:hover {
	color: var(--grey-text);
}

.btn-category-load-more {
	width: 350px;
	height: 50px;
	margin: 30px auto 0px auto;
}

@media (max-width: 1500px) {
	.catalogCategoriesSlider .swiper-slide {
		width: 210px;
	}

	a.catalog-popular-filter {
		display: none
	}

	.product-card-promotion {
		font-size: 10px;
		padding: 1px 3px;
	}

	.product-card-promotions-wr {
		gap: 5px;
	}
}

@media (max-width: 1400px) {
	.catalog-products-wrapper {
		margin-top: 25px;
	}
}

@media (max-width: 992px) {
	.catalogCategoriesSlider .swiper-slide {
		width: 210px;
	}

	a.catalog-popular-filter {
		display: none
	}

	.catalog-products-wrapper .product-card-wrapper {
		padding-bottom: 35px;
	}

	button.product-card-open-bottom-block {
		display: block;
	}
}

@media (max-width: 768px) {
	.catalogCategoriesSlider .swiper-slide {
		width: 180px;
	}

	.catalogCategoriesSlider .swiper-slide {
		font-size: 12px;
	}

	button.catalog-filters-btn {
		width: 165px;
		gap: 8px;
	}

	button.catalog-filters-btn .icon-filter {
		font-size: 20px;
	}

	.custom-select-trigger {
		padding: 0 50px 0 25px;
	}

	.catalog-products-wrapper .product-card-wrapper {
		padding: 15px;
	}

	.catalog-products-wrapper .product-card-hover-wr.pch-item,
	.catalog-products-wrapper .product-card-img-wrapper,
	.catalog-products-wrapper .product-card-main-img,
	.catalog-products-wrapper .pc-img-item {
		height: 150px;
	}

	.catalog-products-wrapper .product-card-name {
		font-size: 18px;
	}

	.catalog-products-wrapper .product-card-subname {
		font-size: 12px;
		margin-top: 0px;
	}

	.catalog-products-wrapper .product-card-reviews-wr {
		margin-top: 5px;
	}

	.catalog-products-wrapper .product-card-sale {
		font-size: 9px;
		padding: 3px;
		width: 22px;
		height: 22px;
	}

	.catalog-products-wrapper .product-card-reviews-stars span {
		font-size: 18px;
		color: var(--orange);
	}

	.catalog-products-wrapper span.product-card-reviews-total {
		font-size: 12px;
	}

	.catalog-products-wrapper .product-card-bottom-wrapper {
		margin-top: 5px;
	}

	.catalog-products-wrapper .product-card-price.sale-red-price {
		font-size: 18px;
		margin-right: 5px;
	}

	.catalog-products-wrapper .product-card-price-old {
		font-size: 10px;
		margin-top: 8px;
	}

	.product-card-price-discount {
		font-size: 10px;
		margin-top: 0px;
		font-weight: 500;
	}

	.product-card-price-discount span {
		color: var(--red);
		font-size: 16px;
		font-family: 'Roboto+Condensed', sans-serif;
		font-weight: 600;
	}

	.catalog-products-wrapper .product-card-option {
		font-size: 10px;
		line-height: 17px;
	}

	.catalog-products-wrapper .product-card-btn-add {
		margin-top: 12px;
		height: 33px;
		font-size: 14px;
	}

	.catalog-products-wrapper .btn-product-card span {
		font-size: 16px;
	}

	.catalog-products-wrapper .btn-product-card svg {
		width: 16px;
		height: 16px;
	}

	.catalog-products-wrapper .compare-btn-product-card.compare-add.btn-product-card {
		right: 22px;
	}

	.catalog-products-wrapper .favorite-btn-product-card.favorite-add.btn-product-card {
		right: 58px;
	}

	.catalog-products-wrapper .btn-product-card {
		width: 27px;
		height: 27px;
	}
}

@media (max-width: 576px) {
	.btn-category-load-more {
		width: 100%;
	}

	.catalog-products-wrapper .product-card-btn-add {
		font-size: 10px;
	}

	.catalogCategoriesSlider .swiper-slide {
		width: 170px;
	}

	.catalog-total {
		margin-left: 4px;
		font-size: 11px;
		line-height: 16px;
	}

	.product-card-price-discount span {
		font-size: 12px;
	}

	button.catalog-filters-btn {
		width: 135px;
		font-weight: 400;
		gap: 7px;
		font-size: 12px;
		height: 35px;
	}

	button.catalog-filters-btn .icon-filter {
		font-size: 17px;
	}

	.custom-select-trigger:after {
		width: 6px;
		height: 6px;
		top: 55%;
		right: 18px;
	}

	.custom-select-trigger {
		min-width: 105px;
		padding: 0 34px 0 13px;
		font-size: 12px;
		height: 35px;
		font-weight: 400;
	}

	.custom-option {
		padding: 0px 14px;
		font-size: 11px;
		font-weight: 500;
		line-height: 28px;
	}

	.custom-select.opened .custom-select-trigger:after {
		margin-top: 1px;
	}

	.catalog-products-wrapper .product-card-price-old {
		font-size: 14px;
		margin-top: 0px;
	}

	.catalog-products-wrapper .product-card-hover-wr.pch-item,
	.catalog-products-wrapper .product-card-img-wrapper,
	.catalog-products-wrapper .product-card-main-img,
	.catalog-products-wrapper .pc-img-item {
		height: 125px;
	}
}

@media (max-width: 450px) {
	.catalog-products-wrapper .row>div {
		padding: 0.5rem;
	}

	.catalog-products-wrapper .product-card-wrapper {
		border-radius: var(--br-lg);
	}

	.catalog-products-wrapper .product-card-hover-wr.pch-item,
	.catalog-products-wrapper .product-card-img-wrapper,
	.catalog-products-wrapper .product-card-main-img,
	.catalog-products-wrapper .pc-img-item {
		height: 110px;
	}

	.catalog-products-wrapper .product-card-wrapper {
		padding: 10px;
	}

	.product-card-main-img {
		border-radius: var(--br-sm);
	}

	.catalog-products-wrapper .btn-product-card span {
		font-size: 12px;
	}

	.catalog-products-wrapper .btn-product-card svg {
		width: 12px;
		height: 12px;
	}

	.catalog-products-wrapper .btn-product-card {
		top: 4%;
	}

	.catalog-products-wrapper .btn-product-card {
		width: 23px;
		height: 23px;
	}

	.catalog-products-wrapper .compare-btn-product-card.compare-add.btn-product-card {
		right: 14px;
	}

	.catalog-products-wrapper .favorite-btn-product-card.favorite-add.btn-product-card {
		right: 43px;
	}

	.catalog-products-wrapper .product-card-name {
		font-size: 16px;
	}

	.catalog-products-wrapper .product-card-subname {
		font-size: 9px;
		margin-top: 0px;
		line-height: 18px;
	}

	.catalog-products-wrapper .product-card-reviews-stars span {
		font-size: 12px;
	}

	.catalog-products-wrapper .product-card-reviews-wr {
		margin-top: 0px;
	}

	.catalog-products-wrapper .product-card-price.sale-red-price {
		font-size: 12px;
		margin-right: 4px;
	}

	.catalogCategoriesSlider .swiper-slide a {
		padding: 5px;
		font-size: 11px;
	}

}

@media (max-width: 550px) {
	.catalog-products-wrapper span.product-card-reviews-total {
		display: none;
	}
}

@media (max-width: 370px) {

	.catalog-products-wrapper .product-card-hover-wr.pch-item,
	.catalog-products-wrapper .product-card-img-wrapper,
	.catalog-products-wrapper .product-card-main-img,
	.catalog-products-wrapper .pc-img-item {
		height: 90px;
	}
}


@media (min-width: 992px) {
	.catalog-products-wrapper .product-card-wrapper:hover .product-card-bottom-block {
		display: block;
	}
}

.product-card-wrapper.promotion-card {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 !important;
}

.promotion-card>a {
	width: inherit;
	height: inherit;
	border-radius: inherit;
	display: block;
}

.promotion-card img {
	width: inherit;
	height: inherit;
	object-fit: contain;
	border-radius: inherit;
}

.catalog-product-item-full .promotion-card img {
	height: 110vw;
}




/* Full width products catalog */
.catalog-product-item-full {
	width: 100%;
	padding: calc(var(--bs-gutter-x) * 0.5) !important;
}

.catalog-product-item-full .product-card-bottom-block {
	display: block;
	box-shadow: none;
	padding: 15px 0 0 0;
	background: #fff0;
	position: static;
}

.catalog-products-wrapper .catalog-product-item-full .product-card-hover-wr.pch-item,
.catalog-products-wrapper .catalog-product-item-full .product-card-img-wrapper,
.catalog-products-wrapper .catalog-product-item-full .product-card-main-img,
.catalog-products-wrapper .catalog-product-item-full .pc-img-item {
	height: 200px;
}

.catalog-products-wrapper .catalog-product-item-full .product-card-name {
	font-size: 20px;
}

.catalog-product-item-full .product-card-wrapper {
	box-shadow: var(--shadow-block);
}

.catalog-products-wrapper .catalog-product-item-full .product-card-subname {
	font-size: 12px;
	margin-top: 2px;
	line-height: 20px;
}

.catalog-products-wrapper .catalog-product-item-full .product-card-reviews-stars span {
	font-size: 14px;
}

.catalog-products-wrapper .catalog-product-item-full .product-card-price.sale-red-price {
	font-size: 18px;
	margin-right: 7px;
}

.catalog-products-wrapper .catalog-product-item-full .product-card-price-old {
	font-size: 12px;
	margin-top: 6px;
}

.catalog-products-wrapper .catalog-product-item-full .product-card-bottom-wrapper {
	margin-top: 2px;
}

.catalog-product-item-full .product-card-promotion {
	font-size: 11px;
	padding: 2px 4px;
}

.catalog-products-wrapper .catalog-product-item-full .btn-product-card {
	width: 28px;
	height: 28px;
}

.catalog-products-wrapper .catalog-product-item-full .btn-product-card span {
	font-size: 15px;
}

.catalog-products-wrapper .catalog-product-item-full .btn-product-card svg {
	width: 15px;
	height: 15px;
}

.catalog-products-wrapper .catalog-product-item-full .favorite-btn-product-card.favorite-add.btn-product-card {
	right: 55px;
}

.catalog-layout-mode-wr {
	display: none
}

.catalog-layout-btn {
	transition: var(--transition-btns);
	width: 37px;
	height: 37px;
}

.catalog-layout-btn.active>rect {
	fill: var(--pink);
	stroke: var(--pink)
}

@media (max-width: 480px) {
	.catalog-layout-mode-wr {
		display: flex;
		gap: 5px;
		margin-top: 15px;
	}
}

/*  catalog filter */
div#catalog_filter_canvas {
	width: 550px;
}

.accordion.catalog-filters-parent {
	display: flex;
	flex-direction: column;
	gap: 32px;
	padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.accordion-item.catalog-filter-item {
	border: none;
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
	position: relative;
	padding-bottom: 40px;
}

.catalog-filter-item-open-close {
	content: "";
	position: absolute;
	bottom: 10px;
	right: 0;
	left: 0;
	margin: 0 auto;
	width: 50%;
	background: var(--light-grey);
	height: 7px;
	border-radius: 30px;
	transition: none;
	cursor: pointer;
}

.accordion-item.catalog-filter-item .accordion-header button {
	background: #fff;
	color: var(--user-color);
	font-weight: 500;
	border: none;
	box-shadow: none;
	border-radius: var(--br-xl);
}

.accordion-item.catalog-filter-item.catalog-filter-price .accordion-header button {
	cursor: default
}

.accordion-item.catalog-filter-item .accordion-header button::after {
	opacity: 0.7;
}

svg.catalog-filter-item-clear path {
	display: block
}

svg.catalog-filter-item-clear {
	display: block;
	position: absolute;
	top: 9px;
	right: 12%;
	flex-shrink: 0;
	width: calc(var(--bs-accordion-btn-icon-width) + 18px);
	height: calc(var(--bs-accordion-btn-icon-width) + 18px);
	margin-left: auto;
	transition: var(--bs-accordion-btn-icon-transition);
	z-index: 50;
	cursor: pointer;
	padding: 6px;
}

svg.catalog-filter-item-clear.hidden,
svg.catalog-filter-item-clear.hidden path {
	display: none !important;
}

svg.catalog-filter-item-clear:hover>path {
	fill: var(--pink)
}

.accordion-item.catalog-filter-item .accordion-body {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.accordion-item.catalog-filter-item .accordion-body label input {
	transform: translateY(1px);
}

.accordion-footer {
	height: 30px;
	position: absolute;
	bottom: 0;
	width: 100%;
	cursor: pointer;
}

.form-check-input.catalog-filter-input {
	border: 1px solid #b1b1b1;
	box-shadow: none;
	cursor: pointer;
}

.form-check-input.catalog-filter-input~label {
	cursor: pointer;
}

.form-check-input.catalog-filter-input:checked {
	background-color: var(--pink);
	border: 1px solid #fff0
}

button.btn-clear-filter {
	background: none;
	border: none;
	padding: 0;
	text-decoration: underline;
	transition: var(--transition-btns);
	color: var(--user-color)
}

div#catalog_filter_canvas .offcanvas-body {
	position: relative;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

}

.filter-canvas-footer {
	box-shadow: var(--shadow-block);
	position: sticky;
	bottom: 0;
	z-index: 15;
	left: 0;
	right: 0;
	margin-top: 35px;
	padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
	background: #fff;
}

button.btn.btn-default.btn-pink.btn-action-filter {
	width: 200px;
	font-weight: 400;
}

/*
catalog filter price slider
*/

.price-filter.list-group-item {
	position: relative;
	display: block;
	-webkit-text-decoration: none;
	text-decoration: none;
	border: none;
	background: #fff0;
	color: var(--user-color);
	padding: 0;
}

.price-filter-number input {
	border-radius: var(--br-sm);
	border: 1px solid #66666687;
	padding: 6px;
	width: 115px;
	line-height: 19px;
}

.price-filter-number input:focus-visible,
.price-filter-number input:focus,
.price-filter-number input:active {
	outline: none;
}

div.slider-multi-range {
	margin-top: 25px;
	border: none;
	box-shadow: none;
	background: #d2d2d2;
	content: "";
	height: 6px;
	transform: translateY(-50%);
	width: 100%;
	border-radius: 30px;
}

div.slider-multi-range .noUi-connect {
	background: #999999;
}

div.slider-multi-range .noUi-handle {
	top: -9px;
	border: none;
	width: 23px;
	height: 23px;
	border-radius: 50%;
	pointer-events: auto;
	background: var(--pink);
	border-color: currentColor;
	cursor: grab;
	transition: box-shadow 0.3s linear;
	box-shadow: 0 0 0 var(--outlineSize) rgba(203, 203, 203, 0.231372549);
}

div.slider-multi-range .noUi-handle::before,
div.slider-multi-range .noUi-handle::after {
	content: none;
}


@media (max-width: 576px) {
	.accordion-item.catalog-filter-item .accordion-header button {
		border-radius: var(--br-lg);
		font-size: 14px;
		padding: 10px 15px;
	}

	.accordion.catalog-filters-parent {
		gap: 25px;
		padding: 0.5rem;
	}

	div#catalog_filter_canvas .offcanvas-body {
		margin-top: 15px;
	}

	svg.catalog-filter-item-clear {
		top: 4px;
		right: 15%;
		width: calc(var(--bs-accordion-btn-icon-width) + 15px);
		height: calc(var(--bs-accordion-btn-icon-width) + 15px);
	}

	.catalog-filter-item-open-close {
		bottom: 6px;
		height: 4px;
	}

	.accordion-item.catalog-filter-item .accordion-body {
		gap: 10px;
		padding: 12px;
		font-size: 12px;
	}

	.multi-range {
		--h: 15px;
		margin-top: 20px;
	}

	.multi-range::before,
	.multi-range::after {
		height: 4px;
	}

	button.btn-clear-filter {
		font-size: 12px;
	}

	button.btn.btn-default.btn-pink.btn-action-filter {
		width: 130px;
		font-size: 12px;
	}
}


/* 

SEARCH PAGE

*/

.search-input-wrapper {
	width: 550px;
	position: relative;
}

.search-input-wrapper input.search__input {
	height: 55px;
}

.search-page-controls-section .custom-select-trigger {
	min-width: 300px;
	font-size: 16px;
	height: 55px;
	font-weight: 400;
	background-color: rgba(44, 54, 56, 0.07);
	color: var(--user-color);
}

.search-page-controls-section .custom-select-trigger:hover {
	background-color: rgba(44, 54, 56, 0.07);
}

.search-page-controls-section .custom-select-trigger:after {
	border-bottom: 1px solid var(--user-color);
	border-right: 1px solid var(--user-color);
}

.search-page-controls-section .custom-option:hover,
.search-page-controls-section .custom-option.selection {
	color: var(--user-color);
	background-color: #cbcbcb66;
}

.search-page-controls-section .custom-options {
	height: 400px;
	overflow-y: auto;
}

.search-page-controls-section .form-check {
	margin-left: 6px;
	margin-top: 5px;
}

.search-page-controls-section .form-check-input {
	width: 15px;
	height: 15px;
	border: var(--bs-border-width) solid #8a8a8a;
}

.search-page-controls-section .form-check label {
	font-size: 15px;
	cursor: pointer;
}

#product-search .catalog-products-wrapper {
	margin-top: 0;
}

@media (max-width: 1200px) {
	#product-search input.search__input {
		font-size: 14px;
	}
}

@media (max-width: 992px) {

	.search-page-search-wrapper,
	.search-input-wrapper,
	.search-page-categories-wrapper,
	.search-page-categories-wrapper .custom-select,
	.search-page-categories-wrapper .custom-select-wrapper {
		width: 100%;
	}

	.search-page-controls-section .custom-select-trigger {
		min-width: 100%;
		font-size: 14px;

	}

	.search-page-controls-section .custom-option {
		padding: 0 20px;
		line-height: 32px;
	}

	.search-page-controls-section .custom-options {
		height: 250px;
	}

	.search-page-controls-section .form-check {
		margin-left: 6px;
		margin-top: 12px;
		margin-bottom: 0;
	}

	.search-page-controls-section .form-check-input {
		width: 12px;
		height: 12px;
		margin-top: 6px;
	}

	.search-page-controls-section .form-check label {
		font-size: 12px;
		margin-left: -5px;
	}

	#product-search .catalog-layout-mode-wr {
		margin-top: 0;
	}
}



/* 

PRODUCT PAGE CARD

*/

#product-product {
	--pr-card-slider-h: 500px;
	--shadow-block-filter: drop-shadow(0px 5px 40px rgba(0, 0, 0, 0.12));
}

.product-top-blocks,
.product-bottom-blocks,
.product-blocks-container {
	width: 100%;
	padding: 0;
	display: flex;
	gap: 1.5em;
	flex-direction: column;
}


.product-slider-wr {
	display: flex;
	gap: 22px;
}

.swiper.productCardSlider {
	height: var(--pr-card-slider-h);
	position: relative;
	filter: var(--shadow-block-filter);
	border-radius: var(--br-xxl);
	width: 100%;
}

.swiper.productCardSlider .swiper-slide img {
	width: 100% !important;
	height: 100%;
	object-fit: cover;
	border-radius: inherit;
	object-position: 50% 80%;
	background-color: #fff
}

.swiper.productCardSlider>.swiper-wrapper>.swiper-slide {
	width: 100% !important;
}

.swiper.productCardSlideButtons {
	position: absolute;
	bottom: 6px;
	left: 8px;
	width: 95%;
	z-index: 10;
	padding: 17px 0;
}

.swiper.productCardSlideButtons .swiper-wrapper {
	width: inherit;
}

.swiper.productCardSlideButtons .swiper-slide {
	width: max-content !important;
	pointer-events: visible
}

.btn.btn-default.btn-product-card-slider {
	width: max-content;
	background: #fff;
	height: 47px;
	border-radius: var(--br-xl);
	color: var(--user-color);
	font-weight: 400;
	font-size: 13px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	gap: 7px;
	box-shadow: 0px 1px 27px rgba(0, 0, 0, 0.1);
	padding: 5px 15px;
	transition: var(--transition-btns);
}

.btn.btn-default.btn-product-card-slider:hover {
	transform: translateY(-5px);
}

.btn.btn-default.btn-product-card-slider>span:first-child {
	font-size: 27px;
}

.swiper.productCardSliderThumbs {
	width: 100%;
	height: inherit;
	padding: 5px 11px;
}

.swiper.productCardSliderThumbs .swiper-slide img {
	width: 100px;
	height: inherit;
	-o-object-fit: cover;
	object-fit: cover;
}

.swiper.productCardSliderThumbs .swiper-slide {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: var(--br-xl);
	box-shadow: var(--shadow-minimal);
	height: 65px;
	transition: all .5s ease;
	border: 3px solid #fff0;
	cursor: pointer
}

.swiper.productCardSliderThumbs .swiper-slide.swiper-slide-thumb-active {
	border: 3px solid var(--light-grey);
}

.swiper-button-next.product-main-slider-arrows {
	top: 100%;
	transform: rotate(90deg);
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 7;
}

.swiper-button-prev.product-main-slider-arrows {
	top: -7%;
	transform: rotate(90deg);
	left: 0;
	right: 0;
	margin: 0 auto;
	z-index: 5
}

.product-main-slider-arrows::after {
	color: var(--user-color);
}

.product-main-slider-arrows {
	--swiper-navigation-size: 32px;
}

.product-slider-thumbs-wr {
	position: relative;
	height: calc(var(--pr-card-slider-h) - 71px);
	margin: auto;
}

.swiper.productCardSliderThumbs .swiper-slide img.product-slide-scheme,
.swiper.productCardSlider .swiper-slide img.product-slide-scheme {
	object-fit: contain;
	object-position: center center;
}

/* Video */

.product-video-wrapper {
	position: relative;
}

video.product-video {
	width: 100%;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	border: none;
	height: var(--pr-card-slider-h);
	object-fit: cover;
}

button.product-video-close {
	border: none;
	background: #fff;
	position: absolute;
	top: 3%;
	right: 2%;
	box-shadow: var(--shadow-block-hover);
	border-radius: 100%;
	padding: 6px;
	line-height: 0;
	font-size: 21px;
	z-index: 5;
	transition: var(--transition-btns);
}

button.product-video-close span {
	color: var(--dark-grey)
}

button.product-video-close:hover {
	transform: rotate(90deg);
}

.swiper-pagination.product-slider-pag {
	position: static;
	margin-top: 5px;
	display: none
}

.swiper-pagination.product-slider-pag .swiper-pagination-bullet {
	width: 7px;
	height: 7px;
	border-radius: 100%;
	opacity: 1;
	background: var(--light-grey);
	transition: all 0.5s ease;
}

.swiper-pagination.product-slider-pag .swiper-pagination-bullet-active {
	width: 33px;
	border-radius: 50px;
	background: var(--dark-grey);
	opacity: 1;
}


@media (max-width: 1600px) {
	:root {
		--pr-card-slider-h: 450px;
	}

	.swiper.productCardSliderThumbs .swiper-slide img {
		width: 80px;
	}

	.btn.btn-default.btn-product-card-slider {
		height: 43px;
		font-size: 12px;
		gap: 5px;
		padding: 5px 12px;
		border-radius: var(--br-lg)
	}

	.swiper-button-prev.product-main-slider-arrows {
		top: -9%;
	}

	.product-slider-wr {
		gap: 15px;
	}

	.product-main-slider-arrows {
		--swiper-navigation-size: 26px;
	}
}

@media (max-width: 1400px) {
	:root {
		--pr-card-slider-h: 350px;
	}

	.swiper.productCardSliderThumbs .swiper-slide img {
		width: 60px;
	}

	.swiper.productCardSliderThumbs .swiper-slide {
		border-radius: var(--br-lg);
	}

	.product-slider-thumbs-wr {
		height: calc(var(--pr-card-slider-h) - 30px);
	}

	.btn.btn-default.btn-product-card-slider>span:first-child {
		font-size: 23px;
	}

	.btn.btn-default.btn-product-card-slider {
		height: 39px;
		font-size: 10px;
		gap: 5px;
		padding: 5px 10px;
	}

	.btn.btn-default.btn-product-card-slider {
		box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.1);
	}

	.swiper.productCardSlideButtons {
		padding: 17px 20px 8px 0;
	}
}

@media (max-width: 1200px) {
	.swiper.productCardSliderThumbs .swiper-slide img {
		width: 50px;
	}

	.product-slider-wr {
		gap: 5px;
	}

	.swiper.productCardSliderThumbs .swiper-slide {
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
	}

	.swiper.productCardSliderThumbs {
		padding: 5px;
	}

	.product-top-blocks,
	.product-bottom-blocks {
		grid-template-columns: 26% 26% 43% !important;
	}

	#product-product {
		--pr-card-slider-h: 340px;
	}
}


@media (max-width: 992px) {
	.product-info-block {
		order: 3;
	}

	.product-aside-block {
		order: 2;
	}

	.product-slider-block {
		order: 1;
	}

	.swiper-pagination.product-slider-pag {
		display: block
	}

	.product-top-blocks,
	.product-bottom-blocks,
	.product-blocks-container {
		gap: 1em;
	}

	#product-product {
		--shadow-block-filter: drop-shadow(0px 5px 22px rgba(0, 0, 0, 0.12));
	}

	.product-slider-thumbs-wr {
		display: none
	}

	.product-slider-wr {
		display: block
	}

}

@media (max-width: 768px) {
	#product-product {
		--pr-card-slider-h: 350px;
	}
}

@media (max-width: 576px) {
	.swiper.productCardSlider .swiper-slide img {
		height: 100%;
		object-position: 50% 100%;
	}
}

@media (max-width: 480px) {
	#product-product {
		--pr-card-slider-h: 240px;
	}
}

@media (max-width: 375px) {
	#product-product {
		--pr-card-slider-h: 200px;
	}
}




@media (min-width: 992px) {
	.product-top-blocks {
		display: grid;
		grid-template-columns: 29% 29% 38.7%;
		grid-gap: 1.5em 1.5em;
		gap: 1.5em 1.5em;
		grid-auto-flow: row dense;
		grid-template-areas:
			"product-slider-block product-slider-block product-aside-block"
			"product-info-block product-info-block product-aside-block"
			"product-info-block product-info-block product-aside-block";
		grid-template-rows: max-content;
		position: relative
	}

	.product-bottom-blocks {
		display: grid;
		grid-template-columns: 29% 29% 38.7%;
		grid-gap: 1.5em 1.5em;
		gap: 1.5em 1.5em;
		grid-auto-flow: row dense;
		grid-template-areas:
			"product-reviews-block product-reviews-block product-send-review-block"
			"product-reviews-block product-reviews-block product-send-review-block"
			"product-reviews-block product-reviews-block product-send-review-block";
		grid-template-rows: max-content;
		position: relative
	}

	.product-info-block {
		grid-area: product-info-block;
		height: max-content
	}

	.product-aside-block {
		grid-area: product-aside-block;
		height: max-content;
		position: sticky;
		top: 15px
	}

	.product-slider-block {
		grid-area: product-slider-block;
		height: max-content
	}

	.product-bottom-blocks {
		grid-area: product-reviews-block;
		height: max-content
	}

	.product-reviews-block {
		grid-area: product-reviews-block;
		height: max-content
	}

	.product-send-review-block {
		grid-area: product-send-review-block;
		height: max-content;
		position: sticky;
		top: 15px
	}
}

.product-send-empty-reviews-block {
	width: 100%;
	margin: 0 auto;
	height: max-content;
	padding: 45px 30px;
}

.product-send-empty-reviews-block button {
	width: 250px;
}

.product-send-empty-reviews-block button {
	width: 350px;
	height: 45px;
}

.product-send-empty-reviews-block .compare-empty-text {
	margin-bottom: 30px;
}

.product-send-empty-reviews-block .compare-empty-title {
	margin-bottom: 15px;
	font-size: 35px;
}

@media (max-width: 768px) {
	.product-send-empty-reviews-block .compare-empty-title {
		margin-bottom: 10px;
		font-size: 26px;
	}

	.product-send-empty-reviews-block .compare-empty-text {
		margin-bottom: 20px;
		font-size: 16px;
		line-height: 22px;
	}

	.product-send-empty-reviews-block button {
		height: 45px;
		width: 100%;
	}

	.product-send-empty-reviews-block {
		padding: 25px 20px;
	}
}


/* Right block */

.product-option-wr.form-check.option_bed_sizes {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.product-sales-popover-window {
	box-shadow: var(--shadow-mini-block);
	--bs-popover-border-color: rgba(0, 0, 0, 0.05882);
	border-radius: var(--br-xl);
	--bs-popover-max-width: 300px;
}

.product-sales-popover-window .popover-body {
	padding: 12px;
}

.product-right-block {
	background: #fff;
	filter: var(--shadow-block-filter);
	border-radius: var(--br-xxl);
	padding: 15px 25px 30px 25px;
	position: relative;
}

.product-right-block-top-buttons {
	position: absolute;
	top: 2%;
	right: 0%;
}

@media (max-width: 480px) {
	.product-right-block-top-buttons {
		right: calc(100% - 100px);
		top: 1%;
	}
}

.product-title-wrapper h2 {
	font-size: 38px;
	font-weight: 700;
	line-height: normal;
	margin: 0;
}

.product-title-wrapper {
	position: relative;
	margin-top: 10px;
}

button.product-sales-popover {
	position: absolute;
	top: -11px;
	right: -29px;
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	font-size: 30px;
	color: var(--red);
}

.product-model {
	margin-top: 27px;
	margin-left: 4px;
	/* text-transform: lowercase; */
}

.product-right-block-reviews {
	display: flex;
	align-items: center;
	margin-top: 10px;
	transition: all .5s ease-in-out;
}

.product-right-block-reviews:hover {
	text-decoration: underline;
}

.product-reviews-stars>span {
	font-size: 24px;
	color: var(--orange);
}

span.product-reviews-total {
	margin-top: 3px;
	margin-left: 3px;
	font-size: 14px;
}

.product-price-wr {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	gap: 5px;
	margin-top: 10px;
}

.product-price.product-price-text {
	line-height: normal;
	font-size: 20px;
	margin-right: 2px;
	font-family: "Roboto+Condensed", sans-serif;
	font-weight: 700;
}

.product-price-old.product-price-old-text {
	color: rgba(44, 54, 56, 0.6);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	-webkit-text-decoration: line-through;
	text-decoration: line-through;
	margin-top: 0px;
}

.product-sale.product-sale-text {
	background: var(--red);
	font-size: 13px;
	display: block;
	padding: 0px 0 0 2px;
	width: 37px;
	height: 37px;
	line-height: 1;
	border-radius: 100%;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-to-buy-info {
	border-radius: var(--br-lg);
	background: rgba(231, 228, 223, 0.49);
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	padding: 7px 15px;
	margin-top: 10px;
}

.product-to-buy-info a {
	text-decoration: underline;
}

.product-to-buy-info.product-module-system span {
	font-weight: 500;
	line-height: inherit;
}

@media (max-width: 576px) {
	.product-to-buy-info.product-module-system span {
		font-size: 14px;
	}
}

.product-to-buy-info.product-module-system {
	font-size: 12px;
}

.product-block-line {
	width: 100%;
	height: 1px;
	background: rgba(0, 0, 0, 0.16);
	margin: 23px 0;
}

.product-subtitle {
	font-size: 18px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 12px;
}

.product-clothes-btns-wr {
	display: flex;
	align-items: center;
	gap: 12px;
}

.product-clothes-btns-wr a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 115px;
	height: 60px;
	border-radius: var(--br-xl);
	padding: 4px;
	transition: all .5s ease-in-out;
	font-weight: 500;
	flex-wrap: wrap;
	background: #fff;
	box-shadow: var(--shadow-mini-block);
	border: 2px solid var(--light-grey);
}


.product-clothes-btns-wr a:hover {
	transform: translateY(-3px);
	border: 2px solid var(--pink);
}

.option_text_img.product-option-wr {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	gap: 10px;
	row-gap: 20px;
	width: 100%;
	margin-bottom: 30px;
	flex-wrap: wrap;
	padding-left: 0;
	justify-content: space-between;
}

.product-clothes-segments-wrapper>div:nth-child(1) .product-clothes-btns-item-wr .product-clothes-btns-item-name {
	background: var(--white);
}

.product-clothes-segments-wrapper>div:nth-child(2) .product-clothes-btns-item-wr .product-clothes-btns-item-name {
	background: var(--brown);
}

.product-clothes-segments-wrapper>div:nth-child(3) .product-clothes-btns-item-wr .product-clothes-btns-item-name {
	background: var(--pink);
}

.product-clothes-btns-item-images {
	width: 307px;
}

.product-clothes-btns-item-name {
	background: var(--white);
	border-radius: var(--br-lg);
	padding: 10px;
	cursor: pointer;
}

.product-clothes-segment-title {
	font-size: 14px;
	font-weight: 500;
}

.product-clothes-btns-item-name>div:first-child {
	font-size: 20px;
	font-weight: 400;
}

.product-clothes-btn-total {
	font-size: 12px;
	font-weight: 500;
}

span.icon-arrow-simple {
	display: block;
	position: relative;
	margin-top: -4px;
}

span.icon-arrow-simple::after {
	padding: 5px;
}

.product-option-wr.option_text_img.option_modules {
	height: 270px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 13px 10px;
	background: #fff;
	box-shadow: var(--shadow-block);
	padding: 12px;
	border-radius: var(--br-xl);
}

.product-option-additional-text-wr {
	background: hsla(38, 14%, 89%, .49);
	border-radius: var(--br-lg);
	font-size: 12px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 10px;
	padding: 7px 15px;
	margin-bottom: 20px;
}

.product-option-wr.form-check.option_text_img .product-option {
	display: none;
}

.option_text_img .product-option-value-wr {
	border-radius: var(--br-xl);
	background: #fff;
	/* 	filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.12)); */
	width: 48%;
	flex: 0 1 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 3px 10px;
	font-size: 13px;
	text-align: center;
	cursor: pointer;
	transition: all .5s ease-in-out;
	z-index: 20;
	padding-top: 7px;
	border: 2px solid var(--white);
	position: relative;
}

.option_text_img .product-option-value-wr img {
	width: auto;
	object-fit: contain;
	height: 35px;
}

.option_text_img.option_legs .product-option-value-wr img {
	height: 70px;
}


.option_text_img .product-option-value-wr.reverse img {
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.option_text_img .product-option-value-wr.active {
	/*   background: #0000000f; */
	/*   box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08); */
	background: var(--white);
}

.product-option-wr.form-check.option_text_img.option_modules .product-option-value-wr img {
	height: 70px;
	transform: none;
}

.option_text_img .product-option-value-wr:hover {
	transform: translateY(-3px);
}

.product-option-wr.form-check.option_switch_clothes .product-option-value-wr {
	cursor: pointer
}

.product-option-wr.form-check.option_switch_clothes .product-option-value-wr img {
	width: 60px;
	height: 60px;
	border-radius: 100%;
	transition: all 0.5s ease;
	border: 3px solid #fff0;
}

.product-option-wr.form-check.option_switch_clothes .product-option-value-wr input {
	display: none;
}

.product-option-wr.form-check.option_switch_clothes {
	display: flex;
	gap: 30px;
	padding: 0;
	margin-top: 15px;
}

.product-option-wr.form-check.option_switch_clothes .product-option-value-wr>div.d-flex.flex-column {
	justify-content: center;
	align-items: center;
}

.product-option-wr.form-check.option_switch_clothes .product-option-value-wr.active img {
	border-color: var(--user-color);
}

.product-option-wr.form-check.option_switch_clothes .product-option-value-wr span {
	font-size: 14px;
}

.option_modules.option_text_img .product-option-value-wr.active .badge {
	--bs-badge-font-size: 0.9em;
	padding: 0.8em 0.6em;
	background: var(--pink);
	font-weight: 400;
	transform: translate(-65%, -30%) !important;
	width: 30px;
	height: 30px;
}

.option_modules.option_text_img .product-option-value-wr .product-option-quantity {
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	justify-content: center;
	background: #00000012;
	border-top-left-radius: inherit;
	border-bottom-left-radius: inherit;
	transition: all .6s ease-in-out;
	opacity: 0;
	visibility: collapse;
}



.option_modules.option_text_img .product-option-value-wr.active:hover .product-option-quantity {
	visibility: visible;
	opacity: 1;
}

.product-option-quantity .product-option-quantity-plus {
	border-bottom: 1px solid #0000004d;
}

.product-option-quantity button {
	background: none;
	border: none;
	height: 50%;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	font-size: 30px;
	font-weight: 400;
	color: var(--user-color);
	z-index: 10;
}

.product-config-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	margin-bottom: 4px;
}

.product-config-item.product-cloth-config-item input {
	border: none;
	background: none;
	min-width: 10ch;
	opacity: 1;
}

.product-config-item>div {
	font-weight: 500;
}

/* .product-config-item-content.product-config-value {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
	width: 320px;
	transition: all .6s ease-in-out;
	text-align: end;
	cursor: pointer;
}

.product-config-item-content.product-config-value.full-width {
	display: block;
	overflow: visible;
}

.product-config-item-content.product-config-value:hover {
	color: var(--pink);
} */

a.btn.btn-default.product-link-configurator {
	background: var(--white);
	color: var(--user-color);
	font-size: 14px;
	font-weight: 500;
	padding: 10px 20px;
	margin-top: 22px;
}

button.add_cart,
.buy_one_click {
	height: 45px;
	font-weight: 400;
}

.product-clothes-btns-wr .product-clothes-btns-item-images a {
	border: none;
	padding: 0;
	margin: 0;
	background: none;
	box-shadow: none;
	display: block;
	width: min-content;
	height: max-content;
}

.product-clothes-btns-item-images img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
}

@media (max-width: 1400px) {
	.product-title-wrapper h1 {
		font-size: 32px;
	}

	.product-model {
		margin-top: 24px;
		font-size: 14px;
	}

	.product-config-wr {
		font-size: 14px;
	}

	.product-config-item {
		margin-bottom: 2px;
	}

	a.btn.btn-default.product-link-configurator {
		font-size: 12px;
		padding: 10px 9px;
	}

	.product-clothes-btns-item-name>div:first-child {
		font-size: 16px;
	}

	.product-clothes-btn-total {
		font-size: 10px;
	}

	.product-clothes-btns-wr {
		gap: 8px
	}
}

@media (max-width: 1200px) {
	.product-clothes-btns-wr a {
		height: 48px;
		padding: 4px 5px;
		font-size: 12px;
		background-position: 50% 55% !important;
	}

	.option_text_img .product-option-value-wr {
		padding: 3px 10px;
		font-size: 12px;
		line-height: 18px;
	}

	button.add_cart,
	.buy_one_click {
		height: 38px;
		font-size: 12px;
	}
}

@media (max-width: 992px) {
	.product-option-wr.form-check.option_switch_clothes {
		gap: 25px;
	}

	.product-option-wr.form-check.option_switch_clothes .product-option-value-wr img {
		width: 55px;
		height: 55px;
	}

	.product-option-wr.form-check.option_switch_clothes .product-option-value-wr span {
		font-size: 12px;
	}

	.option_modules.option_text_img .product-option-value-wr.active .product-option-quantity {
		opacity: 1;
		visibility: visible;
	}
}

@media (max-width: 576px) {
	.option_text_img.option_legs .product-option-value-wr img {
		height: 40px;
	}

	.product-right-block {
		padding: 15px 25px 30px 15px;
	}

	.option_text_img .product-option-value-wr.active .badge {
		--bs-badge-font-size: 0.8em;
	}

	.product-option-quantity button {
		padding-left: 6px;
		padding-right: 6px;
		font-size: 19px;
	}

	.product-option-wr.form-check.option_text_img.option_modules .product-option-value-wr img {
		height: 45px;
	}

	.option_modules.option_text_img .product-option-value-wr.active .badge {
		--bs-badge-font-size: 0.8em;
		padding: 0.7em 0.6em;
		width: 24px;
		height: 24px;
	}

	.product-option-wr.option_text_img.option_modules {
		padding: 5px;
		border-radius: var(--br-lg);
		padding-top: 15px;
		padding-right: 8px;
	}
}

@media (max-width: 480px) {
	.btn.btn-default.btn-product-card-slider {
		height: 33px;
		font-size: 9px;
		gap: 3px;
		padding: 5px 9px;
	}

	.swiper.productCardSlideButtons {
		bottom: 2px;
		left: 3px;
		padding: 10px 20px 8px 0;
	}

	.product-title-wrapper h1 {
		font-size: 26px;
	}

	.product-model {
		margin-top: 20px;
		font-size: 12px;
		margin-left: 0;
		margin-top: 5px;
		width: 100%;
	}

	button.product-sales-popover {
		top: -15px;
		right: -29px;
		font-size: 28px;
	}

	.btn-product-card span {
		font-size: 15px;
	}

	.btn-product-card svg {
		width: 15px;
		height: 15px;
	}

	.compare-btn-product-card.compare-add.btn-product-card {
		right: 30px;
	}

	.favorite-btn-product-card.favorite-add.btn-product-card {
		right: 65px;
	}

	.btn-product-card {
		width: 25px;
		height: 25px;
	}

	.product-reviews-stars>span {
		font-size: 16px;
	}

	.product-reviews-stars>span {
		font-size: 16px;
		color: var(--orange);
	}

	span.product-reviews-total {
		margin-top: 2px;
		margin-left: 3px;
		font-size: 12px;
	}

	.product-price.product-price-text {
		font-size: 19px;
		font-weight: 500;
	}

	.product-price-old.product-price-old-text {
		font-size: 14px;
	}

	.product-sale.product-sale-text {
		font-size: 11px;
		width: 32px;
		height: 32px;
	}

	.product-to-buy-info {
		font-size: 10px;
		line-height: 15px;
	}

	.product-subtitle {
		font-size: 16px;
	}

	.product-clothes-btns-wr a {
		height: 45px;
		padding: 4px 8px;
		font-size: 10px;
		border-radius: var(--br-sm);
	}

	.product-config-item {
		margin-bottom: 0px;
	}

	.product-config-wr {
		font-size: 12px;
	}

	a.btn.btn-default.product-link-configurator {
		font-size: 10px;
	}

	.product-right-block-reviews {
		margin-top: 5px;
	}

}


/* Product info block */

.product-info-wr {
	background: #fff;
	filter: var(--shadow-block-filter);
	margin-top: 0.5rem;
	border-radius: var(--br-xxl);
	padding: 30px 25px 40px 25px;
}

.nav.nav-pills.product-navigation-info {
	display: flex;
	align-items: center;
	gap: 10px;
}

.nav.nav-pills.product-navigation-info .nav-item button {
	--bs-nav-link-padding-x: 0.4rem;
	--bs-nav-link-padding-y: 0.4rem;
	background: none;
	color: var(--user-color);
	font-weight: 500;
	font-size: 16px;
	position: relative;
	padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
}

.nav.nav-pills.product-navigation-info .nav-item:hover>button::after,
.nav.nav-pills.product-navigation-info .nav-item>button.active::after {
	width: 90%;
}

.product-content-info-text-wr * {
	font-size: 16px !important;
	font-weight: 400 !important;
	font-family: 'Roboto' !important;
}

.product-content-info {
	margin-top: 35px;
}

.product-tab-about-advantages-wr {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xxl);
	padding: 15px 20px;
}

.product-tab-about-advantages-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 25%;
}

.product-tab-about-advantages-item>span:first-child {
	font-size: 34px;
	position: relative;
	z-index: 2;
}

.product-tab-about-advantages-item>span:first-child::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: block;
	margin: 0 auto;
	background: var(--white);
	width: 98%;
	height: 98%;
	z-index: -1;
	border-radius: 100%;
	margin: 4px 0px 0px -2px;
}

.product-tab-about-advantages-item>span:last-child {
	font-size: 14px;
	line-height: 17px;
	margin-top: 6px;
	display: block;
	height: 35px;
	padding-top: 5px;
}

button.product-tab-about-link-characteristic {
	margin-top: 22px;
	background: none;
	border: none;
	padding: 0;
	text-decoration: underline;
	color: var(--user-color);
}

.nav.nav-pills.product-navigation-info .nav-item button>span {
	text-transform: lowercase;
}

.product-sale-promotions-title {
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 10px;
}

.product-sale-promotion-items {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 10px
}

.product-sale-promotion-item {
	display: block;
	width: 100%;
	font-size: 14px;
	background: #fff;
	padding: 6px;
	border-radius: var(--br-sm);
	line-height: 18px;
	text-align: center;
	box-shadow: var(--shadow-minimal);
	transition: var(--transition-btns);
}

.product-sale-promotion-item:hover {
	background: var(--white);
}

img.product-content-info-scheme {
	width: 100%;
	/*height: 250px;*/
	object-fit: cover;
	margin: 5px 0 25px 0;
}



.product-content-info-attributes {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.product-content-info-attributes>div:not(:last-child) {
	border-bottom: 1px solid var(--light-grey);
	padding-bottom: 15px;
}

.product-content-info-attribute-wr {
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-content-info-attribute-wr span {
	font-weight: 400;
}

.product-content-info-attribute-title {
	font-size: 18px;
}

.product-content-info-attribute-size-wr {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.product-content-info-attribute-size-name {
	font-weight: 500;
	width: 100%;
	margin-bottom: 5px;
	font-size: 18px;
}

.product-content-info-attribute-size-text {
	margin-right: 15px
}

.product-content-info-attribute-size-wr table {
	width: 100%;
	border-collapse: collapse;
}

.product-content-info-attribute-size-wr table th,
.product-content-info-attribute-size-wr table td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: center;
}

.product-content-info-attribute-size-wr table th {
	background-color: #f2f2f2;
}

.product-content-info-attribute-size-text>span:first-child {
	font-weight: 500;
	border-right: 1px solid var(--dark-grey);
	padding-right: 10px;
}

.product-content-info-attribute-size-text>span:last-child {
	padding-left: 5px;
}

.nav.nav-pills.product-navigation-info .nav-item button>.icon-arrow {
	display: none;
	margin-left: auto;
	padding: 0;
	transition: var(--about-video-btn-size);
}

.nav.nav-pills.product-navigation-info .nav-item button>.icon-arrow::before {
	display: inline-block;
	transform: rotate(270deg);
	transition: var(--about-video-btn-size);
}

@media (max-width: 1600px) {
	.nav.nav-pills.product-navigation-info .nav-item button {
		--bs-nav-link-padding-x: 0.3rem;
		--bs-nav-link-padding-y: 0.3rem;
		font-size: 13px;
	}

	.nav.nav-pills.product-navigation-info {
		gap: 5px;
	}
}

@media (max-width: 1200px) {
	img.product-content-info-scheme {
		/*height: 135px;*/
		object-fit: contain;
	}

	.nav.nav-pills.product-navigation-info {
		align-items: start;
		gap: 7px;
		flex-direction: column;
	}

	.nav.nav-pills.product-navigation-info>li,
	.nav.nav-pills.product-navigation-info>li button {
		width: 100%;
		text-align: start;
	}

	.nav.nav-pills.product-navigation-info .nav-item button>span {
		display: inline;
		padding: 3px;
	}

	.nav.nav-pills.product-navigation-info .nav-item button>.icon-arrow {
		display: inline-block;
	}

	.nav.nav-pills.product-navigation-info .nav-item button {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.nav.nav-pills.product-navigation-info>li {
		border-bottom: 1px solid var(--light-grey);
		padding-bottom: 3px;
	}

	.nav.nav-pills.product-navigation-info .nav-item button.active {
		color: var(--pink);
	}

	.nav.nav-pills.product-navigation-info .nav-item button.active>.icon-arrow::before {
		transform: rotate(90deg);
	}
}

@media (max-width: 992px) {

	.product-main-cart-buttons-wr {
		position: fixed;
		bottom: 58px;
		width: 100vw;
		left: 0;
		right: 0;
		flex-wrap: wrap-reverse;
		gap: 5px !important;
		z-index: 994;
	}

	button.add_cart {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		font-size: 15px;
		height: 40px;
		font-weight: 500
	}

	.buy_one_click {
		border: none;
		padding: 0;
		margin: 0;
		display: block;
		text-align: center;
		font-size: 13px;
		font-weight: 400;
		background: #fff;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		transform: translateX(5px);
		width: max-content;
		margin-left: auto;
		padding: 0 13px;
		height: 32px;
		box-shadow: var(--shadow-mini-block);
	}

	.buy_one_click:hover {
		background: #fff !important;
		border: none !important;
		color: var(--user-color) !important
	}
}

@media (max-width: 768px) {

	.product-info-wr,
	.product-right-block,
	.swiper.productCardSlider,
	video.product-video,
	.product-right-block {
		border-radius: var(--br-xl) !important;
	}

	.nav.nav-pills.product-navigation-info .nav-item button {
		font-size: 16px;
		padding: 0.5rem 0.2rem
	}

	.product-content-info-attributes>div:not(:last-child) {
		padding-bottom: 5px;
	}

	.product-content-info-attribute-title {
		font-size: 15px;
	}

	.product-content-info-attributes {
		gap: 10px;
	}

	.product-content-info-attribute-size-name {
		margin-bottom: 2px;
		font-size: 15px;
	}

	.product-content-info-attribute-size-text>span:first-child {
		padding-right: 8px;
		font-size: 14px;
	}

	.product-content-info-attribute-size-text>span:last-child {
		padding-left: 4px;
		font-size: 14px;
	}

	.product-content-info-attribute-wr span,
	.product-content-info-attribute-size-wr table th,
	.product-content-info-attribute-size-wr table td,
	.product-content-info-attribute-size-wr table th {
		font-weight: 400;
		font-size: 14px;
	}

	.product-tab-about-advantages-item>span:last-child {
		font-size: 12px;
		line-height: 16px;
	}

	.product-tab-about-advantages-item>span:first-child {
		font-size: 30px;
	}

	.product-tab-about-advantages-wr {
		gap: 20px;
		border-radius: var(--br-xl);
		padding: 15px;
	}
}

@media (max-width: 576px) {
	.product-info-wr {
		padding: 15px 25px 30px 15px;
	}
}

@media (max-width: 480px) {
	.nav.nav-pills.product-navigation-info .nav-item button {
		font-size: 14px;
	}

	.nav.nav-pills.product-navigation-info {
		gap: 2px;
	}

	.product-content-info {
		margin-top: 20px;
	}

	.product-content-info-text-wr * {
		font-size: 14px !important;
		line-height: 23px !important;
	}

	img.product-content-info-scheme {
		height: auto;
		object-fit: contain;
	}

	.product-tab-about-advantages-item {
		width: 44%;
	}

	.product-tab-about-advantages-wr {
		flex-wrap: wrap;
		justify-content: center;
	}

	button.product-tab-about-link-characteristic {
		font-size: 14px;
		line-height: 22px;
	}

	.product-content-info-attribute-size-text {
		width: 100%;
	}
}


@media (min-width: 1200px) {
	.nav.nav-pills.product-navigation-info .nav-item button::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: block;
		width: 0;
		height: 2px;
		border-radius: 30px;
		background: var(--pink);
		text-align: center;
		margin: 0 auto;
		transition: all .5s ease;
	}
}


/* Clothes canvas */

div#product_clothes_canvas {
	width: 750px;
}

.accordion-item.product-clothes-item.canvas-custom-accordion-item .accordion-button::after {
	display: none;
}

.accordion-item.product-clothes-item.canvas-custom-accordion-item .accordion-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 15px 20px 15px;
}

.accordion-item.product-clothes-item.canvas-custom-accordion-item .accordion-header>button {
	width: max-content;
	font-weight: 500;
	font-size: 22px;
	padding: 0;
}

.product-cloth-category-price>span:first-child {
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.product-cloth-category-price>span:last-child {
	text-decoration: line-through;
	color: rgba(44, 54, 56, 0.60);
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
}

.product-clothes-tab .nav-link>img {
	width: 55px !important;
	height: 55px;
	border-radius: 100%;
	border: 3px solid #fff0;
	transition: all .5s ease
}

.product-clothes-tab .nav-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 8px;
	font-size: 14px;
	align-items: center;
	color: var(--user-color);
	text-transform: uppercase;
	letter-spacing: 0.4px;
	margin: auto;
	transition: all .5s ease;
	position: relative
}

.product-clothes-tab .nav-link:hover>img {
	transform: translateY(-5px)
}

.product-clothes-tab .nav-link.active>img {
	transform: translateY(-5px)
}

.product-clothes-tab .nav-item {
	width: 90px;
	height: max-content;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-clothes-tab {
	justify-content: flex-start;
	gap: inherit
}

.product-clothes-tab .nav-link.active {
	background: none;
	color: var(--user-color);
}

.product-clothes-tab .nav-link.active>img {
	border: 3px solid var(--pink);
}

.product-clothes-canvas .offcanvas-body {
	overflow-y: scroll
}

.product-clothes-parent .tab-content .tab-pane {
	gap: 15px;
}

.product-clothes-parent .tab-content>.active {
	display: flex;
}

div.cloth-img-item>img {
	width: 45px !important;
	height: 45px;
	border-radius: 100%;
	border: 2px solid #fff0;
}

div.cloth-img-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2px;
	font-size: 14px;
	text-transform: capitalize;
	text-align: center;
	cursor: pointer;
	transition: all .5s ease;
	width: 70px;
	height: max-content;
	position: relative
}

.cloth-img-item-full-size::after {
	content: "";
	display: block;
	width: inherit;
	height: inherit;
	background-image: url("data:image/svg+xml,%3Csvg width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M15.605 9.28785C14.7611 8.44397 13.6391 7.97921 12.4457 7.97925C11.9169 7.97925 11.4883 8.40789 11.4883 8.93662C11.4884 9.4654 11.9169 9.894 12.4457 9.894C13.1277 9.894 13.7688 10.1595 14.251 10.6418C14.7333 11.124 14.9988 11.7651 14.9988 12.4471C14.9987 12.9758 15.4274 13.4045 15.9561 13.4045C15.9562 13.4045 15.9562 13.4045 15.9562 13.4045C16.4848 13.4045 16.9135 12.9759 16.9136 12.4472C16.9136 11.2538 16.4489 10.1317 15.605 9.28785Z%27 fill=%27%232C3638%27/%3E%3Cpath d=%27M25.2503 23.8967L18.7225 17.3689C19.7888 16.0123 20.4255 14.3027 20.4255 12.4473C20.4254 8.0481 16.8464 4.46899 12.4471 4.46899C12.4469 4.46899 12.4471 4.46899 12.4469 4.46899C10.3159 4.46899 8.31232 5.29893 6.80556 6.80573C5.2986 8.31269 4.46875 10.3162 4.46875 12.4473C4.46875 16.8466 8.04781 20.4257 12.4471 20.4257C14.3024 20.4257 16.012 19.7891 17.3686 18.7228L23.8964 25.2506C24.0833 25.4375 24.3284 25.531 24.5734 25.531C24.8184 25.531 25.0634 25.4376 25.2503 25.2506C25.6243 24.8768 25.6243 24.2705 25.2503 23.8967ZM12.4471 18.511C9.10363 18.511 6.3835 15.7909 6.3835 12.4473C6.3835 10.8277 7.01421 9.30495 8.15946 8.1597C9.3047 7.01437 10.8274 6.38374 12.4471 6.38374C15.7906 6.38374 18.5107 9.10387 18.5107 12.4474C18.5107 15.7909 15.7906 18.511 12.4471 18.511Z%27 fill=%27%232C3638%27/%3E%3C/svg%3E%0A");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	position: initial;
}

.cloth-img-item-full-size {
	border: none;
	position: absolute;
	top: -3px;
	right: 9px;
	width: 22px;
	height: 22px;
	padding: 0;
	background: #fff;
	border-radius: 100%;
	border: 1px solid var(--pink);
	z-index: 2;
	display: none;
	transition: all .5s ease;
	cursor: zoom-in !important
}



div.cloth-img-item>img {
	transition: all .5s ease;
}

div.cloth-img-item:hover>img {
	transform: translateY(-5px)
}

div.cloth-img-item.active>img {
	transform: translateY(-5px);
	border: 2px solid var(--pink);
}

.product-clothes-tab .nav-link .cloth-img-item-sale-icon.icon-sale {
	top: 3px;
	left: 19px;
}

.cloth-category-btn-sale .cloth-img-item-sale-icon.icon-sale {
	display: block
}

/*  modal zoom */
.product-cloth-img-zoom-modal .modal-body img {
	width: 100%;
	max-width: 600px;
	height: max-content;
	max-height: 500px;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
	object-fit: cover;
}

.product-cloth-img-zoom-modal .modal-body {
	padding: 0;
}

.product-cloth-img-zoom-modal .modal-content {
	background: #fff0;
	border: none;
}

.product-cloth-img-zoom-modal .btn-close {
	position: absolute;
	top: 15px;
	right: 13px;
	z-index: 2;
	font-size: 20px;
}

.product-config-item-content img {
	width: 35px;
	height: 35px;
	display: block;
	border-radius: 100%;
	object-fit: cover;
}

.product-config-item-content {
	display: flex;
	align-items: center;
	gap: 10px;
}

.product-config-item-content * {
	margin-right: auto;
	display: block;
	text-align: end;
	font-weight: 500;
}

.product-config-item-content.product-sale {
	font-weight: 600;
	color: #ff0000;
}

.product-cloth-description-text-wr * {
	font-size: 12px !important;
	padding: 0 !important;
	margin: 0 !important;
	line-height: 20px !important;
}

.product-cloth-description-text-wr {
	--scrollbarWidth: 5px;
	background: #FFF;
	overflow: hidden;
	overflow-y: auto;
	height: 300px;
}

.product-cloth-description li:not(:last-child) {
	margin-bottom: 8px !important;
}

.product-cloth-description {
	border-radius: 0px 14px 14px 0px;
	border-top: 1px solid #E7E7E7;
	border-right: 1px solid #E7E7E7;
	border-bottom: 1px solid #E7E7E7;
	background: #FFF;
	width: 45%;
	height: max-content;
	padding: 10px 5px 5px 20px;
}

.product-cloth-images {
	--scrollbarWidth: 7px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: inherit;
	width: 65%;
	height: 300px;
	overflow: hidden;
	overflow-y: auto;
	padding: 5px 0;
	padding-right: 10px;
	padding-top: 10px;
}

.product-clothes-item .tab-pane {
	box-shadow: var(--shadow-mini-block);
	padding: 15px 10px 15px 0px;
	border-radius: var(--br-xl);
}

.cloth-img-item>span {
	line-height: 17px;
	font-size: 13px !important;
}

.cloth-img-item-sale-icon.icon-sale {
	display: none;
	position: absolute;
	top: -5px;
	left: 4px;
	font-size: 21px !important;
	background: #fff;
	color: var(--red);
	border-radius: 100%;
	line-height: 1;
	box-shadow: var(--shadow-mini-block);
	z-index: 2;
}

.cloth-img-sale .cloth-img-item-sale-icon.icon-sale,
.cloth-category-sale .cloth-img-item-sale-icon.icon-sale {
	display: block
}

.btn-product-cloth-description {
	display: none;
	width: max-content;
	background: var(--white);
	border-radius: var(--br-lg);
	border: none;
	padding: 5px 15px;
	height: 28px;
	line-height: initial;
	color: var(--user-color)
}

.product-cloth-description-modal {
	--bs-modal-border-radius: var(--br-xl);
	background: #000000a3;
}

.product-cloth-description-modal .modal-body * {
	padding: 0 !important;
	margin: 0 !important;
	line-height: 28px;
}

.product-cloth-description-modal .modal-body {
	padding: 30px;
	padding-top: 0;
}

.product-cloth-description-modal .modal-header {
	border-bottom: none;
}

.product-cloth-description-modal .modal-header button {
	font-size: 17px;
}

.product-cloth-config-roll-up {
	border: none;
	background-color: rgba(255, 255, 255, 0);
	background-image: url("data:image/svg+xml,%3Csvg width=%2730%27 height=%2730%27 viewBox=%270 0 30 30%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M4.73242 6.55127H25.2676M4.73242 13.5919H20.8672C22.334 13.5919 25.2676 14.296 25.2676 17.1122C25.2676 19.9285 22.8224 20.6325 21.6006 20.6325H15M15 20.6325L17.9336 17.8163M15 20.6325L17.9336 23.4488M4.73242 20.6325H10.5996%27 stroke=%27%232C3638%27 stroke-width=%272.2%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A");
	width: 30px;
	height: 30px;
	background-repeat: no-repeat;
	background-position: center center;
	padding: 24px;
}

.toast.custom-regular-toast.roll-up .product-cloth-config-roll-up {
	background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.75 7.50014C3.75 6.9084 4.2297 6.42871 4.82143 6.42871H25.1786C25.7703 6.42871 26.25 6.9084 26.25 7.50014C26.25 8.09188 25.7703 8.57157 25.1786 8.57157H4.82143C4.2297 8.57157 3.75 8.09188 3.75 7.50014Z' fill='%232C3638'/%3E%3Cpath d='M3.75 14.6621C3.75 14.0704 4.2297 13.5906 4.82143 13.5906L16.6071 13.5909C17.1989 13.5909 17.6786 14.0706 17.6786 14.6623C17.6786 15.2541 17.1989 15.7338 16.6071 15.7338L4.82143 15.7335C4.2297 15.7335 3.75 15.2538 3.75 14.6621Z' fill='%232C3638'/%3E%3Cpath d='M4.82143 20.7527C4.2297 20.7527 3.75 21.2324 3.75 21.8241C3.75 22.4158 4.2297 22.8954 4.82143 22.8954H23.0357C23.6275 22.8954 24.1071 22.4158 24.1071 21.8241C24.1071 21.2324 23.6275 20.7527 23.0357 20.7527H4.82143Z' fill='%232C3638'/%3E%3C/svg%3E%0A");
}

.toast.custom-regular-toast.roll-up .toast-body {
	display: none;
}

.toast.custom-regular-toast.roll-up .toast-header>span {
	display: none;
}

.toast.custom-regular-toast.roll-up .toast-header {
	border: none;
	border-radius: var(--br-xl);
	padding: 0;
}

.toast.custom-regular-toast.roll-up {
	width: max-content;
}

.custom-regular-toast.product-cloth-config .toast-header {
	justify-content: space-between;
	font-weight: 500;
	color: var(--user-color);
	font-size: 16px;
	border-radius: var(--br-xl) var(--br-xl) 0 0;
	padding: 2px 15px;
}

.form-check.form-switch.product-cloth-switch-images-slider {
	width: max-content;
	background: var(--white);
	padding: 4px 14px;
	padding-left: 3rem;
	border-radius: var(--br-lg);
}

.form-check.form-switch.product-cloth-switch-images-slider.form-switch .form-check-input {
	background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 7C5.65685 7 7 5.65685 7 4C7 2.34315 5.65685 1 4 1C2.34315 1 1 2.34315 1 4C1 5.65685 2.34315 7 4 7Z' fill='%23E7E4DF'/%3E%3C/svg%3E%0A");
}

button.swiper-custom-arrow.product-cloth-images-arrow {
	opacity: 1;
	cursor: pointer;
	pointer-events: visible;
	display: flex;
	border: none;
	width: 60px;
	height: 60px;
	top: 46%
}

@media (max-width: 1200px) {
	.custom-regular-toast {
		left: 3%
	}
}

@media (max-width: 992px) {
	.custom-add-toast {
		display: none !important
	}
}

@media (max-width: 600px) {
	.accordion-item.product-clothes-item.canvas-custom-accordion-item .accordion-header>button {
		font-size: 18px;
	}

	.product-clothes-canvas .offcanvas-body {
		--bs-offcanvas-padding-x: 0.5rem;
		padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
	}

	.product-cloth-category-price>span:first-child {
		font-size: 16px;
	}

	.product-cloth-category-price>span:last-child {
		font-size: 14px;
	}

	.accordion-item.canvas-custom-accordion-item {
		padding-bottom: 25px;
	}

	.product-clothes-tab .nav-link>img {
		width: 45px !important;
		height: 45px;
		border: 2px solid rgba(255, 255, 255, 0)
	}

	.product-clothes-tab .nav-link.active>img {
		border: 2px solid var(--user-color);
	}

	.product-clothes-tab .nav-link {
		gap: 5px;
		font-size: 12px;
		padding: 5px;
	}

	.product-clothes-tab .nav-item {
		width: 78px;
	}

	.cloth-img-item>img {
		height: 38px;
		width: 38px !important;
	}

	.cloth-img-item {
		font-size: 12px;
		width: 64px;
	}

	.btn-product-cloth-description {
		display: block
	}

	.product-cloth-description {
		display: none
	}

	.product-clothes-parent .tab-content>.active {
		flex-direction: column;
	}

	.product-cloth-images {
		justify-content: center;
		width: 100%;
		padding: 0;
		padding-top: 10px;
	}

	.form-check.form-switch.product-cloth-switch-images-slider {
		line-height: 18px;
		height: 28px;
	}


}

@media (max-width: 500px) {
	.product-cloth-description-modal .modal-body * {
		font-size: 14px !important;
	}
}


@media (min-width: 600px) {
	.product-clothes-parent .tab-content>.active {
		position: relative;
		padding-top: 50px;
	}

	.form-check.form-switch.product-cloth-switch-images-slider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 10px auto;
	}
}

@media (min-width: 992px) {
	.cloth-img-item:hover>button {
		transform: translateY(-5px)
	}

	.cloth-img-item:hover>button {
		display: block
	}

	.cloth-img-item.active>button {
		transform: translateY(-5px);
	}
}


/* 
Product cloth view mode
*/

/* Modal */
.product-cloth-img-slider-modal {
	--bs-modal-width: 800px;
	--bs-modal-header-padding: 1rem 2rem;
}

.product-cloth-img-slider-modal .modal-content {
	border: none;
	position: relative;
}

.product-cloth-img-slider-modal .modal-header {
	border: none;
	padding: 15px 25px 10px 25px;
}

.product-cloth-img-slider-modal .modal-header .modal-title {
	font-size: 18px;
	font-weight: 500;
}

.productClothImagesSlider .swiper-slide {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.productClothImagesSlider .swiper-slide img {
	width: 90%;
	height: 500px;
	object-fit: cover;
	border-radius: var(--br-xxl);
	box-shadow: var(--shadow-block);
}

.productClothImagesSlider .swiper-slide h6 {
	text-transform: capitalize;
	font-size: 17px;
	display: block;
	padding-top: 8px;
}

button.btn.btn-default.btn-pink.slide-cloth-img-item {
	width: 250px;
}

button.btn.btn-default.btn-pink.slide-cloth-img-item.active {
	background-color: var(--pink);
	color: #fff;
	border: none
}

.product-cloth-img-slider-modal .modal-dialog {
	margin: 0px auto;
	padding: 20px 15px;
}

.product-cloth-img-slider-modal .modal-header {
	border: none;
}

.product-cloth-img-slider-modal .modal-footer {
	justify-content: flex-start;
	padding: var(--bs-modal-header-padding);
}

.product-cloth-img-slider-modal .modal-footer span {
	text-transform: capitalize;
	text-decoration: underline;
}

.product-cloth-img-slider-modal .modal-footer {
	font-weight: 500;
}

@media (max-width: 768px) {
	.productClothImagesSlider .swiper-slide img {
		width: 95%;
		height: 350px;
		border-radius: var(--br-xl);
		box-shadow: var(--shadow-mini-block);
	}

	button.swiper-custom-arrow.product-cloth-images-arrow {
		width: 55px;
		height: 55px;
		top: 44%;
	}

	.product-cloth-img-slider-modal {
		--bs-modal-padding: 1rem 1.5rem;
		--bs-modal-header-padding: 0.75rem 1.5rem;
	}
}

@media (max-width: 480px) {
	.product-cloth-img-slider-modal .modal-header .modal-title {
		font-size: 16px;
	}

	.productClothImagesSlider .swiper-slide img {
		height: 230px;
	}

	button.swiper-custom-arrow.product-cloth-images-arrow {
		width: 40px;
		height: 40px;
		top: 39%;
	}

	.productClothImagesSlider .swiper-slide h6 {
		font-size: 16px;
		padding-top: 5px;
		order: 1;
	}

	button.btn.btn-default.btn-pink.slide-cloth-img-item {
		width: 100%;
		order: 2;
	}

	.product-cloth-img-slider-modal {
		--bs-modal-padding: 1rem 1rem;
		--bs-modal-header-padding: 0.75rem 1rem;
	}

	.product-cloth-img-slider-modal .modal-footer {
		font-size: 14px;
	}
}

/*

Product reviews

*/

.product-reviews-wr {
	background: #fff;
	filter: var(--shadow-block-filter);
	border-radius: var(--br-xxl);
	padding: 25px 25px 30px 25px;
	position: relative;
}

.swiper.productReviewsSlider {
	margin-top: 30px;
}

.swiper.productReviewsSlider .swiper-slide img {
	width: 100%;
	height: 100px;
	border-radius: var(--br-xl);
	object-fit: cover;
}

.product-reviews-slider-arrow.swiper-custom-arrow {
	width: 40px;
	height: 40px;
}

@media (max-width: 992px) {
	.swiper.productReviewsSlider .swiper-slide {
		width: 150px;
	}
}

@media (max-width: 576px) {
	.swiper.productReviewsSlider .swiper-slide {
		width: 120px;
	}

	.swiper.productReviewsSlider .swiper-slide img {
		height: 80px;
		border-radius: var(--br-lg);
	}
}

/*  slider reviews */
.product-reviews-slider-wr {
	--reviews-gallery-content-h: 650px;
}

.product-reviews-slider-wr.row {
	padding: 0;
	margin: 0;
	height: var(--reviews-gallery-content-h)
}

.product-reviews-slide.fancybox-image.fancybox__content {
	margin: auto 20rem;
	display: block;
	padding: 0;
	background: #fff;
	box-shadow: var(--shadow-block);
	height: max-content;
	align-self: center;
	min-height: max-content;
	width: -webkit-fill-available;
	border-radius: var(--br-xxl);
	overflow: hidden;
}

.product-reviews-slider-wr.row>* {
	padding: 0;
}

.product-reviews-slider-wr.row>div:first-child>img {
	width: 100%;
	height: var(--reviews-gallery-content-h);
	object-fit: cover;
}

.reviews-gallery-content-review {
	--scrollbarWidth: 5px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	overflow-y: auto;
}

.reviews-gallery-content-review .reviews-gallery-content-user svg {
	width: 50px;
	height: 50px;
	object-fit: contain;
}

.reviews-gallery-content-review-name {
	font-size: 15px;
	font-weight: 500;
}

.reviews-gallery-content-review-date {
	font-size: 12px;
	color: var(--dark-grey);
}

.reviews-gallery-content-review-text>div {
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
}

.reviews-gallery-content-review-text p {
	font-size: 14px;
	line-height: 22px;
	margin: 0;
	padding: 0;
}

.reviews-gallery-content-user {
	margin-bottom: 10px;
}

.swiper.productReviewsSlider .swiper-slide {
	cursor: pointer;
}

.product-reviews-slider-wr.row>div:last-child {
	height: var(--reviews-gallery-content-h);
	padding: 5px 5px 10px 0px;
}



@media (max-width: 1500px) {
	.product-reviews-slider-wr {
		--reviews-gallery-content-h: 500px;
	}

	.product-reviews-slide.fancybox-image.fancybox__content {
		margin: auto 15rem;
	}
}

@media (max-width: 1400px) {
	.product-reviews-slider-wr {
		--reviews-gallery-content-h: 450px;
	}

	.product-reviews-slide.fancybox-image.fancybox__content {
		margin: auto 13rem;
	}
}

@media (max-width: 1200px) {
	.product-reviews-slide.fancybox-image.fancybox__content {
		margin: auto 5rem;
	}
}

@media (max-width: 992px) {
	.product-reviews-slider-wr {
		--reviews-gallery-content-h: 400px;
	}

	.product-reviews-slide.fancybox-image.fancybox__content {
		margin: 4rem 3rem;
	}
}

@media (max-width: 768px) {
	.product-reviews-slider-wr {
		--reviews-gallery-content-h: 100%;
		overflow-y: auto
	}

	.product-reviews-slider-wr.row>div:last-child {
		height: max-content;
		padding: 5px;
	}

	.product-reviews-slider-wr.row>div:first-child>img {
		height: 400px;
	}

	.product-reviews-slide.fancybox-image.fancybox__content {
		border-radius: var(--br-xl);
		margin-top: 0;
	}
}

@media (max-width: 576px) {
	.product-reviews-slide.fancybox-image.fancybox__content {
		margin: 4rem 1rem;
	}

	.product-reviews-slider-wr.row>div:first-child>img {
		height: 350px;
	}
}

@media (max-width: 480px) {
	.product-reviews-slider-wr.row>div:first-child>img {
		height: 250px;
	}
}


/* product buy to one click */

.buy-to-one-click-fields-wr .form-cf-header-item {
	margin: 20px 0;
}

.buy-to-one-click-fields-wr .checkbox-wrapper {
	margin-bottom: 10px;
	margin-top: -5px;
}

.buy-to-one-click-product-wr img {
	width: 30%;
	-o-object-fit: contain;
	object-fit: contain;
	flex: 0 0 auto;
}

.buy-to-one-click-product-wr h4 {
	font-weight: 600;
	font-size: 20px;
	line-height: 20px;
	margin: 0;
}

.buy-to-one-click-product-wr h4 span {
	font-weight: 400;
	font-size: 12px;
	color: var(--dark-grey);
}

.buy-to-one-click-options * {
	display: block;
	margin: 0;
	padding: 0;
	border: none;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.buy-to-one-click-options>* {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-weight: 500;
	justify-content: flex-start;
	gap: 3px;
	flex-wrap: wrap;
}

.buy-to-one-click-product-wr {
	padding: 15px;
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
}

div#buyToOneClickModal .modal-header .modal-title {
	font-size: 20px;
}

div#buyToOneClickModal .modal-header {
	padding: 1.6rem 1.5rem;
}

div#buyToOneClickModal {
	--bs-modal-width: 450px;
}

div#buyToOneClickModal .modal-header .modal-title span {
	font-weight: 600;
}

.buy-to-one-click-price-wr {
	font-size: 14px;
	font-weight: 500;
	display: flex;
	gap: 6px;
}

.buy-to-one-click-price-old.product-price-old {
	color: rgba(44, 54, 56, 0.6);
	font-size: 12px;
	font-style: normal;
	line-height: normal;
	text-decoration: line-through;
	margin-top: 6px;
}

@media (max-width: 576px) {
	div#buyToOneClickModal .checkbox-wrapper span {
		font-size: 10px;
	}

	div#buyToOneClickModal .checkbox-wrapper span a {
		font-size: 10px;
	}
}

/* product reviews */

.product-review-block {
	margin-bottom: 30px;
	background: #fff;
	padding: 25px;
	border-radius: var(--br-xxl);
	filter: var(--shadow-block-filter);
}

.product-review-avatar {
	width: 40px;
	height: 40px;
	object-fit: contain;
}

.product-review-name {
	font-size: 17px;
	font-weight: 500;
}

.product-review-date {
	font-size: 14px;
	color: var(--dark-grey);
}

.product-review-rating .icon-star-full {
	color: #ffcc00;
}

.product-clothes-btns-item-name.w-100.d-flex.gap-3.justify-content-between.align-items-center:hover {
	opacity: 0.9;
}

.product-review-rating span {
	font-size: 20px;
}

.product-review-user-wr {
	margin-bottom: 15px;
}

.product-review-text-item-title .review-good-icon {
	color: var(--brigth-green);
}

.product-review-text-item-title .review-bad-icon {
	color: var(--red);
}

.product-review-text-item-title span {
	font-size: 29px;
}

.product-review-text-item-title>div {
	font-weight: 500;
}

.product-review-text-item p {
	padding: 0;
	margin: 0;
	margin-top: 12px;
	font-size: 14px;
}

.product-review-text-item-title .icon-message1 {
	font-size: 22px;
}

.swiper.productReviewCurrentSlider {
	margin-top: 20px;
	width: 100%;
	height: max-content;
}

.swiper.productReviewCurrentSlider .swiper-slide img {
	width: 100%;
	object-fit: cover;
	border-radius: var(--br-xl);
}

.swiper.productReviewCurrentSlider .swiper-slide {
	cursor: pointer;
}

.product-review-comment-block {
	margin-top: 20px;
	background: rgba(231, 228, 223, 0.70);
	border-radius: var(--br-xxl);
	padding: 20px;
}

.product-review-comment-title .frendom-balls {
	background-image: url("data:image/svg+xml,%3Csvg width='73' height='14' viewBox='0 0 73 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.09473 13.6774C10.6846 13.6774 13.5947 10.7673 13.5947 7.17743C13.5947 3.58758 10.6846 0.677429 7.09473 0.677429C3.50488 0.677429 0.594727 3.58758 0.594727 7.17743C0.594727 10.7673 3.50488 13.6774 7.09473 13.6774Z' fill='%23BBA0AF'/%3E%3Cpath d='M26.5752 13.6774C30.165 13.6774 33.0752 10.7673 33.0752 7.17743C33.0752 3.58758 30.165 0.677429 26.5752 0.677429C22.9853 0.677429 20.0752 3.58758 20.0752 7.17743C20.0752 10.7673 22.9853 13.6774 26.5752 13.6774Z' fill='%23869EA4'/%3E%3Cpath d='M46.0557 13.6774C49.6455 13.6774 52.5557 10.7673 52.5557 7.17743C52.5557 3.58758 49.6455 0.677429 46.0557 0.677429C42.4658 0.677429 39.5557 3.58758 39.5557 7.17743C39.5557 10.7673 42.4658 13.6774 46.0557 13.6774Z' fill='%23D0B6A5'/%3E%3Cpath d='M65.5364 13.6774C69.1262 13.6774 72.0364 10.7673 72.0364 7.17743C72.0364 3.58758 69.1262 0.677429 65.5364 0.677429C61.9465 0.677429 59.0364 3.58758 59.0364 7.17743C59.0364 10.7673 61.9465 13.6774 65.5364 13.6774Z' fill='%23E5E2DD'/%3E%3C/svg%3E%0A");
	display: block;
	width: 70px;
	height: 18px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

.product-review-comment-title {
	display: flex;
	align-items: center;
	gap: 10px;
	background: #fff;
	font-weight: 500;
	width: max-content;
	padding: 3px 12px;
	border-radius: var(--br-lg);
	margin-bottom: 20px;
}

.product-review-comment-block p {
	margin: 0;
	font-size: 14px;
}

.product-reviews-top-menu .form-check>*,
.product-reviews-top-menu .form-check {
	cursor: pointer;
}

.product-review-block.placeholder {
	display: block;
	height: max-content;
	width: 100%;
	background: currentColor;
	opacity: 0.2;
	filter: contrast(0);
}

@media (max-width: 992px) {
	.swiper.productReviewCurrentSlider .swiper-slide {
		width: 150px;
	}
}



/* Reviews send block */

.product-send-review-wr {
	background: #fff;
	filter: var(--shadow-block-filter);
	border-radius: var(--br-xxl);
	padding: 30px 55px;
	position: relative;
}

.product-send-review-big-count {
	font-size: 48px;
	font-weight: 600;
	word-wrap: break-word;
	margin-right: 10px;
}

.product-send-review-wr .product-card-reviews-wr {
	align-items: flex-end;
	margin-bottom: 35px;
}

.product-send-review-wr span.product-card-reviews-total {
	display: block;
	margin-top: 12px;
	align-self: flex-start;
}

.product-send-review-progress-item .progress {
	width: 100%;
}

button.btn.btn-default.btn-pink.product-review-send {
	margin-top: 25px;
	height: 44px;
}

.product-send-review-progress-item .progress {
	--bs-progress-bg: rgba(0, 0, 0, 0.06);
}

.product-send-review-progress-item.prb-5 .progress {
	--bs-progress-bar-bg: #5AE98B;
}

.product-send-review-progress-item.prb-4 .progress {
	--bs-progress-bar-bg: #ADEC6C;
}

.product-send-review-progress-item.prb-3 .progress {
	--bs-progress-bar-bg: #F4D243;
}

.product-send-review-progress-item.prb-2 .progress {
	--bs-progress-bar-bg: #F2A226;
}

.product-send-review-progress-item.prb-1 .progress {
	--bs-progress-bar-bg: #F96335;
}

.product-send-review-progress-item>div:first-child {
	color: var(--grey-dark);
	font-weight: 500;
}

.product-send-review-progress-item-procent {
	font-weight: 500;
}

.product-send-review-progress-item-total {
	font-size: 14px;
	align-self: flex-end;
}

@media (max-width: 992px) {
	.product-reviews-block {
		order: 2;
	}

	.product-send-review-block {
		order: 1;
	}
}

@media (max-width: 576px) {

	.product-send-review-wr,
	.product-reviews-wr {
		padding: 15px 25px 30px 15px;
	}

	.product-review-block {
		padding: 20px;
		border-radius: var(--br-xl);
	}

	.swiper.productReviewCurrentSlider .swiper-slide {
		width: 120px;
	}

	.swiper.productReviewCurrentSlider .swiper-slide img {
		height: 80px;
		border-radius: var(--br-lg);
	}

	.product-review-user-wr {
		flex-wrap: wrap;
		gap: 10px;
	}

	.product-review-user-wr>div {
		width: 100%;
	}

	.product-review-date {
		font-size: 12px;
		color: var(--dark-grey);
	}

	.product-review-text-item-title span {
		font-size: 24px;
	}

	.product-review-text-item-title>div {
		font-size: 14px;
	}

	.product-review-text-item p {
		margin-top: 7px;
		font-size: 13px;
		line-height: 19px;
	}

	.product-review-comment-block {
		margin-top: 20px;
		border-radius: var(--br-xl);
		padding: 12px;
	}

	.product-reviews-top-menu .form-check {
		font-size: 14px;
	}

	.product-send-review-big-count {
		font-size: 34px;
	}

	.product-send-review-progress-item-procent {
		font-size: 12px;
	}
}


/* Send review contact form */

.rating__display {
	font-size: 15px;
	font-weight: 500;
	position: absolute;
	top: 100%;
	width: 100%;
	text-align: center;
}

.rating__stars {
	--bg: #e3e4e8;
	--fg: #17181c;
	--primary: #255ff4;
	--yellow: #f4a825;
	--yellow-t: rgba(244, 168, 37, 0);
	--bezier: cubic-bezier(0.42, 0, 0.58, 1);
	--trans-dur: 0.3s;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 0.375em;
	position: relative;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 45px;
}

.rating__star {
	display: block;
	overflow: visible;
	pointer-events: none;
	width: 2em;
	height: 2em;
}

.rating__star-ring,
.rating__star-fill,
.rating__star-line,
.rating__star-stroke {
	animation-duration: 1s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

.rating__star-ring,
.rating__star-fill,
.rating__star-line {
	stroke: var(--yellow);
}

.rating__star-fill {
	fill: var(--yellow);
	transform: scale(0);
	transition: fill var(--trans-dur) var(--bezier), transform var(--trans-dur) var(--bezier);
}

.rating__star-stroke {
	stroke: #c7cad1;
	transition: stroke var(--trans-dur);
}

.rating__label {
	cursor: pointer;
	padding: 0.3em;
}

.rating__label--delay1 .rating__star-ring,
.rating__label--delay1 .rating__star-fill,
.rating__label--delay1 .rating__star-line,
.rating__label--delay1 .rating__star-stroke {
	animation-delay: 0.05s;
}

.rating__label--delay2 .rating__star-ring,
.rating__label--delay2 .rating__star-fill,
.rating__label--delay2 .rating__star-line,
.rating__label--delay2 .rating__star-stroke {
	animation-delay: 0.1s;
}

.rating__label--delay3 .rating__star-ring,
.rating__label--delay3 .rating__star-fill,
.rating__label--delay3 .rating__star-line,
.rating__label--delay3 .rating__star-stroke {
	animation-delay: 0.15s;
}

.rating__label--delay4 .rating__star-ring,
.rating__label--delay4 .rating__star-fill,
.rating__label--delay4 .rating__star-line,
.rating__label--delay4 .rating__star-stroke {
	animation-delay: 0.2s;
}

.rating__input {
	-webkit-appearance: none;
	appearance: none;
	display: none;
}

/* .rating__input:hover ~ [data-rating]:not([hidden]) {
  display: none;
} */
.rating__input-1:hover~[data-rating="1"][hidden],
.rating__input-2:hover~[data-rating="2"][hidden],
.rating__input-3:hover~[data-rating="3"][hidden],
.rating__input-4:hover~[data-rating="4"][hidden],
.rating__input-5:hover~[data-rating="5"][hidden],
.rating__input:checked:hover~[data-rating]:not([hidden]) {
	display: block;
}

.rating__input-1:hover~.rating__label:first-of-type .rating__star-stroke,
.rating__input-2:hover~.rating__label:nth-of-type(-n + 2) .rating__star-stroke,
.rating__input-3:hover~.rating__label:nth-of-type(-n + 3) .rating__star-stroke,
.rating__input-4:hover~.rating__label:nth-of-type(-n + 4) .rating__star-stroke,
.rating__input-5:hover~.rating__label:nth-of-type(-n + 5) .rating__star-stroke {
	stroke: var(--yellow);
	transform: scale(1);
}

.rating__input-1:checked~.rating__label:first-of-type .rating__star-ring,
.rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-ring,
.rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-ring,
.rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-ring,
.rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-ring {
	animation-name: starRing;
}

.rating__input-1:checked~.rating__label:first-of-type .rating__star-stroke,
.rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-stroke,
.rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-stroke,
.rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-stroke,
.rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-stroke {
	animation-name: starStroke;
}

.rating__input-1:checked~.rating__label:first-of-type .rating__star-line,
.rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-line,
.rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-line,
.rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-line,
.rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-line {
	animation-name: starLine;
}

.rating__input-1:checked~.rating__label:first-of-type .rating__star-fill,
.rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-fill,
.rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-fill,
.rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-fill,
.rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-fill {
	animation-name: starFill;
}

.rating__input-1:not(:checked):hover~.rating__label:first-of-type .rating__star-fill,
.rating__input-2:not(:checked):hover~.rating__label:nth-of-type(2) .rating__star-fill,
.rating__input-3:not(:checked):hover~.rating__label:nth-of-type(3) .rating__star-fill,
.rating__input-4:not(:checked):hover~.rating__label:nth-of-type(4) .rating__star-fill,
.rating__input-5:not(:checked):hover~.rating__label:nth-of-type(5) .rating__star-fill {
	fill: var(--yellow-t);
}

.rating__sr {
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	position: absolute;
	width: 1px;
	height: 1px;
}

@keyframes starRing {

	from,
	20% {
		animation-timing-function: ease-in;
		opacity: 1;
		r: 8px;
		stroke-width: 16px;
		transform: scale(0);
	}

	35% {
		animation-timing-function: ease-out;
		opacity: 0.5;
		r: 8px;
		stroke-width: 16px;
		transform: scale(1);
	}

	50%,
	to {
		opacity: 0;
		r: 16px;
		stroke-width: 0;
		transform: scale(1);
	}
}

@keyframes starFill {

	from,
	40% {
		animation-timing-function: ease-out;
		transform: scale(0);
	}

	60% {
		animation-timing-function: ease-in-out;
		transform: scale(1.2);
	}

	80% {
		transform: scale(0.9);
	}

	to {
		transform: scale(1);
	}
}

@keyframes starStroke {
	from {
		transform: scale(1);
	}

	20%,
	to {
		transform: scale(0);
	}
}

@keyframes starLine {

	from,
	40% {
		animation-timing-function: ease-out;
		stroke-dasharray: 1 23;
		stroke-dashoffset: 1;
	}

	60%,
	to {
		stroke-dasharray: 12 12;
		stroke-dashoffset: -12;
	}
}

.modal-review-send {
	--bs-modal-width: 700px;
}

.modal-review-send textarea.form__input {
	min-height: 100px !important;
	resize: none;
}



.modal-review-send .form-cf-header-item {
	margin: 0;
}

.modal-review-send .form-cf-header-item input::placeholder,
.modal-review-send .form-cf-header-item textarea::placeholder {
	color: red
}

.modal-review-send .modal-footer {
	padding: 1.2rem 1.5rem;
	padding-bottom: 2rem;
	border: none
}

input.btn.btn-default.btn-pink.product-review-send-btn {
	height: 42px;
}

input.btn.btn-default.btn-pink.product-review-send-btn:focus {
	color: #fff
}

.modal-review-send .modal-header>div>span {
	font-size: 13px;
	line-height: 18px;
	display: flex;
	gap: 7px;
	border: 1px solid var(--light-grey);
	padding: 5px;
	border-radius: var(--br-xl);
}

.modal-review-send .rating-star-title {
	font-size: 18px;
	text-align: center;
	font-weight: 500;
}


.modal-review-send .dropzone {
	height: 35.4rem;
}

.block-modal .modal-content>*:not(.loading-wrapper) {
	touch-action: none;
	pointer-events: none;
	opacity: 0;
}

.block-modal .modal-dialog,
.block-modal .modal-content {
	height: 300px;
}

@media (max-width: 768px) {

	.modal-review-send .dropzone {
		height: auto;
	}
}

@media (max-width: 576px) {
	.modal-review-send .dropzone .dz-default.dz-message {
		font-size: 14px;
		line-height: 20px;
	}

	.rating__stars {
		margin-bottom: 35px;
	}

	.modal-review-send .modal-header>div>span {
		font-size: 12px;
		line-height: 17px;
	}

}


/* captcha modal */

.captcha-modal {
	display: block;
	background: #000000c2;
}

.captcha-modal-title {
	font-size: 16px;
	text-align: center;
	font-weight: 500;
}

.captcha-img-wrapper img {
	margin: 15px auto;
	display: block;
	position: relative;
	border: 1px solid rgba(0, 0, 0, 0.4588235294);
}

.captcha-modal label {
	display: flex;
	flex-direction: column;
	gap: 10px;
	cursor: pointer;
}

.captcha-modal label input {
	border-radius: var(--br-sm);
	border: 1px solid #919191;
	height: 35px;
	width: 100%;
}

button#captcha-send-btn {
	width: 250px;
}

button#captcha-reload-btn {
	background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.2705 15.0019C26.2705 17.2311 25.6095 19.4103 24.371 21.2638C23.1325 23.1173 21.3722 24.5619 19.3127 25.415C17.2532 26.268 14.987 26.4912 12.8007 26.0564C10.6143 25.6215 8.606 24.548 7.02972 22.9717C5.45344 21.3955 4.37998 19.3872 3.94509 17.2008C3.5102 15.0144 3.7334 12.7482 4.58647 10.6887C5.43955 8.6292 6.88418 6.86892 8.73769 5.63044C10.5912 4.39197 12.7703 3.73094 14.9995 3.73094C17.1336 3.72381 19.2251 4.32829 21.0264 5.47282L22.4722 4.02706C22.6157 3.88356 22.7987 3.78592 22.9978 3.74653C23.1969 3.70714 23.4032 3.72778 23.5906 3.80582C23.778 3.88386 23.9379 4.0158 24.0502 4.18489C24.1625 4.35398 24.222 4.5526 24.2212 4.75557V8.85412C24.2212 9.12587 24.1133 9.38649 23.9211 9.57864C23.729 9.7708 23.4684 9.87875 23.1966 9.87875H19.0981C18.8954 9.87871 18.6974 9.81859 18.5289 9.706C18.3605 9.59341 18.2292 9.4334 18.1516 9.2462C18.0741 9.059 18.0538 8.85301 18.0933 8.65428C18.1328 8.45555 18.2304 8.273 18.3736 8.1297L19.5284 6.97494C18.1485 6.18919 16.5874 5.77736 14.9995 5.78021C13.1756 5.78021 11.3927 6.32105 9.8762 7.33435C8.3597 8.34765 7.17772 9.78788 6.47975 11.4729C5.78178 13.158 5.59916 15.0122 5.95499 16.801C6.31081 18.5898 7.18909 20.233 8.47877 21.5227C9.76845 22.8124 11.4116 23.6906 13.2004 24.0465C14.9893 24.4023 16.8435 24.2197 18.5285 23.5217C20.2136 22.8237 21.6538 21.6417 22.6671 20.1252C23.6804 18.6087 24.2212 16.8258 24.2212 15.0019C24.2212 14.7302 24.3292 14.4696 24.5213 14.2774C24.7135 14.0852 24.9741 13.9773 25.2459 13.9773C25.5176 13.9773 25.7782 14.0852 25.9704 14.2774C26.1626 14.4696 26.2705 14.7302 26.2705 15.0019Z' fill='%232C3638'/%3E%3C/svg%3E%0A") center center/contain no-repeat;
	width: 30px;
	height: 30px;
	border: none
}

.captcha-modal label span {
	font-size: 14px;
}

.captcha-modal label span.captcha-error-message {
	font-size: 12px;
	margin-top: -7px;
	color: #dc4545e3
}

@media (max-width: 480px) {
	.captcha-modal-title {
		font-size: 14px;
	}

	.captcha-modal .modal-body {
		padding: 1rem;
	}
}


/* 

RECOMMENDED PRODUCTS BLOCK

*/

.product-recommended-block {
	margin-top: 1.5rem;
}

.swiper.productsRecommendedCarusel {
	margin-top: 25px;
}

.simple-product-card-wrapper {
	--padding-right-left: 15px;
	border-radius: var(--br-xl);
	box-shadow: var(--shadow-block);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	height: 335px;
	gap: 7px;
	padding: 12px var(--padding-right-left);
	padding-bottom: 15px;
}

.simple-product-card-wrapper img {
	width: 100%;
	height: 180px;
	object-fit: cover;
	display: block;
	border-radius: var(--br-xl);
}

.simple-product-card-wrapper button.btn-product-card {
	position: static;
}

.simple-product-card-wrapper .product-card-sale {
	width: 30px;
	height: 30px;
	font-size: 12px;
	padding: 0px 0 0 3px;
}

.simple-product-card-top-block {
	position: absolute;
	width: 100%;
	top: 32px;
	left: 0;
	right: 0;
	padding: 0 calc(var(--padding-right-left) + 10px);
}

.swiper.productsRecommendedCarusel .swiper-slide {
	padding: 17px 5px;
}

.simple-product-card-wrapper h2 {
	font-size: 24px;
	font-weight: 600;
	margin: 0;
}

.simple-product-card-wrapper h3 {
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	line-height: 28px;
	padding-top: 2px;
	color: var(--dark-grey);
}

@media (max-width: 480px) {
	.simple-product-card-wrapper h3 {
		font-size: 10px;
	}
}

.simple-product-card-price {
	font-size: 20px;
	font-weight: 500;
	color: var(--red);
}

.simple-product-card-price-old {
	color: rgba(0, 0, 0, 0.4196078431);
	-webkit-text-decoration: line-through;
	text-decoration: line-through;
	font-size: 16px;
	font-weight: 400;
	padding-top: 3px;
}

.simple-product-card-wrapper .btn.btn-default {
	margin-top: auto;
	color: var(--bs-white);
	font-weight: 400;
	font-size: 14px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.product-recommended-arrows.swiper-custom-arrow {
	top: 47%;
}

/* 

CITIES MODAL

*/

a.prmn-cmngr-cities__city-name {
	font-size: 14px;
	display: block;
	line-height: 24px;
}

div#modalCities {
	--mc-padding: 1.2rem 1.5rem
}

div#modalCities .modal-body {
	padding: 2rem var(--mc-padding);
}

div#modalCities .modal-title {
	font-weight: 500;
	font-size: 20px;
}

div#modalCities .modal-header {
	background: var(--white);
	padding: 1.2rem 1.5rem;
	align-items: flex-start;
}

.citiesTabsWrapper {
	--bs-nav-pills-link-active-bg: var(--white);
	--bs-nav-pills-border-radius: var(--br-lg);
	--bs-nav-pills-link-active-color: var(--user-color);
}

.citiesTabsWrapper .nav-link {
	color: var(--user-color);
	background: #fff;
	box-shadow: var(--shadow-block);
	transition: var(--transition-btns);
}

div#modalCities .tab-content {
	margin-top: 20px;
	border-top: 1px solid var(--light-grey);
	padding-top: 10px;
}

div#modalCities .tab-content .row>div {
	padding-bottom: 5px;
	padding-top: 5px;
	height: max-content;
}

div#modalCities .tab-content .row {
	height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	align-content: flex-start;
}

input.prmn-cmngr-cities__search.search__input {
	margin-top: 25px;
}

.prmn-cmngr {
	position: relative;
}

.prmn-cmngr a {
	cursor: pointer;
}

.prmn-cmngr__confirm {
	position: absolute;
	top: 44px;
	left: -50px;
	background: #fff;
	padding: 15px 25px;
	min-width: 250px;
	max-width: 400px;
	z-index: 1039;
	box-shadow: var(--shadow-block-hover);
	line-height: 20px;
	font-size: 16px;
	text-align: center;
	color: #333;
	display: none;
	border-radius: var(--br-lg);
	cursor: auto
}

.prmn-cmngr .prmn-cmngr__confirm-city {
	white-space: nowrap;
	font-weight: 500;
}

.prmn-cmngr .prmn-cmngr__confirm-btns {
	margin-top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

input.prmn-cmngr__confirm-btn.btn.btn-brown {
	background: var(--brown);
}

input.prmn-cmngr__confirm-btn.btn.btn-brown:hover {
	background: var(--brown-hover);
}

input.prmn-cmngr__confirm-btn {
	color: #fff;
	padding-left: 20px;
	padding-right: 20px;
}

.citiesTabsWrapper .nav-link>span {
	width: 20px;
	height: 20px;
}

.citiesTabsWrapper .nav-link {
	display: flex;
	align-items: center;
	gap: 8px;
}

@media (max-width: 768px) {
	.prmn-cmngr__confirm {
		left: 0
	}
}



/*

CART ORDER PAGE

*/

.simple-content fieldset {
	text-align: left;
}

.simple-content fieldset h3 {
	margin: 0;
}

.simple-content .name {
	float: none !important;
}

.simple-content .button,
#simplecheckout_login .button {
	margin: 0px !important;
	height: 46px;
}

.simple-content .form-control {
	width: 100%;
}

#simplecheckout_login .buttons {
	margin: 0;
	border: none;
	background: inherit !important;
	padding: 0px !important;
}

.simplecheckout {
	width: 100%;
}

.simplecheckout:after {
	content: "";
	display: block;
	clear: both;
}

.simplecheckout-three-column {
	float: left;
	margin-right: 2%;
	min-width: 200px;
	max-width: 500px;
	width: 32%;
}

.simplecheckout-three-column+.simplecheckout-three-column+.simplecheckout-three-column {
	margin-right: 0px;
	float: right;
}

@media only screen and (max-width:768px) {
	.simplecheckout-three-column {
		display: block;
		float: none;
		min-width: auto !important;
		max-width: 1900px !important;
		width: 100%;
	}
}

@media only screen and (max-width:1200px) {
	.simplecheckout label.col-sm-2 {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media only screen and (max-width:768px) {
	.simplecheckout label.col-sm-2 {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.simpleregister {
	margin-bottom: 5px;
}

.simpleregister-block-content {
	margin-bottom: 10px;
	padding: 10px;
}

@media only screen and (min-width:1200px) {

	.simpleregister-block-content>fieldset:nth-of-type(2),
	.simpleregister-block-content>fieldset:nth-last-of-type(2) {
		width: 49%;
		margin-right: 1%;
		float: left;
		text-align: left;
	}
}

.simpleregister-block-content:after {
	content: "";
	display: block;
	clear: both;
}

@media only screen and (max-width:600px) {
	.simpleregister-block-content .simplecheckout-customer {
		width: 100%;
	}
}

.simpleregister-button-block {
	overflow: auto;
	margin-bottom: 20px;
	padding: 6px;

	background: none repeat scroll 0 0 #FFFFFF;
	text-align: right;
}

.simpleregister-button-right {
	float: right;
	text-align: right;
}

.simpleregister-button-left {
	float: left;
	text-align: left;
}

.simplecheckout-methods-table {
	width: 100%;
}

.simplecheckout-methods-table td.code {
	width: 1px;
}

.simplecheckout-methods-table td.title {
	padding: 5px;
}

.simplecheckout-methods-table td.title label {
	display: block !important;
}

.simplecheckout-methods-table td.quote {
	width: 15%;
	text-align: right;
}

.simplecheckout-methods-description {
	padding: 5px;
	margin: 5px 0px;
}

.simplecheckout-required {
	color: #FF0000;
	font-weight: bold;
}

.simplecheckout-button-left {
	float: left;
	text-align: left;
}

.simplecheckout-button {
	display: inline-block;
	padding: 6px 12px 6px 12px;
	-webkit-border-radius: 7px 7px 7px 7px;
	-moz-border-radius: 7px 7px 7px 7px;
	border-radius: 7px 7px 7px 7px;
	-webkit-box-shadow: 0px 2px 2px #DDDDDD;
	-moz-box-shadow: 0px 2px 2px #DDDDDD;
	box-shadow: 0px 2px 2px #DDDDDD;
	color: #FFFFFF;
	text-decoration: none;
	font-weight: bold;
	font-size: 12px;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 12px;
	cursor: pointer;
	-khtml-border-radius: 7px 7px 7px 7px;
}

.simplecheckout-button-left .button {
	margin: 0;
}

@media only screen and (max-width:780px) {
	.simplecheckout-button-left {
		display: none;
	}
}

#simplecheckout_customer .simplecheckout-block-content {
	text-align: center;
}

.simplecheckout-login {
	display: inline-block;
}

.simplecheckout-login-left {
	width: 50%;
	text-align: left;
}

.simplecheckout-login-right {
	width: 50%;
	text-align: left;
}

.simplecheckout-login-right .button {
	float: left;
	margin: 0;
}

.simplecheckout-login-right input {
	max-width: 160px !important;
	text-align: left;
}

#simplecheckout_cart .form-control {
	display: inline-block !important;
	min-width: 50px;
}

.simplecheckout-cart {
	margin: 0px !important;
	width: 100%;
	border-collapse: collapse;
	border-top: 1px solid #DDDDDD;
	border-right: 1px solid #DDDDDD;
	border-left: 1px solid #DDDDDD;
}

.simplecheckout-cart-total {
	padding: 5px 0;
}

.simplecheckout-cart-total:after {
	content: "";
	display: block;
	clear: both;
}

.simplecheckout-cart-total .buttons {
	background: inherit !important;
	padding: 0px !important;
}

.simplecheckout-cart-total input {
	margin-right: 0px !important;
	max-width: 100px;
}

.simplecheckout-cart-total span.inputs {
	margin: 0 3px 0 0 !important;
	display: inline-block !important;
}

.simplecheckout-cart-total span.inputs input[type=text] {
	margin: 0px;
}

.simplecheckout-cart-total-value {
	display: inline-block;
	margin: 0px 3px;
	font-size: 18px;
}

.simplecheckout-cart-total-remove {
	display: inline-block;
	width: 18px;
	text-align: center;
}

.simplecheckout-cart-total-remove img {
	vertical-align: -2px;
	cursor: pointer;
}

.simplecheckout-cart col.image {
	width: 5%;
}

.simplecheckout-cart col.model {
	width: 10%;
}

.simplecheckout-cart col.price {
	width: 10%;
}

.simplecheckout-cart col.total {
	width: 10%;
}

.simplecheckout-cart col.remove {
	width: 7px;
}

.simplecheckout-cart th {
	padding: 8px;
	border-bottom: 1px solid #DDDDDD;
	background-color: #F8F8F8;
	color: #4D4D4D;
	font-weight: bold;
}

.simplecheckout-cart th.image {
	width: 1px;
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	position: static !important;
}

.simplecheckout-cart th.name {
	text-align: center;
}

.simplecheckout-cart .name {
	font-size: inherit !important;
}

.simplecheckout-cart th.model {
	text-align: center;
}

.simplecheckout-cart th.quantity {
	text-align: center;
}

.simplecheckout-cart .price {
	text-align: right;
	display: table-cell !important;
	font: inherit !important;
	font-weight: bold !important;
}

.simplecheckout-cart .total {
	text-align: right;
	display: table-cell !important;
	font: inherit !important;
	font-weight: bold !important;
}

.simplecheckout-cart .quantity {
	display: table-cell !important;
	float: none !important;
	position: static !important;
}

.simplecheckout-cart th.remove {
	width: 7px;
	text-align: center;
}

.simplecheckout-cart td {
	padding: 7px;
	border-bottom: 1px solid #DDDDDD;
	vertical-align: top;
}

.simplecheckout-cart td.image {
	text-align: center;
	display: table-cell;
	vertical-align: middle !important;
	position: static !important;
}

.simplecheckout-cart td.name {
	text-align: center;
}

.simplecheckout-cart td.name div.image {
	display: none;
}

.simplecheckout-cart td.model {
	text-align: center;
}

.simplecheckout-cart td.quantity {
	min-width: 70px;
	vertical-align: top;
	text-align: center;
	white-space: nowrap;
}

.simplecheckout-cart td.quantity input {
	float: none !important;
}

.simplecheckout-cart td.quantity .input-group {
	margin: auto !important;
}

.simplecheckout-cart td.quantity .quantity button {
	margin: 0 !important;
}

.simplecheckout-cart td.quantity img {
	display: inline-block;
	border: 0px;
	cursor: pointer;
}

.simplecheckout-cart td.price {
	text-align: right;
	white-space: nowrap;
	display: table-cell !important;
	font: inherit !important;
}

.simplecheckout-cart td.total {
	text-align: right;
	white-space: nowrap;
	display: table-cell !important;
}

.simplecheckout-cart td.remove {
	padding-right: 3px;
	padding-left: 3px;
	width: 7px;
	vertical-align: top;
	text-align: center;
}

.simplecheckout-cart td.remove img {
	max-width: 30px !important;
	cursor: pointer;
}

.simplecheckout-cart td.product-warning {
	color: #FF0000;
	font-weight: bold;
}

.simplecheckout-cart td.right {
	vertical-align: middle;
	text-align: right;
}

.simplecheckout-three-column .simplecheckout-cart col.image,
.simplecheckout-three-column .simplecheckout-cart th.image,
.simplecheckout-three-column .simplecheckout-cart td.image,
.simplecheckout-three-column .simplecheckout-cart col.price,
.simplecheckout-three-column .simplecheckout-cart th.price,
.simplecheckout-three-column .simplecheckout-cart td.price,
.simplecheckout-three-column .simplecheckout-cart col.model,
.simplecheckout-three-column .simplecheckout-cart th.model,
.simplecheckout-three-column .simplecheckout-cart td.model {
	display: none;
}

.simplecheckout-three-column .simplecheckout-cart td.name .image {
	display: block;
}

.simplecheckout-scroll {
	overflow-y: scroll;
	max-height: 300px;
}

.simplecheckout-error-text {
	color: var(--red);
	font-size: 11px;
	position: absolute;
	top: -20px;
	text-align: center;
}

.simplecheckout-warning-text {
	margin: 5px 0;
	font-weight: bold;
}

.simplecheckout-warning-block {
	clear: both;
	margin-bottom: 15px;
	padding: 10px;
	border-radius: var(--br-xl);
	text-align: center;
	font-size: 13px;
	border: none;
	background: #ff4d4d36;
}

.simpleregister-warning-block {
	margin-bottom: 15px;
	padding: 10px 10px 10px 33px;
	border: 1px solid #F8ACAC;
	border-radius: 5px 5px 5px 5px;
	color: #555555;
	text-align: left;
}

.simplecheckout-proceed-payment {
	display: none;
	margin-bottom: 10px;
	padding: 10px;
	text-align: center;
	font-weight: bold;
}

#simple_payment_address_block {
	margin-top: 15px;
}

.simplecheckout-customer-same-address {
	text-align: left;
	margin: 15px 0px;
}

#simplecheckout_login {
	margin: auto;
	text-align: center;
}

#simplecheckout_payment_form {
	padding: 5px;
}

#simplecheckout_payment_form .checkout-content {
	display: block;
}

#simplecheckout_payment_form div.buttons {
	display: none;
}

#simplecheckout_login .simplecheckout-block-content {
	padding: 5px !important;
}

#simple_login_layer {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99990;
	display: none;
	width: 100%;
	background-color: #000000;
}

#simple_login {
	position: fixed;
	z-index: 99991;
	display: none;
	padding: 5px;
	width: 300px;
	height: 250px;
	border: 1px solid #FFD9FF;
	-webkit-border-radius: 7px 7px 7px 7px;
	-moz-border-radius: 7px 7px 7px 7px;
	border-radius: 7px 7px 7px 7px;
	background: none repeat scroll 0 0 #FFFFFF;
	-khtml-border-radius: 7px 7px 7px 7px;
}

#simple_login:after {
	content: "";
	display: block;
	clear: both;
}

#simple_login_header {
	padding: 0px 0px;
	text-align: right;
}

#simple_login_header img {
	margin: 0 0 0 auto;
}

.you-will-be-registered {
	font-weight: bold;
	margin-bottom: 10px;
}

#simple_summary {
	display: none;
}

#simplecheckout_step_menu {
	margin-bottom: 10px;
}

.simple-step {
	display: inline-block;
	padding: 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	background-color: #D9D9D9;
	color: #000000;
	font-size: 13px;
	-khtml-border-radius: 5px 5px 5px 5px;
	cursor: pointer;
	margin-bottom: 5px;
}

.simple-step-current {
	display: inline-block;
	padding: 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	background-color: #38B0E3;
	color: #FFFFFF;
	font-size: 13px;
	-khtml-border-radius: 5px 5px 5px 5px;
}

.simple-step-delimiter {
	display: inline-block;
	vertical-align: middle;
	line-height: 13px;
	margin: 0px 5px;
}

.simple-step-completed {
	display: inline-block;
	padding: 5px;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	background-color: #38B0E3;
	color: #FFFFFF;
	font-size: 13px;
	-khtml-border-radius: 5px 5px 5px 5px;
}

.simple-step-completed a {
	color: #FFFFFF !important;
	text-decoration: none !important;
	font-size: 13px !important;
}

.simplecheckout-summary-info {
	margin-top: 15px !important;
}

.simplecheckout-summary-info th.name {
	text-align: left;
}

.simplecheckout-summary-info td {
	text-align: left;
}

.simplecheckout-cart-buttons .buttons {
	border: none !important;
	padding: 0 !important;
}

#simple_login .simplecheckout-block-content {
	border: none !important;
}

#temp_flat_container {
	text-align: center;
	margin: 10px 0px 20px 0px;
}

#simplecheckout_comment .simplecheckout-block-content {
	border: 0 !important;
	padding: 0 !important;
}

#simplecheckout_comment textarea {
	width: 100% !important;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	min-height: 100px;
}

.simple-content input[type=text],
.simple-content input[type=password],
.simple-content input[type=email],
.simple-content input[type=tel],
.simple-content input[type=phone],
.simple-content input[type=date],
.simple-content input[type=time],
.simple-content select,
.simple-content textarea {
	width: 100%;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0px !important;
}

.checkout-heading:after {
	content: "";
	display: block;
	clear: both;
}

.simple-content .checkout-heading {
	font-size: 18px;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 20px;
}

.checkout-heading span {
	display: inline-block !important;
	float: left;
}

.checkout-heading-button {
	float: right !important;
	display: inline-block !important;
}

.simplecheckout-select-address {
	text-align: left;
	margin-bottom: 10px;
}

.simple-captcha-container {
	margin-top: 5px;
}

.simplecheckout-tooltip {
	display: none;
}

#easyTooltip {
	padding: 5px;
	border: 1px solid #ccc;
	background: #fde910;
	-webkit-border-radius: 5px 5px 5px 5px;
	-moz-border-radius: 5px 5px 5px 5px;
	-khtml-border-radius: 5px 5px 5px 5px;
	border-radius: 5px 5px 5px 5px;
	-webkit-box-shadow: 0px 2px 2px #DDDDDD;
	-moz-box-shadow: 0px 2px 2px #DDDDDD;
	box-shadow: 0px 2px 2px #DDDDDD;
	opacity: 1 !important;
}

#easyTooltip:before {
	content: '';
	position: absolute;
	left: 10px;
	top: -8px;
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 8px solid #fde910;
}

.simplecheckout-cart i {
	cursor: pointer;
}

.simple-content .table-responsive {
	margin-bottom: 0 !important;
	border: none !important;
}

@media only screen and (max-width:640px) {

	.simplecheckout-cart col.image,
	.simplecheckout-cart th.image,
	.simplecheckout-cart td.image,
	.simplecheckout-cart col.model,
	.simplecheckout-cart th.model,
	.simplecheckout-cart td.model,
	.simplecheckout-cart col.price,
	.simplecheckout-cart th.price,
	.simplecheckout-cart td.price,
	.simplecheckout-cart td.quantity [data-onclick*=increase],
	.simplecheckout-cart td.quantity [data-onclick*=decrease] {
		display: none !important;
	}

	.simplecheckout-cart td.name div.image {
		display: block;
	}

}

@media only screen and (max-width:1000px) {

	.simplecheckout-cart col.model,
	.simplecheckout-cart th.model,
	.simplecheckout-cart td.model {
		display: none;
	}
}

@media only screen and (max-width:600px) {
	#total_shipping span:first-child {
		max-width: 300px;
		display: inline-block;
	}
}

@media only screen and (max-width:420px) {
	#total_shipping span:first-child {
		max-width: 200px;
		display: inline-block;
	}
}

.row-customer_register .radio {
	display: inline-block !important;
	margin-right: 10px;
}

.simplecheckout-vertical-menu .checkout-heading {
	cursor: pointer;
	margin-bottom: 3px;
}

.simplecheckout-steps-wrapper {
	padding: 10px;
}

#agreement_checkbox {
	float: left;
	margin-right: 20px;
}

#agreement_checkbox .checkbox {
	margin: 0;
}


/* custom */

.simplecheckout-step {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	gap: 30px;
	position: relative
}

.simplecheckout-left-column.checkout-column {
	width: auto;
	display: flex;
	flex-direction: column;
	gap: 30px;
	flex: 0 1 auto;
}

.checkout-cart-product {
	background: #fff;
	filter: var(--shadow-block-filter);
	border-radius: var(--br-xxl);
	padding: 40px 35px;
	position: relative;
	display: flex;
	justify-content: space-between;
	gap: 30px;
}

a.checkout-cart-image-wr {
	display: block;
	width: 180px;
	height: auto;
}

a.checkout-cart-image-wr img {
	width: inherit;
	height: inherit;
}

.checkout-cart-product>* {
	display: block;
}

.checkout-cart-name {
	display: flex;
	align-items: flex-end;
	gap: 10px;
}

a.checkout-cart-name-wr,
a.checkout-cart-name-wr * {
	font-size: 24px;
	font-weight: 500;
}

.checkout-cart-options {
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-top: 7px;
	font-size: 14px;
	line-height: 18px;
	max-width: 300px;
}


a.checkout-cart-name-wr h3 {
	margin: 0;
}

.checkout-cart-name>span {
	font-size: 15px;
	color: var(--dark-grey);
}

.checkout-cart-quantity-wrapper .quantity__input {
	width: 45px !important;
	height: 32px;
}

.checkout-cart-quantity-wrapper .quantity-cart-btn {
	width: 26px;
	height: 32px;
}

.checkout-cart-quantity-wrapper {
	align-self: center;
	display: flex;
	flex-direction: column;
}

.checkout-cart-price-one {
	font-size: 14px;
	margin-top: 5px;
	text-align: center;
}

.checkout-cart-total-product {
	font-size: 20px;
	font-weight: 500;
	align-self: center;
}

.checkout-cart-delete {
	align-self: center;
}

.checkout-cart-delete button {
	border: none;
	background: none;
	width: initial;
	height: initial;
}

.checkout-cart-delete button>span {
	color: var(--user-color);
}

.checkout-cart-delete button>span:hover {
	color: var(--pink);
}

.checkout-cart-delete button>span {
	font-size: 28px;
	transition: var(--transition-btns);
}

.simplecheckout-right-column.checkout-column {
	width: 100%;
	max-width: 35%;
	flex: 0 1 auto;
	position: sticky;
	top: 10px;
	height: max-content;
	background: #fff;
	filter: var(--shadow-block-filter);
	border-radius: var(--br-xxl);
	padding: 15px 25px 30px 25px;
}


div#simplecheckout_payment_form {
	display: none;
}

.simplecheckout-right-column.checkout-column .simplecheckout-block {
	border-bottom: 1px solid #dedede;
	padding: 15px 0;
	padding-bottom: 25px;
	margin-bottom: 10px;
}

.simplecheckout-right-column.checkout-column .simplecheckout-block-content fieldset {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	gap: 18px;
	padding: 0;
	row-gap: 25px
}

.simplecheckout-right-column.checkout-column .simplecheckout-block-content .form-cf-header-item {
	width: 48%;
	margin: 0;
}


.simplecheckout-right-column.checkout-column .simplecheckout-block-content .form-cf-header-item .form__label {
	font-size: 15px;
	left: 1rem;
}

.simplecheckout-block.checkout-coupon-block input {
	box-shadow: var(--shadow-mini-block);
	border-radius: var(--br-lg);
	padding: 2px 15px;
	border: none;
	height: 50px;
	margin-top: 10px !important;
}

.checkout-coupon-title {
	font-size: 15px;
}

.simplecheckout-block.checkout-coupon-block input:focus {
	outline: none;
}

.simplecheckout-cart-total>span:first-child {
	font-weight: 500;
}

div#simplecheckout_summary {
	border: none;
}

.simplecheckout-button-right {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}

#agreement_checkbox label {
	font-size: 14px;
}

div#simplecheckout_cart>div:not(:last-child) {
	margin-bottom: 30px;
}

.cart-page-address-popup {
	display: none;
	flex-direction: column;
	position: absolute;
	bottom: -7rem;
	left: 0rem;
	width: 300px;
	height: 100px;
	background: #fff;
	box-shadow: var(--shadow-block);
	border-radius: var(--br-xl);
	padding: 5px 0px;
	gap: 5px;
	font-size: 14px;
	overflow-x: hidden;
	overflow-y: auto;
}

.cart-page-address-popup>div {
	cursor: pointer;
	transition: all .4s ease;
	padding-left: 15px;
}

.cart-page-address-popup>div:hover {
	color: var(--pink);
}

.cart-page-address-popup.active {
	display: flex
}

#simplecheckout_customer .simplecheckout-block-content .form-cf-header-item {
	font-size: 14px;
}

@media (max-width: 1600px) {
	a.checkout-cart-image-wr {
		width: 140px;
	}

	.simplecheckout-right-column.checkout-column {
		max-width: 35%;
	}

	.simplecheckout-left-column.checkout-column {
		flex: 1 0 auto;
	}

	.simplecheckout-right-column.checkout-column .simplecheckout-block-content .form-cf-header-item {
		width: 100%;
	}

	.checkout-cart-product {
		padding: 35px 20px;
		gap: 20px;
	}

	a.checkout-cart-name-wr,
	a.checkout-cart-name-wr * {
		font-size: 20px;
		font-weight: 500;
	}

	.checkout-cart-name>span {
		font-size: 14px;
	}

	.checkout-cart-quantity-wrapper .quantity-cart-btn {
		width: 20px;
		height: 22px;
	}

	.checkout-cart-quantity-wrapper .quantity__input {
		height: 22px;
	}

	.cart-page-address-popup {
		width: 100%;
	}
}

@media (max-width: 1200px) {
	.simplecheckout-step {
		flex-wrap: wrap;
	}

	.simplecheckout-right-column.checkout-column {
		max-width: 100%;
	}

	.simplecheckout-left-column.checkout-column {
		flex: 0 1 auto;
		width: 100%;
	}
}

@media (max-width: 992px) {

	a.checkout-cart-name-wr,
	a.checkout-cart-name-wr * {
		font-size: 16px;
	}

	.checkout-cart-name>span {
		font-size: 12px;
		line-height: normal;
	}

	.checkout-cart-name {
		gap: 5px;
	}

	.checkout-cart-product {
		gap: 10px;
	}

	a.checkout-cart-image-wr {
		width: 120px;
	}

	.checkout-cart-options {
		gap: 2px;
		margin-top: 5px;
		font-size: 12px;
		line-height: 17px;
	}

	.checkout-cart-price-one {
		font-size: 12px;
		margin-top: 2px;
	}

	.checkout-cart-total-product {
		font-size: 18px;
	}

	.checkout-cart-delete button>span {
		font-size: 22px;
	}

	.checkout-cart-delete {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}


@media (max-width: 768px) {
	.checkout-cart-product {
		flex-wrap: wrap;
		padding: 20px;
	}

	.checkout-cart-product>* {
		width: 100%;
	}

	.checkout-cart-delete {
		width: initial
	}

	.checkout-cart-quantity-wrapper {
		width: max-content;
		margin-top: 10px;
	}

	.checkout-cart-description-wrapper {
		margin-top: 10px;
	}

	.checkout-cart-total-product {
		position: absolute;
		bottom: 15%;
		right: 20px;
		width: max-content;
	}

	.simplecheckout-step {
		--br-xxl: var(--br-xl)
	}

	#agreement_checkbox {
		font-size: 14px;
		line-height: normal;
	}
}

/* 

FOOTER

*/
.footer--cop {
	background-color: #2c3638;
	padding: 15px 0;
	font-size: 12px;
	color: #e7e4df;
}

ul.list-unstyled.footer {
	font-size: 14px;
	padding: 0px 5px 5px 5px;
	line-height: 2;
}

.footer-menu {
	background-color: var(--white);
}

.card.mb-4.footer {
	border-radius: 30px;
	border: 0px;
	padding: 15px;
	margin-top: 1.5rem !important;
	margin-bottom: 2.8rem !important;
	background-color: #ffffff66;
	list-style: none;
}

.footer-menu a {
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.16px;
}

.footer-menu a:hover {
	color: var(--pink);
	transition: var(--transition-btns);
}


.checkbox-wrapper span {
	font-size: 11px;
	line-height: 15px;
}

.checkbox-wrapper span a {
	font-size: 11px;
	border-bottom: 1px solid;
	line-height: 15px;
}

.footer-container-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	grid-template-rows: auto !important;
	gap: 0px 20px;
	row-gap: 20px;
	padding: 20px 0px;
}

.salons.footer.custom-scrollbar {
	overflow-x: hidden;
	height: 19rem;
}

.footer-grid {
	border-radius: 30px;
	padding: 20px 20px;
	background-color: #ffffff66;
	list-style: none;
}

.footer-grid.buyers {
	display: grid;
}

.footer-grid.franchise {
	display: grid;
}

.footer-grid.contacts {
	display: grid;
}

.footer-grid.mailing {
	display: grid;
}

.footer-grid-social {
	display: flex;
	gap: 20px;
	flex-direction: column;
}

.salons-item-background {
	border-radius: 15px;
	margin-right: 10px;
	padding: 10px;
	background-color: #E7E4DF;
}

.salons.footer.custom-scrollbar .footer {
	margin-right: 10px;
}

.container-grid-contact {
	display: grid;
	grid-column: span 2;
	grid-template-columns: auto auto;
	grid-template-rows: auto;
	grid-auto-flow: row;
	gap: 20px;
}

hr.footer {
	color: #d6d6d6;
	opacity: 1;
	margin: 10px 0px 10px 0px;
}

.header-cf-callback .modal-content {
	border-radius: var(--br-xxl)
}

h5.card-title {
	font-size: 15px;
}

h5.card-title.footer {
	font-size: 20px;
	font-weight: 600;
	line-height: normal;
}

.search-container {
	position: relative;
}

.search-input {
	padding-right: 40px;
}

.search-button {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0, -50%);
	padding: 6px 15px;
	border-radius: 0 4px 4px 0;
}

input.form-control.search-input.ss {
	border-radius: 25px;
	font-size: 14px;
	padding: 15px 8px;
}

button.btn.btn-primary.search-button.ss {
	border-radius: 25px;
	font-size: 14px;
	padding: 16px;
	margin: 0px 0px 0px 0px;
	background: var(--pink);
}

button.btn.btn-primary.search-button.ss:hover {
	background: var(--pink-hover) !important;
}

.checkbox-container {
	display: flex;
	align-items: center;
	padding-top: 10px;
}

.checkbox-label {
	margin-left: 5px;
}

input#consent-checkbox {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

label.checkbox-label.ss {
	font-size: 11px;
}

@media (min-width: 576px) and (max-width: 991.98px) {
	.footer--cop {
		text-align: center;
	}
}

@media (max-width: 992px) {
	footer.mt-sc {
		padding-bottom: 15px;
	}
}


/*stars-rating*/
.rating-container {
	position: relative;
	width: 130px;
	height: 16px;
}

.red-square {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	clip-path: inset(0 0 100% 0);
	transition: clip-path 0.3s ease-in-out;
	z-index: 1;
}

.black-square {
	width: 100%;
	height: 100%;
}

/**/

/*INFORMATIONS*/



.page-info-docs li a {
	color: #085294;
}

/*ABOUT PAGE*/

img.about-img-top {
	width: 100%;
	border-radius: 30px;
	margin-top: 30px;
	height: 90vh;
	-o-object-fit: cover;
	object-fit: cover;
}

.about-friend-home {
	margin-top: 100px;
}

.about-title {
	padding-left: 60px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 50px;
	font-size: 40px;
}

.about-title h2 {
	font-size: 40px;
}

.about-title .about-logo {
	margin-top: -7px;
	padding-right: 9px;
	width: 400px;
}

.about-friend-home p {
	font-size: 22px;
	line-height: 38px;
	padding-left: 30px;
}

.about-friend-home p>img {
	width: 175px;
	margin-top: -4px;
}

.about-slider-first-wr img {
	width: 100%;
	border-radius: 30px;
	height: 500px;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-box-shadow: -1px 9px 27px -5px rgba(0 0 0 / 26%);
	box-shadow: -1px 9px 27px -5px rgb(0 0 0 / 26%);
	margin: 30px 0;
}


.about-slider-first-wr {
	margin-top: -70px;
	position: relative;
}

.about-slider-first-wr::after {
	content: "";
	position: absolute;
	top: 37%;
	left: -4%;
	background-color: #BBA0AF;
	width: 100%;
	height: 400px;
	z-index: -1;
	opacity: 0.7;
	border-radius: 30px;
}

.about-guarantee {
	margin-top: 120px;
}

.about-guarant-img {
	width: 100%;
	border-radius: 30px;
	-o-object-fit: cover;
	object-fit: cover;
	height: 900px;
}

.about-guarant-img.about-guarant-img2 {
	height: 550px;
}

.about-guarantee p {
	margin-top: 40px;
	font-size: 21px;
	line-height: 44px;
}

.about-eco {
	margin-top: 100px;
}

.about-eco-img {
	width: 100%;
	border-radius: 30px;
	height: 80vh;
	-o-object-fit: cover;
	object-fit: cover;
}

.about-eco p {
	margin: 0 auto;
	margin-top: 40px;
	text-align: center;
	font-size: 30px;
	line-height: 44px;
	width: 600px;
}

.about-slider-carusel {
	margin-top: 80px;
}

.swiper.aboutSliderCarusel {
	padding: 0 100px;
}

.swiper.aboutSliderCarusel .swiper-slide>img {
	width: 100%;
	border-radius: 30px;
	height: 700px;
	-o-object-fit: cover;
	object-fit: cover;
}

.swiper.aboutSliderCarusel div:nth-child(2n)>img {
	margin-top: 60px;
}

.about-safety {
	margin-top: 100px;
}

:root {
	--ab-s-im-h: 600px
}

.about-safe-img {
	width: 100%;
	height: var(--ab-s-im-h);
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 30px
}

.about-safety p {
	font-size: 26px;
	line-height: 40px;
	text-align: end;
	margin-top: 150px;
	position: relative;
}

.about-safety p::after {
	content: "";
	position: absolute;
	top: -32%;
	left: -40%;
	width: 200%;
	height: var(--ab-s-im-h);
	background-color: #D0B6A5;
	z-index: -1;
	opacity: 0.7;
	border-radius: 30px
}

.about-sertificate {
	margin: 0 60px;
	margin-top: 160px;
}

.about-sertificate .about-title {
	padding-left: 0;
}

.about-sert-wr img:last-child {
	border: 6px solid #BBA0AF
}

.about-sertificate p {
	font-size: 20px;
	line-height: 30px;
	margin-top: 40px;
}

.about-sert-wr {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	gap: 30px;
	flex-wrap: wrap
}

.about-contacts {
	margin-top: 100px;
	background-color: #E5E2DD;
}

.about-contacts-wr {
	padding-left: 60px;
	line-height: 36px;
	font-size: 23px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.about-contacts-wr span,
.about-contacts-wr a:not(:last-child) {
	margin-bottom: 15px;
}

.about-contacts-wr a {
	color: #383838;
	-webkit-transition: all .5s ease;
	-o-transition: all .5s ease;
	transition: all .5s ease;
	text-decoration: none;
}

.about-contacts-wr a:hover {
	color: #6e6e6e;
}

.about-contacts-content-wr {
	padding: 65px 0;
}

.about-contacts img {
	width: 100%;
	height: 550px;
	-o-object-fit: cover;
	object-fit: cover;
}

.about-video-wrapper {
	width: 900px;
	margin: 0 auto;
	margin-top: 100px;
}

@media screen and (max-width: 992px) {
	.about-video-wrapper {
		width: 100%;
	}
}


div#information-information {
	margin-top: 30px;
	overflow-x: hidden
}

div#information-information h2 {
	text-align: center
}

div#information-information h2.home-title.page-title {
	font-weight: 400;
	margin-bottom: 0;
}

.about-sert-wr img {
	width: 150px;
	object-fit: contain;
	display: block;
}

@media screen and (max-width: 1200px) {
	.swiper.aboutSliderCarusel .swiper-slide>img {
		height: 450px;
	}

	.about-safety p::after {
		top: -21%;
	}
}

@media screen and (max-width: 992px) {

	.about-title:not(.about-title.about-title-contacts) {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 0;
	}

	img.about-img-top {
		height: 70vh;
	}

	.about-friend-home p {
		font-size: 18px;
		line-height: 34px;
		padding: 0;
	}

	.about-slider-first-wr {
		margin-top: -5px;
	}

	.about-slider-first-wr::after {
		height: 350px;
	}

	.about-video-wrapper {
		width: 100%;
	}

	.about-guarant-img {
		height: 650px;
	}

	.about-guarant-img.about-guarant-img2 {
		height: 350px;
	}

	.about-guarantee p {
		font-size: 22px;
		line-height: 35px;
	}

	.about-eco p {
		font-size: 22px;
		line-height: 35px;
		width: 100%;
	}

	.swiper.aboutSliderCarusel {
		padding: 0 80px;
	}

	.swiper.aboutSliderCarusel .swiper-slide>img {
		height: 350px;
	}

	.about-safety p {
		text-align: center;
		font-size: 22px;
		line-height: 35px;
		margin-top: 60px;
		padding: 15px;
	}

	.about-safety p::after {
		top: -20px;
		left: 0;
		right: 0;
		width: 100%;
		height: calc(100% + 20%);
	}

	.about-sertificate .about-title {
		text-align: center;
	}
}


@media screen and (max-width: 768px) {
	.indent-rl-cf {
		padding-right: 30px;
		padding-left: 30px;
	}

	.about-title .about-logo {
		margin-top: 0;
		padding-right: 0;
		margin-bottom: 5px;
	}

	.about-guarant-img.about-guarant-img2 {
		margin-top: 40px;
	}

	.about-guarantee p {
		text-align: center;
	}

	.about-eco-img {
		height: 50vh;
	}

	.indent-rl-cf {
		padding-right: 30px;
		padding-left: 30px;
	}

	.about-title.about-title-contacts {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 0;
	}

	.about-contacts-wr {
		padding-left: 0;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
		padding: 0 10px;
	}

	.about-contacts img {
		height: 350px;
	}
}

@media screen and (max-width: 576px) {
	.about-sertificate p {
		font-size: 18px;
		line-height: 30px;
		text-align: center;
	}

	.indent-rl-cf {
		padding-right: 25px;
		padding-left: 25px;
	}

	img.about-img-top {
		height: 35vh;
	}

	.about-guarantee p {
		font-size: 20px;
		line-height: 32px;
	}

	.about-eco p {
		font-size: 20px;
		line-height: 32px;
	}

	.indent-rl-cf {
		padding-right: 25px;
		padding-left: 25px;
	}

	.about-sertificate {
		margin-left: 10px;
		margin-right: 10px;
	}

	.about-safe-img {
		height: 300px;
	}

	.about-safety p {
		font-size: 20px;
		line-height: 32px;
	}
}

@media screen and (max-width: 480px) {
	.swiper.aboutSliderCarusel {
		padding: 0 65px;
	}

	.swiper.aboutSliderCarusel .swiper-slide>img {
		height: 320px;
	}

	.about-contacts-wr {
		line-height: 34px;
		font-size: 20px;
	}

	.about-contacts-wr span,
	.about-contacts-wr a:not(:last-child) {
		margin-bottom: 10px;
	}
}

/*SCROLL_TOP*/
.scroll-btn-up {
	position: fixed;
	left: 20px;
	bottom: 20px;
	cursor: pointer;
	width: -moz-max-content;
	width: -webkit-max-content;
	width: max-content;
	transition: all 0.5s ease-in-out;
	background: #dadada;
	margin: 0;
	line-height: 1;
	padding: 8px;
	border-radius: 100%;
	opacity: 0.7;
	z-index: 995;
}

.scroll-btn-up:hover {
	opacity: 1
}

.scroll-btn-up .icon-arrow {
	transform: rotate(90deg);
	display: block;
	font-size: 40px;
}

.scroll-btn-up_hide {
	display: none;
}

@media (max-width: 992px) {
	.scroll-btn-up .icon-arrow {
		font-size: 28px;
	}

	.scroll-btn-up {
		left: 10px;
		bottom: 70px;
		padding: 5px;
		opacity: 1;
	}

	.scroll-btn-up.scroll-btn-product-card {
		bottom: 105px;
	}
}


/* custom Alerts */
.noty_layout .noty_bar {
	border-radius: var(--br-sm);
	border: none;
	box-shadow: var(--shadow-mini-block);
}

jdiv.notranslate {
	z-index: 10 !important;
}

jdiv.wrap_f3c8._orientationRight_f2d9._mobileOffsetBottom_ec15.__jivoMobileButton {
	z-index: 10 !important;
}



.option_text_img.option_color_carpet .product-option-value-wr img {
	border-radius: 12px;
	height: 120px !important;
}


@media screen and (max-width: 480px) {
	.option_text_img.option_color_carpet .product-option-value-wr img {
		height: 90px !important;
	}
}


/*COOOCIE*/

#cookie_notification {
	display: none;
	justify-content: space-between;
	align-items: flex-end;
	position: fixed;
	bottom: 15px;
	left: 50%;
	width: 900px;
	max-width: 90%;
	transform: translateX(-50%);
	padding: 25px;
	background-color: rgba(255, 245, 245, .8);
	border-radius: 4px;
	box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
	z-index: 100;
}

#cookie_notification p {
	margin: 0;
	font-size: 0.7rem;
	text-align: left;
}

.cookie_accept {
	cursor: pointer;
	word-break: normal;
	display: block;
	font-size: 18px;
	background: rgba(255, 255, 255, 0.7);
	padding: 12px 15px;
	border-radius: var(--br-lg);
	height: 45px;
}

@media (min-width: 576px) {
	#cookie_notification.show {
		display: flex;
	}

	.cookie_accept {
		margin: 0 0 0 25px;
	}
}

@media (max-width: 575px) {
	#cookie_notification.show {
		display: block;
		text-align: left;
	}

	.cookie_accept {
		margin: 10px 0 0 0;
	}
}



/* top banner */

.top-banner {
	width: 100%;
	height: auto;
	padding-top: 18px;
	padding-bottom: 10px;
}

.top-banner .top-banner-title {
	font-weight: 800;
	font-size: clamp(18px, 4vw, 34px);
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1.2px;
	padding-bottom: 8px;
	line-height: clamp(26px, 5vw, 36px);
}

.top-banner .row > div {
	padding-bottom: 10px;
}

/* Countdown timer */

.timer {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #fff
}

.timer__items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	font-size: clamp(22px, 4vw, 48px);
}

.timer__item {
	position: relative;
	margin-left: 12px;
	margin-right: 12px;
	padding-bottom: 30px;
	text-align: center;
}

.timer__item::before {
	content: attr(data-title);
	display: block;
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	font-size: 14px;
}

.timer__item:not(:last-child)::after {
	content: ':';
	position: absolute;
	right: -15px;
}

.timer__result {
	text-align: center;
	color: #fff;
	font-weight: 500;
}

@media (max-width: 768px) {
	.timer__item {
    margin-left: 9px;
    margin-right: 9px;
    padding-bottom: 21px;
}
.timer__item::before {
	font-size: 12px;
}
.timer__item:not(:last-child)::after {
	right: -12px;
}
}
